/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import AttachmentFilesPanel from "app/component/attachment-files-panel/AttachmentFilesPanel";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { NameFormats, nameFormatter } from "app/utils";
import { STATUS_TYPE_REPORT } from "infrastructure/enum/status-report.enum";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import { IAO, IOccupations, UserDetail } from "infrastructure/interfaces";
import { get } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import "./AODetail.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import Config, { ConfigTypes } from "services/ConfigService";
import AOAgreement from "./AOAgreement";
import {
  clearAttachmentList,
  loaderLock,
  loaderUnlock,
  setPostings,
  updatePostings,
} from "store/common/actions";
import { showErrors } from "store/exception/actions";
import {
  setAODetailDownloaded,
  setDetailedAO,
  updateDetailedAO,
} from "store/report/actions";
import { updateAttachmentsExpense } from "../../../store/common/actions";
import AOButtonsControl from "./AOButtonsControl";
import ReportExpenses from "./AOExpenses";
import AOHeader from "./AOHeader";
import AOPostings from "./AOPostings";
import If from "app/component/util/If";
import { STATUS_TYPE } from "infrastructure/enum/status-type.enum";
import SubmitCustomModal from "app/component/modal/SubmitCustomModal";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import { isIE } from "react-device-detect";
import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import agreementService from "services/entity/agreement.service";
import AOActs from "./AODocuments";
import {
  getAdvanceReportApplication,
  getAdvanceReportIsReportDetailDownloaded,
  getCommonAttachmentPermission,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  getAssistancesById,
  postPaperWorks,
  updateApprovalsAdvanceReportApplicationMarkAsReadById,
} from "../../../services/ApiService";
import { ReportHeader } from "ui/ReportHeader";

interface AODetailProps extends RouteComponentProps<any> {}

const AODetail: React.FC<AODetailProps> = (props) => {
  const { t, i18n } = useTranslation();
  const id = props.match.params.id;

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  const user: UserDetail = useSelector(getCommonUserDetail);
  const { isAdvanceReportAttachmentPermission } = useSelector(
    getCommonAttachmentPermission
  );
  const advanceReport = useSelector(getAdvanceReportApplication);
  const isReportDetailDownloaded = useSelector(
    getAdvanceReportIsReportDetailDownloaded
  );
  const dispatch = useDispatch();

  let [isOpenEmployeeInfo, setOpenEmployeeInfo] = useState(false);
  let [isAppMode, setAppMode] = useState(true);
  let [isPostingsMode, setPostingsMode] = useState(false);
  let [isAgreementMode, setAgreementMode] = useState(false);
  let [isMenuOpen, setMenuOpen] = useState(false);
  let [isShowDailyRenew, setShowDailyRenew] = useState(false);
  let [dailyRenewEditId, setDailyRenewEditId] = useState(0);
  let [perDiemCalculationType, setPerDiemCalculationType] = useState(0);
  let [
    advanceReportAmountCalculationPermission,
    setAdvanceReportAmountCalculationPermission,
  ] = useState(0);

  let [canShowTakeInHand, setCanShowTakeInHand] = useState(
    null as unknown as boolean
  );
  let [isOpenTakeInHandModal, setIsOpenTakeInHandModal] = useState(false);
  let [assistancesIds, setAssistancesIds] = useState([] as any[]);
  let [rightStatusForWarning, setRightStatusForWarning] = useState(false);
  let [transactionResponseStatus, setTransactionResponseStatus] = useState(
    0 as number
  );
  let [fieldAccess, setFieldAccess] = useState({
    approvals: false,
    applicationType: false,
    cancelAO: false,
    number: false,
    associatedExpenseApplication_Number: false,
    type: false,
    orderNumber: false,
    journeyNumber: false,
    businessTripNumber: false,
    businessTarget: false,
    description: false,
    vbsElement: false,
    departureFromCity: false,
    arrivalToCity: false,
    startOn: false,
    endOn: false,
    personalDaysNumber: false,
    advanceCashAmount: false,
    totalAmount: false,
    transactions: false,
    flowHistory: false,
    printingForms: false,
    updateAO: false,
    status: false,
  });

  useEffect(() => {
    return () => {
      dispatch(setAODetailDownloaded(false));
    };
  }, []);

  useEffect(() => {
    dispatch(updateDetailedAO(id));
    window.scrollTo(0, 0);
    return () => {
      dispatch(setDetailedAO({}));
      dispatch(clearAttachmentList());
    };
  }, [id]);

  useEffect(() => {
    let companyId =
      advanceReportApplication && advanceReportApplication.company
        ? advanceReportApplication.company.id
        : 0;
    setAdvanceReportAmountCalculationPermission(
      Config.getConfigToCompany(
        ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION,
        companyId
      )
    );
    if (advanceReportApplication && isBookerOccupation()) {
      dispatch(
        updatePostings(id, "AdvanceReportApplication", {}, setResponseStatus)
      );
    } else {
      dispatch(setPostings({ transactions: [] }));
    }

    dispatch(
      updateAttachmentsExpense(
        id,
        "AdvanceReportApplication",
        get(advanceReportApplication, "expenses", []),
		1
      )
    );
    markAsReaded();
    checkAssistance();
    generateFieldsAccess();

    setPerDiemCalculationType(
      Config.getConfigToCompany(ConfigTypes.PERDIEM_CALCULATION_TYPE, companyId)
    );
    canOpenDailyExpense();
  }, [advanceReportApplication]);

  useEffect(() => {
    if (isPostingsMode) {
      dispatch(updatePostings(id, "AdvanceReportApplication", {}));
    }
  }, [isPostingsMode]);

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const setResponseStatus = (status: number) => {
    setTransactionResponseStatus(status);
  };

  const checkAssistance = async () => {
    if (
      advanceReportApplication &&
      advanceReportApplication.assigneeEmployee &&
      advanceReportApplication.assigneeEmployee.id
    ) {
      let massRequest = user.occupations.map((occupation) =>
        getAssistancesById(occupation.occupationId)
      );
      let massResponse = await Promise.all(massRequest);
      let massList = massResponse
        .map((response) => {
          if (response.headers.success) {
            let assistancesList = get(response, "data.value.assistances", []);
            return assistancesList.map((item: any) => item.employeeId);
          }
          return [];
        })
        .flatMap((item) => item);

      setAssistancesIds(massList);
    }
  };

  const markAsReaded = async () => {
    if (
      advanceReportApplication &&
      advanceReportApplication.status == STATUS_TYPE.ON_APPROVAL
    ) {
      await updateApprovalsAdvanceReportApplicationMarkAsReadById(
        advanceReportApplication.id
      );
    }
  };

  useEffect(() => {
    agreementService.setId(advanceReportApplication.id).setIsAdvance(true);
    if (
      advanceReportApplication.status === STATUS_TYPE_REPORT.DRAFT ||
      advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVAL_REQUIRES
    ) {
      setCanShowTakeInHand(false);
      return;
    }
    changeShowTakeInHand();
    return () => {
      setCanShowTakeInHand(null as unknown as boolean);
    };
  }, [
    advanceReportApplication.id,
    advanceReportApplication.status,
    advanceReportApplication.paperWork
      ? advanceReportApplication.paperWork.employeeId
      : undefined,
  ]);

  const toggleEmployeeInfo = () => {
    setOpenEmployeeInfo(!isOpenEmployeeInfo);
    if (isMenuOpen) togglePopup();
  };

  const showPostingForm = () => {
    return (
      <Fragment>
        <AOPostings id={id} />
        <AOButtonsControl
          id={id}
          isPostingsMode={isPostingsMode}
          setPostingsMode={() => setPostingsMode(true)}
        />
      </Fragment>
    );
  };

  const showAgreementForm = () => {
    return (
      <Fragment>
        <AOAgreement id={id} />
      </Fragment>
    );
  };

  const showAppMode = () => {
    setAppMode(true);
    setPostingsMode(false);
    setAgreementMode(false);
  };

  const showPostingsMode = () => {
    setAppMode(false);
    setPostingsMode(true);
    setAgreementMode(false);
  };

  const showAgreementMode = () => {
    setAppMode(false);
    setPostingsMode(false);
    setAgreementMode(true);
  };

  const linkClickHandler = (id: number) => {
    props.history.push(`/ExpenseApplication/Detail/${id}`);
  };

  const togglePopup = () => {
    setMenuOpen(!isMenuOpen);
  };

  const userInAgreementList = async () => {
    let agreementList = await agreementService.getList();

    return (
      agreementList &&
      agreementList.approvalTiers
        .flatMap((item) => item.employees)
        .filter((item) => item.id == user.id).length > 0
    );
  };

  const notPaperWorkEmployee = () => {
    return (
      advanceReportApplication &&
      advanceReportApplication.paperWork.employeeId != user.id
    );
  };

  const checkedListApproved = async () => {
    let currentTier = await agreementService.getCurrentTier();
    let visibleTakeInHand: boolean = false;
    if (currentTier) {
      currentTier.employees.forEach((item) => {
        if (item.id === user.id) {
          visibleTakeInHand = true;
        }
      });
    }
    return visibleTakeInHand;
  };

  const changeShowTakeInHand = async () => {
    if (advanceReportApplication.status !== STATUS_TYPE_REPORT.ON_APPROVAL) {
      setCanShowTakeInHand(false);
      return;
    }

    let currentComposition = await agreementService.getCurrentComposition();
    let currentTier = await agreementService.getCurrentTier();

    if ((await userInAgreementList()) === false) {
      setCanShowTakeInHand(false);
      return;
    }

    if (currentComposition == "All") {
      setCanShowTakeInHand(false);
      return;
    } //Если composition All кнопка взять в работу не отображается.

    if (
      currentComposition == "Any" &&
      currentTier &&
      currentTier.employees.length == 1
    ) {
      setCanShowTakeInHand(false);
      return;
    } //Если composition Any и согласующий один кнопка не отображается

    if (
      currentComposition == "Any" &&
      currentTier &&
      currentTier.employees.length > 1 &&
      notPaperWorkEmployee() &&
      (await checkedListApproved())
    ) {
      setCanShowTakeInHand(true);
      return;
    } //Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr null, то кнопка отображается
    //Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr заполнено, то кнопка отображается только для тех пользователь у которых paperwokr не совпадает с текущим пользователем .

    setCanShowTakeInHand(false);
  };

  // проверяем, что сейчас идет 1ый этап согласования
  const [showRevokeButton, setShowRevokeButton] = useState(false);

  const canShowRevokeButton = async () => {
    if (canShowTakeInHand) {
      const currentTier = await agreementService.getCurrentTier();
      const agreementList = await agreementService.getList();
      if (
        currentTier &&
        agreementList &&
        currentTier.id === agreementList.approvalTiers[0].id
      ) {
        setShowRevokeButton(true);
      }
    }
  };

  useEffect(() => {
    canShowRevokeButton();
  }, [canShowTakeInHand]);

  const sendRequestToTakeInHand = async () => {
    dispatch(loaderLock());
    const requestParams = {
      objectType: "AdvanceReportApplication",
      objectId: advanceReportApplication ? advanceReportApplication.id : 0,
    };
    let result = await postPaperWorks(requestParams);
    if (result.headers.success) {
      dispatch(updateDetailedAO(id));
      setIsOpenTakeInHandModal(false);
      dispatch(loaderUnlock());
      return;
    } else {
      togleIsOpenTakeInHandModal();
      dispatch(loaderUnlock());
      dispatch(
        showErrors({
          code: "takeInHand_action",
          message: "Не удалось взять в работу",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const togleIsOpenTakeInHandModal = () => {
    setIsOpenTakeInHandModal(!isOpenTakeInHandModal);
  };

  const takeInHand = () => {
    if (
      advanceReportApplication &&
      advanceReportApplication.paperWork.employeeId == 0
    ) {
      sendRequestToTakeInHand();
    } else {
      togleIsOpenTakeInHandModal();
    }
  };

  const openDailyRenew = async () => {
    dispatch(loaderLock());
    let dailyExpensesId = advanceReportApplication.expenses
      .filter((item: any) => {
        return item.expenseType.expenseGroup.code == "Perdiem";
      })
      .map((item: any) => item.id);

    if (dailyExpensesId.length > 0) {
      await setDailyRenewEditId(dailyExpensesId[0]);
    } else {
      await setDailyRenewEditId(0);
    }

    await setShowDailyRenew(true);

    dispatch(loaderUnlock());
  };

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  };

  const dailyAlertClickHandler = () => {
    if (rightStatusForWarning) {
      openDailyRenew();
    } else {
      return;
    }
  };

  const canOpenDailyExpense = () => {
    const accessStatuses = ["Cancelled", "Posted", "On Approval", "Approved"];
    setRightStatusForWarning(
      !accessStatuses.includes(advanceReportApplication.status)
    );
  };

  const generateFieldsAccess = () => {
    // TODO: hasAccess(field: keyof typeof ADVANCE_APPLICATION_PROPETIES) => bool
    if (advanceReportApplication && advanceReportApplication.properties) {
      let fieldAccess = {
        approvals: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.APPROVALS
        ),
        applicationType: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE
        ),
        cancelAO: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.CANCEL_AO
        ),
        number: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.NUMBER
        ), //stubProperties.includes('AdvanceReport_Number'),
        associatedExpenseApplication_Number:
          advanceReportApplication.properties.includes(
            ADVANCE_APPLICATION_PROPETIES.EXPENSE_APPLICATION_NUMBER
          ), //stubProperties.includes('AdvanceReport_AssociatedExpenseApplication_Number'),
        type: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE
        ), //stubProperties.includes('AdvanceReport_Type'),
        orderNumber: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.ORDER_NUMBER
        ), //stubProperties.includes('AdvanceReport_OrderNumber'),
        journeyNumber: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.JOURNEY_NUMBER
        ), //stubProperties.includes('AdvanceReport_JourneyNumber'),
        businessTripNumber: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER
        ), //stubProperties.includes('AdvanceReport_BusinessTripNumber'),
        businessTarget: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.BUSINESS_TARGET
        ), //stubProperties.includes('AdvanceReport_BusinessTarget'),
        description: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.DESCRIPTION
        ), //stubProperties.includes('AdvanceReport_Description'),
        vbsElement: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.VBS_ELEMENT
        ), //stubProperties.includes('AdvanceReport_VbsElement'),
        departureFromCity: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY
        ), //stubProperties.includes('AdvanceReport_DepartureFromCity'),
        arrivalToCity: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY
        ), //stubProperties.includes('AdvanceReport_ArrivalToCity'),
        startOn: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.START_ON
        ), //stubProperties.includes('AdvanceReport_StartOn'),
        endOn: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.END_ON
        ), //stubProperties.includes('AdvanceReport_EndOn'),
        personalDaysNumber: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER
        ), //stubProperties.includes('AdvanceReport_PersonalDaysNumber'),
        advanceCashAmount: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT
        ), //stubProperties.includes('AdvanceReport_AdvanceCashAmount'),
        totalAmount: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.TOTAL_AMOUNT
        ), //stubProperties.includes('AdvanceReport_TotalAmount'),
        transactions: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.TRANSACTIONS
        ),
        flowHistory: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY
        ), //stubProperties.includes('AdvanceReport_FlowHistory'),
        printingForms: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.PRINTING_FORMS
        ),
        updateAO: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.UPDATE_AO
        ),
        status: advanceReportApplication.properties.includes(
          ADVANCE_APPLICATION_PROPETIES.STATUS
        ), //stubProperties.includes('AdvanceReport_Status'),
      };
      setFieldAccess(fieldAccess);
      return;
    }
  };

  const showMainForm = () => {
    return (
      <Fragment>
        <div className="request">
          <div
            className={`request-item ${advanceReportApplication.expenseApplication.id ? "prepayment" : ""}`}
            style={{ position: "relative" }}
          >
            <ReportHeader
              id={id}
              history={props.history}
              advanceReportApplication={advanceReportApplication}
              fieldAccess={fieldAccess}
              linkClickHandler={linkClickHandler}
            />
            <AOHeader
              id={id}
              type={advanceReportApplication.applicationType.code}
              toggleEmployeeInfo={toggleEmployeeInfo}
            />
            <If
              condition={
                perDiemCalculationType === 2 &&
                advanceReportApplication.applicationType.code === "Travel" &&
                rightStatusForWarning
              }
            >
              <div className="request-item-row">
                {isIE ? (
                  <div
                    style={{
                      display: "inline-table",
                      alignItems: "center",
                      padding: "20px 25% 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "calc(100% - 20px)",
                        position: "absolute",
                        display: "table-cell",
                        marginLeft: "-55px",
                      }}
                    >
                      <SvgIcon
                        className={"icon icon-notice"}
                        href={"#svg_icon_daily-alert"}
                        style={{ display: "ruby-text" }}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        width: "100%",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "-0.011em",
                      }}
                    >
                      {t("request_detail.daily_alert_part_1")}
                      <span
                        onClick={dailyAlertClickHandler}
                        style={
                          rightStatusForWarning
                            ? { textDecoration: "underline", cursor: "pointer" }
                            : {}
                        }
                      >
                        {t("request_detail.daily_alert_part_2")}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "20px 25% 0px",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <SvgIcon
                        className={"icon icon-notice"}
                        href={"#svg_icon_daily-alert"}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        width: "100%",
                        fontSize: "16px",
                        lineHeight: "20px",
                        letterSpacing: "-0.011em",
                      }}
                    >
                      {t("request_detail.daily_alert_part_1")}
                      <span
                        onClick={dailyAlertClickHandler}
                        style={
                          rightStatusForWarning
                            ? { textDecoration: "underline", cursor: "pointer" }
                            : {}
                        }
                      >
                        {t("request_detail.daily_alert_part_2")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </If>
          </div>
          <ReportExpenses
            list={advanceReportApplication.expenses}
            id={id}
            applicationId={advanceReportApplication.applicationType.id}
          />
          <AOActs documentArr={advanceReport.additionalDocuments} />
          {canShowTakeInHand ? (
            <div className={`request-bottom-controls tx-center flex`}>
              <div className="btn btn_black pointer" onClick={takeInHand}>
                {t("request_detail.request_button_controls.take_in_hand")}
              </div>
              <div className="revoke-button-only">
                <AOButtonsControl
                  id={id}
                  isPostingsMode={isPostingsMode}
                  setPostingsMode={() => setPostingsMode(true)}
                  showRevokeButtonOnly={showRevokeButton}
                />
              </div>
            </div>
          ) : null}
          {!canShowTakeInHand && (
            <div>
              <AOButtonsControl
                id={id}
                isPostingsMode={isPostingsMode}
                setPostingsMode={() => setPostingsMode(true)}
              />
            </div>
          )}
          {isAdvanceReportAttachmentPermission ? (
            <AttachmentFilesPanel isReport={true} id={id} />
          ) : null}
        </div>
        <SubmitCustomModal
          isOpen={isOpenTakeInHandModal}
          title={t("request_detail.in_hand_request", {
            user: nameFormatter(
              advanceReportApplication.paperWork.employeeName,
              NameFormats.LASTNAME_WITH_FULL_INITIALS,
              i18n.language
            ),
          })}
          onClose={togleIsOpenTakeInHandModal}
          onSubmit={sendRequestToTakeInHand}
        />
        <CreateOrderModal
          id={advanceReportApplication.id}
          editId={dailyRenewEditId === 0 ? undefined : dailyRenewEditId}
          isAdvance={true}
          isOpen={isShowDailyRenew}
          onClose={closeDailyRenew}
          dailyRenew={true}
          dailyRenewWithoutAlert={true}
        />
      </Fragment>
    );
  };

  const showTabMenu = () => {
    let tabArray = [
      <li
        onClick={showAppMode}
        className={`${isAppMode ? "active" : ""} pointer`}
      >
        <a>{t("report_detail.app")}</a>
      </li>,
    ];

    if (fieldAccess.transactions) {
      tabArray.push(
        <li
          onClick={showPostingsMode}
          className={`${isPostingsMode ? "active" : ""} pointer`}
        >
          <a>{t("report_detail.postings")}</a>
        </li>
      );
    }

    if (fieldAccess.approvals) {
      tabArray.push(
        <li
          onClick={showAgreementMode}
          className={`${isAgreementMode ? "active" : ""} pointer`}
        >
          <a>{t("report_detail.agreement")}</a>
        </li>
      );
    }

    if (tabArray.length > 1) {
      return (
        <div className="request-menu">
          <ul>{tabArray}</ul>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      {advanceReportApplication.id &&
        advanceReportAmountCalculationPermission != -1 && (
          <Fragment>
            {showTabMenu()}
            <If condition={isAppMode}>{showMainForm()}</If>
            <If condition={isPostingsMode}>{showPostingForm()}</If>
            <If condition={isAgreementMode}>{showAgreementForm()}</If>
          </Fragment>
        )}
      {!advanceReportApplication.id &&
        advanceReportAmountCalculationPermission != -1 &&
        isReportDetailDownloaded && (
          <div className="request-list empty">
            <div className="request-list-empty">
              <img src="/img/empty-img.png" />
              <div style={{ width: "inherit" }}>
                {t("report_detail.not_found", {
                  user: user.name[i18n.language as "en" | "ru"].split(" ")[0],
                })}
              </div>
            </div>
          </div>
        )}
      {advanceReportAmountCalculationPermission == -1 &&
        isReportDetailDownloaded && (
          <div className="request-list empty">
            <div className="request-list-empty">
              <img src="/img/empty-img.png" />
              <div style={{ width: "inherit" }}>
                {t("report_detail.not_access")}
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default withRouter(AODetail);
