import {
  statusReportStylesMap,
  statusReportNameMap,
} from "infrastructure/enum/status-report.enum";
import {
  statusStylesMap,
  STATUS_TYPE,
  statusNameMap,
} from "infrastructure/enum/status-type.enum";
import { JournalItem } from "infrastructure/interfaces";
import moment from "moment";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateJournalList } from "store/journal/actions";
import { Checkbox } from "ui/Checkbox";
import Else from "../util/Else";
import If from "../util/If";

interface JournalListProps {
  isAdvance?: boolean;
  isArchive?: boolean;
  journalList: JournalItem[];
  toggleClickItem: (id: number) => void;
  chosenItems: number[];
}

const JournalList: React.FC<JournalListProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let [journalList, setJournalList] = useState([] as JournalItem[]);

  const clickHandler = (e: any, id: number) => {
    const type: any = (e.target as HTMLElement).getAttribute("data-type");
    if (type) {
      e.preventDefault();
    }
    switch (type) {
      case "delete":
        return;
      case "checkbox":
        props.toggleClickItem(id);
        return;
    }
  };

  useEffect(() => {
    if (props.journalList) {
      setJournalList(props.journalList);
    } else {
      setJournalList([]);
    }
  }, [props.journalList]);

  const observer = useRef();

  const lastElement = useCallback((node: any) => {
    if (observer && observer.current) {
      // @ts-ignore
      observer.current.disconnect();
    }
    // @ts-ignore
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        dispatch(
          updateJournalList(
            props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
            true,
            () => {
              window.scrollTo(0, document.documentElement.scrollTop - 500);
            }
          )
        );
      }
    });
    if (node && observer && observer.current) {
      // @ts-ignore
      observer.current.observe(node);
    }
  }, []);

  const toggle = (item: JournalItem) => (e: any) => {
    e.preventDefault();
    props.toggleClickItem(item.referenceId);
  };
  return (
    <Fragment>
      {journalList.map((item, index) => {
        let loadedAt = moment
          .utc(item.loadedAt, "YYYY-MM-DDThh:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        let lastLoadedAt = moment
          .utc(item.lastLoadedAt, "YYYY-MM-DDThh:mm:ss")
          .local()
          .format("YYYY-MM-DD HH:mm:ss");

        if (journalList && journalList.length === index + 1) {
          return (
            <Link
              ref={lastElement}
              key={index}
              to={`/${item.referenceType}/Detail/${item.referenceId}`}
              onClick={(e) => clickHandler(e, item.referenceId)}
            >
              <div className="request-expenses-item">
                <div
                  style={{ display: "flex" }}
                  className="request-expenses-block"
                >
                  <label
                    onClick={toggle(item)}
                    style={{
                      flex: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    htmlFor={`check${index}`}
                  >
                    <Checkbox
                      id={index}
                      name={`check${index}`}
                      label={""}
                      onClick={() => {}}
                      type={"checkbox"}
                      checked={props.chosenItems.includes(item.referenceId)}
                    />
                  </label>
                </div>
                <div className="request-expenses-block info">
                  <div className="request-expenses-item-text">№{item.id}</div>
                  {item.batchNumber && (
                    <div className="request-expenses-item-text">
                      {t("journal.batch_number")} {item.batchNumber}
                    </div>
                  )}
                  <div className="request-item-name">{item.name}</div>
                  <div className="request-item-user">
                    {item.employee.firstName[i18n.language as "ru"]}{" "}
                    {item.employee.lastName[i18n.language as "ru"]}
                  </div>
                </div>
                <div className="request-expenses-block-wrap">
                  <div className="flex-sm-row flex-sm-between">
                    <div>
                      {/* <div className="request-list-item-cost">{toLocaleNumberFormatter(item.totalAmount)}{getCurrencieCode('rub')}</div> */}
                    </div>
                  </div>
                  <If condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusReportStylesMap[item.referenceState]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `statusAO.${statusReportNameMap[item.referenceState]}`
                        )}
                        <span></span>
                      </div>
                    </div>
                  </If>
                  <Else condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusStylesMap[item.referenceState as STATUS_TYPE]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `status.${statusNameMap[item.referenceState as STATUS_TYPE]}`
                        )}
                        <span></span>
                      </div>
                    </div>
                  </Else>
                  <span>
                    {item.loadedAt != null
                      ? ` ${t("status.unload_first_date")}: ${loadedAt}`
                      : null}
                  </span>
                  {item.lastLoadedAt && (
                    <span>{` ${t("status.unload_last_date")}: ${lastLoadedAt}`}</span>
                  )}
                </div>
              </div>
            </Link>
          );
        } else
          return (
            <Link
              key={index}
              to={`/${item.referenceType}/Detail/${item.referenceId}`}
              onClick={(e) => clickHandler(e, item.referenceId)}
            >
              <div className="request-expenses-item">
                <div className="request-expenses-block">
                  <label
                    onClick={toggle(item)}
                    style={{
                      flex: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    htmlFor={`check${index}`}
                  >
                    <Checkbox
                      id={index}
                      name={`check${index}`}
                      label={""}
                      onClick={() => {}}
                      type={"checkbox"}
                      checked={props.chosenItems.includes(item.referenceId)}
                    />
                  </label>
                </div>
                <div className="request-expenses-block info">
                  <div className="request-expenses-item-text">№{item.id}</div>
                  {item.batchNumber && (
                    <div className="request-expenses-item-text">
                      {t("journal.batch_number")} {item.batchNumber}
                    </div>
                  )}
                  <div className="request-item-name">{item.name}</div>
                  <div className="request-item-user">
                    {item.employee.firstName[i18n.language as "ru"]}{" "}
                    {item.employee.lastName[i18n.language as "ru"]}
                  </div>
                </div>
                <div className="request-expenses-block-wrap">
                  <div className="flex-sm-row flex-sm-between">
                    <div></div>
                  </div>
                  <If condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusReportStylesMap[item.referenceState]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `statusAO.${statusReportNameMap[item.referenceState]}`
                        )}
                        <span></span>
                      </div>
                    </div>
                  </If>
                  <Else condition={props.isAdvance}>
                    <div className="request-expenses-block">
                      <div
                        className={`request-list-item-status ${statusStylesMap[item.referenceState as STATUS_TYPE]}`}
                      >
                        {t("filters.status")}:{" "}
                        {t(
                          `status.${statusNameMap[item.referenceState as STATUS_TYPE]}`
                        )}
                        <span></span>
                      </div>
                    </div>
                  </Else>
                  <span>
                    {item.loadedAt != null
                      ? ` ${t("status.unload_first_date")}: ${loadedAt}`
                      : null}
                  </span>
                  {item.lastLoadedAt && (
                    <span>{` ${t("status.unload_last_date")}: ${lastLoadedAt}`}</span>
                  )}
                </div>
              </div>
            </Link>
          );
      })}
    </Fragment>
  );
};

export default JournalList;
