import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileSaver from "file-saver";
import { isEmpty } from "lodash";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useTranslation } from "react-i18next";
import { Checkbox } from "ui/Checkbox";
import { loaderLock, loaderUnlock } from "store/common/actions";
import If from "../util/If";
import { updateJournalList } from "store/journal/actions";
import DownloadPostingsModal from "../modal/DownloadPostingsModal";
import AssistanceWarningModal from "../modal/AssistanceWarningModal";
import {
  getJournalIsArchive,
  getJournalFilter,
  getJournalList,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  postTransactionsDownloadWithParams,
  postTransactionsFTPDownloadWithParams,
  updateBatchesArchiveBulk,
} from "../../../services/ApiService";
import JournalList from "./JournalList";
import Config, { ConfigTypes } from "services/ConfigService";
import { showErrors, showInfo } from "store/exception/actions";
import moment from "moment";

interface JournalPageProps {
  isAdvance?: boolean;
  isArchive?: boolean;
}

const JournalPage: React.FC<JournalPageProps> = (props) => {
  const { t } = useTranslation();

  const [chosenItems, setChosenItems] = useState([] as number[]);
  const journalList = useSelector(getJournalList);
  const isArchive = useSelector(getJournalIsArchive);
  const journalFilter = useSelector(getJournalFilter);
  const [
    isOpenReturnAllFromArchiveWarningModal,
    setOpenReturnAllFromArchiveWarningModal,
  ] = useState(false);
  const [
    isOpenReturnSelectedFromArchiveWarningModal,
    setOpenReturnSelectedFromArchiveWarningModal,
  ] = useState(false);
  const [isOpenDownloadPostingsModal, setOpenDownloadPostingsModal] =
    useState(false);
  const [isDownloadAllChosen, setDownloadAllChosen] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector(getCommonUserDetail);

  const downloadFormat =
    Config.getConfigToCompany(
      ConfigTypes.WAY_TO_UPLOAD_TRANSACTIONS,
      user.occupations && user.occupations[0].company.id
    ) === "2" && props.isAdvance
      ? "2"
      : "1";

  useEffect(() => {
    setChosenItems([]);
    setDownloadAllChosen(false);
  }, [isArchive]);

  const checkChosenItem = (id: number): boolean => {
    return chosenItems.includes(id);
  };

  const clearAllItem = () => {
    setChosenItems([]);
    setDownloadAllChosen(false);
  };

  const toggleClickItem = (id: number) => {
    const copy = chosenItems.slice();
    if (checkChosenItem(id)) {
      copy.splice(copy.indexOf(id), 1);
    } else {
      copy.push(id);
    }
    setChosenItems(copy);
    if (copy.length === 0) {
      setDownloadAllChosen(false);
    } else if (copy.length === journalList.length) {
      setDownloadAllChosen(true);
    }
  };

  const clickAll = () => {
    let selectedItems: number[] = [];
    if (chosenItems.length) {
      setChosenItems([]);
      setDownloadAllChosen(false);
    } else {
      journalList.forEach((item) => {
        selectedItems.push(item.referenceId);
      });
      setChosenItems(selectedItems);
      setDownloadAllChosen(true);
    }
  };

  const getObjectIdsAsParams = (objectIds: number[]): string => {
    let result = "?";
    let params: string[] = [];
    objectIds.map((item) => {
      params.push(`ObjectIds=${item}`);
    });
    if (params.length) {
      result += params.join("&");
    }
    return result;
  };

  const getCityIdsAndObjectIdsAsParams = (): string => {
    let result = "";
    let params: string[] = [];
    const cities = journalFilter.cityId;
    if (cities && cities.length > 0) {
      cities.map((item: any) => {
        params.push(`CityId=${item}`);
      });
    }
    let itemNumbers =
      journalFilter.itemNumber && journalFilter.itemNumber.split(",");
    if (itemNumbers && itemNumbers.length) {
      itemNumbers.map((itemNumber: string) => {
        params.push(`ObjectIds=${itemNumber.trim()}`);
      });
    }
    if (params.length > 0) {
      result += "?";
      result += params.join("&");
    }
    return result;
  };

  const prepareFilterRequestParams = (): any => {
    return {
      CreateDate: journalFilter.dateCreate,
      loadDateStart: journalFilter.downloadDateStart,
      loadDateEnd: journalFilter.downloadDateEnd,
      LastLoadDateStart: journalFilter.downloadLastDateStart,
      LastLoadDateEnd: journalFilter.downloadLastDateEnd,
      JourneyNum: journalFilter.tripNumber,
      JourneyStartDate: journalFilter.tripDateStart,
      JourneyEndDate: journalFilter.tripDateEnd,
      EmployeeId: journalFilter.employee,
      batchID: journalFilter.uploadNumber,
      batchNumber: journalFilter.batchNumber,
      PostingDateStart: journalFilter.postingDateStart,
      PostingDateEnd: journalFilter.postingDateEnd,
    };
  };

  const downloadHandler = async (date?: string) => {
    dispatch(loaderLock());
    const data = isDownloadAllChosen
      ? getCityIdsAndObjectIdsAsParams()
      : !isEmpty(chosenItems)
        ? getObjectIdsAsParams(chosenItems)
        : "";
    const params = {
      responseType: "blob",
      params: isDownloadAllChosen
        ? {
            ...prepareFilterRequestParams(),
            PostingDate: date ? date : null,
            ObjectType: props.isAdvance
              ? "AdvanceReportApplication"
              : "ExpenseApplication",
            isArchive: false,
          }
        : {
            PostingDate: date ? date : null,
            ObjectType: props.isAdvance
              ? "AdvanceReportApplication"
              : "ExpenseApplication",
            isArchive: false,
          },
    };
    let response;
    if (downloadFormat === "2") {
      response = await postTransactionsFTPDownloadWithParams(data, params);
    } else {
      response = await postTransactionsDownloadWithParams(data, {}, params);
    }
    if (response.headers.success) {
      if (downloadFormat === "2") {
        dispatch(
          showInfo({
            code: "download_postings",
            message: t("journal.ftp_success"),
          })
        );
      } else {
        let contentDisposition = response.headers["content-disposition"];
        let fileName = contentDisposition.slice(
          contentDisposition.lastIndexOf("'") + 1
        );
        FileSaver.saveAs(response.data, decodeURI(fileName));
      }
      dispatch(
        updateJournalList(
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          false
        )
      );
      setChosenItems([]);
    } else {
      if (downloadFormat === "2") {
        const dateFormatted =
          date &&
          moment
            .utc(date, "YYYY-MM-DDThh:mm:ss")
            .locale("ru")
            .local()
            .format("DD MMMM YYYY")
            .toString();
        dispatch(
          showErrors({
            code: "approve_postings",
            message: t("journal.ftp_error", { date: dateFormatted }),
          })
        );
      } else {
        dispatch(
          showErrors({
            code: "approve_postings",
            message: "",
          })
        );
      }
    }
    dispatch(loaderUnlock());
  };

  const returnFromArchiveHandler = async (byFilter: boolean = false) => {
    dispatch(loaderLock());
    let response = await updateBatchesArchiveBulk(
      byFilter
        ? getCityIdsAndObjectIdsAsParams()
        : !isEmpty(chosenItems)
          ? getObjectIdsAsParams(chosenItems)
          : "",
      { isArchive: false },
      {
        params: byFilter ? prepareFilterRequestParams() : null,
      }
    );
    if (response.headers.success) {
      dispatch(
        updateJournalList(
          props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication",
          false
        )
      );
      setChosenItems([]);
    }
    dispatch(loaderUnlock());
  };

  const toggleDownloadPostingsModal = () => {
    setOpenDownloadPostingsModal(!isOpenDownloadPostingsModal);
    setDownloadAllChosen(false);
  };

  const downloadAllPostings = () => {
    if (journalList && !!journalList.length && !isArchive) {
      setDownloadAllChosen(true);
      toggleDownloadPostingsModal();
    }
  };

  const downloadSelectedPostings = () => {
    setDownloadAllChosen(false);
    toggleDownloadPostingsModal();
  };

  const returnAllFromArchive = () => {
    returnFromArchiveHandler(true);
  };

  const returnFromArchiveSelected = async () => {
    returnFromArchiveHandler(false);
  };

  const toggleReturnAllFromArchiveWarningModal = () => {
    setOpenReturnAllFromArchiveWarningModal(
      !isOpenReturnAllFromArchiveWarningModal
    );
  };

  const toggleReturnSelectedFromArchiveWarningModal = () => {
    setOpenReturnSelectedFromArchiveWarningModal(
      !isOpenReturnSelectedFromArchiveWarningModal
    );
  };

  return (
    <Fragment>
      <div className="journal-swap-controls">
        <div style={{ marginBottom: "-16px" }}>
          <Checkbox
            checked={isDownloadAllChosen}
            id={1}
            name={`check${1}`}
            label={t("journal.select_all")}
            onClick={clickAll}
            type={"checkbox"}
            disabled={!journalList.length}
          />
        </div>
        <If condition={isEmpty(chosenItems)}>
          <If condition={!isArchive}>
            <a
              className={`flex-center control pointer ${journalList && !!journalList.length ? "" : "disabled"}`}
              onClick={downloadAllPostings}
            >
              <SvgIcon
                className={"icon icon-download"}
                href={"#svg_icon_download"}
              />
              <span>{t("journal.unload_all")}</span>
            </a>
          </If>
          <If condition={isArchive}>
            <a
              className={`flex-center control pointer ${journalList && !!journalList.length ? "" : "disabled"}`}
              onClick={toggleReturnAllFromArchiveWarningModal}
            >
              <span>{t("journal.return_from_archive_all")}</span>
            </a>
          </If>
        </If>
        <If condition={!isEmpty(chosenItems)}>
          <div className="journal-swap-controls-counter">
            <span>
              {t("journal.selected", { count: chosenItems.length })} |
            </span>
            <a className={"pointer"} onClick={clearAllItem}>
              {t("journal.cancel_selection")}
            </a>
          </div>
          <If condition={!isArchive}>
            <a
              className="flex-center control pointer"
              onClick={downloadSelectedPostings}
            >
              <SvgIcon
                className={"icon icon-download"}
                href={"#svg_icon_download"}
              />
              <span>{t("journal.unload_selected")}</span>
            </a>
          </If>
          <If condition={isArchive}>
            <a
              className="flex-center control pointer"
              onClick={toggleReturnSelectedFromArchiveWarningModal}
            >
              <span>{t("journal.return_from_archive_selected")}</span>
            </a>
          </If>
        </If>
      </div>
      <div className="request-expenses-wrap journal-swap">
        <JournalList
          journalList={journalList}
          isAdvance={props.isAdvance ? true : false}
          isArchive={isArchive}
          toggleClickItem={toggleClickItem}
          chosenItems={chosenItems}
        />
      </div>
      <AssistanceWarningModal
        isOpen={isOpenReturnAllFromArchiveWarningModal}
        title={t("journal.return_from_archive_warning_title")}
        info={t("journal.return_from_archive_warning_info")}
        sub_info={t("journal.return_from_archive_warning_sub_info")}
        todo={t("journal.return_from_archive_warning_todo")}
        onClose={toggleReturnAllFromArchiveWarningModal}
        onSubmit={() => {
          toggleReturnAllFromArchiveWarningModal();
          returnAllFromArchive();
        }}
      />
      <AssistanceWarningModal
        isOpen={isOpenReturnSelectedFromArchiveWarningModal}
        title={t("journal.return_from_archive_warning_title")}
        info={t("journal.return_from_archive_warning_info")}
        sub_info={t("journal.return_from_archive_warning_sub_info")}
        todo={t("journal.return_from_archive_warning_todo")}
        onClose={toggleReturnSelectedFromArchiveWarningModal}
        onSubmit={() => {
          toggleReturnSelectedFromArchiveWarningModal();
          returnFromArchiveSelected();
        }}
      />
      <DownloadPostingsModal
        isOpen={isOpenDownloadPostingsModal}
        isList={true}
        onClose={toggleDownloadPostingsModal}
        onSubmit={(date) => {
          toggleDownloadPostingsModal();
          downloadHandler(date);
          setDownloadAllChosen(false);
        }}
      />
    </Fragment>
  );
};

export default JournalPage;
