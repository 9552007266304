import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  CSSProperties,
} from "react";
import { useTranslation } from "react-i18next";
import { nameFormatter, NameFormats } from "app/utils";
import SvgIcon from "../svg-icon/SvgIcon";
import { get } from "lodash";
import { getEmployeeByParams } from "../../../services/ApiService";

interface OccupationAutocompleteProps {
  defaultText?: string;
  placeholder?: string;
  onChoose?: (option: OccupationAutocompleteOptionType) => void;
  readOnly?: boolean;
  ref?: any;
  withPosition?: boolean;
  style?: CSSProperties;
  tabIndex?: -1 | 0;
}

export interface OccupationAutocompleteOptionType {
  value: number;
  text: string;
}

const OccupationAutocomplete: React.FC<OccupationAutocompleteProps> =
  React.forwardRef((props: OccupationAutocompleteProps, ref: any) => {
    let { t, i18n } = useTranslation();

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(
      props.defaultText ? props.defaultText : "",
    );
    let [employees, setEmployees] = useState(
      [] as OccupationAutocompleteOptionType[],
    );
    let [employeesWithOccupations, setEmployeesWithOccupations] = useState(
      [] as any,
    );
    let [error, setError] = useState(false);
    let [employeeTimer, setEmployeeTimer] = useState({} as any);

    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
      setInputValue(props.defaultText ? props.defaultText : "");
    }, [props.defaultText]);

    const optionClickHandler = (option: OccupationAutocompleteOptionType) => {
      setInputValue(option.text);
      setOpen(false);
      if (props.onChoose) props.onChoose(option);
    };

    const changeInput = () => {
      props.onChoose && props.onChoose({} as OccupationAutocompleteOptionType);
      setInputValue(inputRef.current.value);
      if (inputRef.current.value.length > 2) {
        clearTimeout(employeeTimer);
        let name = inputRef.current.value;
        (() => {
          setEmployeeTimer(
            setTimeout(() => {
              getEmployee(name);
            }, 800),
          );
        })();
      } else {
        setEmployees([] as OccupationAutocompleteOptionType[]);
        setOpen(false);
        setLoading(false);
      }
    };

    const missClickHandler = () => {
      if (isOpen) {
        setOpen(false);
      }
    };

    const getEmployee = async (nameValue: string) => {
      setError(false);
      setLoading(true);
      setEmployees([] as OccupationAutocompleteOptionType[]);
      setOpen(true);

      try {
        let result = await getEmployeeByParams({
          params: {
            Name: nameValue,
            Includes: props.withPosition ? "Position" : undefined,
          },
        });
        if (result.config.params.Name == inputRef.current.value) {
          let newEmployees = result.data.data
            .map((item: any) => {
              return item.occupations.map((occupation: any) => {
                let position = get(occupation, `position.${i18n.language}`, "");
                return {
                  employee: { ...item },
                  occupation: { ...occupation },
                  value: occupation.id,
                  text: props.withPosition
                    ? `${nameFormatter(item.name, NameFormats.FULL, i18n.language)}, ${occupation.company.name[i18n.language as "ru"]}, ${position}`
                    : `${nameFormatter(item.name, NameFormats.FULL, i18n.language)}, ${occupation.company.name[i18n.language as "ru"]}, ${occupation.name[i18n.language as "ru"]}`,
                };
              });
            })
            .flatMap((item: any) => item);

          setEmployeesWithOccupations(
            result.data.data.map((item: any) => {
              return {
                value: item.id,
                text: nameFormatter(item.name, NameFormats.FULL, i18n.language),
                occupations: item.occupations,
              };
            }),
          );
          setEmployees(newEmployees);
        }
      } catch (e) {
        setError(true);
      }

      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        setInputValue("");
      },
      getOccupations: (emolyeeId: number) => {
        return (
          employeesWithOccupations.find(
            (item: any) => item.value === emolyeeId,
          ) || []
        );
      },
      value: inputRef.current.value,
    }));

    return (
      <div className={"select " + (isOpen ? "active" : "")} style={props.style}>
        {isOpen ? (
          <div className="miss-click-handler" onClick={missClickHandler} />
        ) : (
          ""
        )}
        <input
          className="input"
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={changeInput}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          tabIndex={props.tabIndex}
        />
        <SvgIcon href={"#svg_icon_search"} className={"icon icon-search"} />
        <div
          className="select-content"
          style={{ zIndex: 20, maxHeight: "260px", fontSize: "16px", overflowY: "scroll" }}
        >
          {employees.length > 0
            ? employees.map((option, index) => {
                return (
                  <div
                    className="select-content-item"
                    key={index}
                    onClick={() => optionClickHandler(option)}
                  >
                    {option.text}
                  </div>
                );
              })
            : null}
          {!error && !isLoading && employees.length === 0 ? (
            <div className="select-content-item">
              {t("create_expense.no_employee_found")}{" "}
            </div>
          ) : null}
          {error ? (
            <div className="select-content-item">
              {t("create_expense.search_error")}{" "}
            </div>
          ) : null}
          {isLoading ? (
            <div className="select-content-item">
              <div
                className="lds-ellipsis"
                style={{ marginLeft: "calc(50% - 30px)" }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  });

export default OccupationAutocomplete;
