import React, { useState, Fragment, useEffect, useRef } from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useTranslation } from "react-i18next";
import { get, includes, uniqBy, uniq, flattenDeep } from "lodash";
import { HistoryAuditMapper } from "infrastructure/mappers";
import {
  HistoryAudit,
  ExpenseTypeItem,
  BusinessTarget,
  BusinessTargetIds,
} from "infrastructure/interfaces";
import moment from "moment";
import { showErrors } from "store/exception/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  hideHistory,
  historyUpdated,
  showHistory,
} from "store/common/actions";
import {
  statusExpenseStylesMap,
  getExpenseEnumByName,
  statusExpenseNameMap,
} from "infrastructure/enum/status-expense.enum";
import {
  statusReportStylesMap,
  getReportEnumByName,
  statusReportNameMap,
} from "infrastructure/enum/status-report.enum";
import analitiks from "services/AnaliticsService";
import { capitalizeFirstLetter, getCurrencieCode } from "app/utils";
import {
  getCommonBusinessTargetsCatalog,
  getCommonExpenseTypesCatalog,
  getCommonHistoryNeedUpdating,
  getCommonHistoryState,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  getCitiesByCitiesById,
  getHistoryByLogicalRout,
} from "../../../services/ApiService";

export interface HistoryProps {
  objectId: number;
  logicalRout: string;
}

interface CitysCatalog {
  id: number;
  name: {
    ru: string;
    en: string;
  };
}

const History: React.FC<HistoryProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const historyNeedUpdating = useSelector(getCommonHistoryNeedUpdating);
  let historyState: boolean = useSelector(getCommonHistoryState);
  let businessTargetsCatalog: BusinessTarget[] = useSelector(
    getCommonBusinessTargetsCatalog,
  );
  let expenseTypesCatalog: ExpenseTypeItem[] = useSelector(
    getCommonExpenseTypesCatalog,
  );

  let [history, setHistory] = useState([] as HistoryItemProps[]);
  let [rawHistory, setRawHistory] = useState([] as HistoryAudit[]);
  let [historyGeted, setHistoryGeted] = useState(false);
  let [historyCount, setHistoryCount] = useState(0);
  let [smallLoader, setSmallLoader] = useState(false);
  let [scrollFinish, setScrollFinish] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);
  let [width, setWidth] = useState(0);
  let [isReport, setIsReport] = useState(
    props.logicalRout == "AdvanceReportApplications",
  );
  let [expenseTypeIds, setExpenseTypeIds] = useState([] as number[]);
  let [businessTargetIds, setBusinessTargetIds] = useState(
    [] as BusinessTargetIds[],
  );
  let [cityIds, setCityIds] = useState([] as number[]);
  let [citysCatalog, setCitysCatalog] = useState([] as CitysCatalog[]);

  let [isHistoryOpen, setHistoryOpen] = useState(historyState);

  const user = useSelector(getCommonUserDetail);

  const setIds = (data: any) => {
    let expenseTypesArray: any[] = [];
    let businessTargetsArray: any[] = [];
    let citysArray: any[] = [];
    let changesArray = flattenDeep(
      data
        .map((historyItem: any) => historyItem.objects)
        .map((item: any) => item.map((i: any) => i.changes)),
    );
    changesArray.forEach((item: any) => {
      if (item.property === "ExpenseTypeId") {
        expenseTypesArray.push(item.oldValue);
        expenseTypesArray.push(item.newValue);
        expenseTypesArray.push(item.newNameEn);
        expenseTypesArray.push(item.newNameRu);
      }
      if (item.property === "BusinessTargetId") {
        businessTargetsArray.push(item.oldValue);
        businessTargetsArray.push(item.newValue);
      }
      if (
        item.property === "ArrivalToCityId" ||
        item.property === "DepartureFromCityId"
      ) {
        citysArray.push(item.oldValue);
        citysArray.push(item.newValue);
      }
    });
    setExpenseTypeIds(uniq(expenseTypesArray));
    setBusinessTargetIds(uniq(businessTargetsArray));
    setCityIds(uniq(citysArray));
  };

  const getHistory = async (page: number) => {
    let result = await getHistoryByLogicalRout(
      props.logicalRout,
      props.objectId,
      { params: { PageSize: 10, page: page } },
    );
    if (result.headers.success) {
      let historyAuditMapper = new HistoryAuditMapper();
      if (page == 1) {
        setRawHistory(historyAuditMapper.responsesToEntitys(result.data.data));
      } else {
        setRawHistory(
          uniqBy(
            [
              ...rawHistory,
              ...historyAuditMapper.responsesToEntitys(result.data.data),
            ],
            "id",
          ),
        );
      }
      dispatch(historyUpdated());
      if (result.data.data.length == 0) {
        setScrollFinish(true);
      }
      setIds(result.data.data);
    } else {
      dispatch(
        showErrors({
          code: "get_history_count",
          message: t("history.request_error", { status: result.status }),
        }),
      );
    }
    setSmallLoader(false);
  };

  const getFirstHistory = async () => {
    let result = await getHistoryByLogicalRout(
      props.logicalRout,
      props.objectId,
      { params: { PageSize: 10, page: 1 } },
    );
    if (result.headers.success) {
      let historyAuditMapper = new HistoryAuditMapper();
      setRawHistory(
        uniqBy(
          [
            ...historyAuditMapper.responsesToEntitys(result.data.data),
            ...rawHistory,
          ],
          "id",
        ),
      );
      dispatch(historyUpdated());
      setIds(result.data.data);
    } else {
      dispatch(
        showErrors({
          code: "get_history_count",
          message: t("history.request_error", { status: result.status }),
        }),
      );
    }
  };

  const getNextHistory = () => {
    analitiks(
      props.logicalRout == "ExpenseApplications"
        ? "ViewZNRHistory"
        : "ViewAOHistory",
      props.logicalRout == "ExpenseApplications"
        ? {
            znrId: +props.objectId,
            employeeId: user.id,
            occupationId: user.occupations[0].occupationId,
            companyId: user.occupations[0].company.id,
          }
        : {
            aoId: +props.objectId,
            employeeId: user.id,
            occupationId: user.occupations[0].occupationId,
            companyId: user.occupations[0].company.id,
          },
    );
    setSmallLoader(true);
    getHistory(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const getHistoryCount = async () => {
    let result = await getHistoryByLogicalRout(
      props.logicalRout,
      props.objectId,
      { params: { CountOnly: true } },
    );
    if (historyCount != result.data.itemsCount) {
      setHistoryCount(result.data.itemsCount);
    }
  };

  const ref = useRef({} as any);

  useEffect(() => {
    getFirstHistory();
  }, [historyCount]);

  useEffect(() => {
    if (cityIds.length !== 0) {
      getCitysByIds(cityIds);
    }
  }, [cityIds]);

  useEffect(() => {
    if (historyNeedUpdating) {
      getFirstHistory();
    }
  }, [historyNeedUpdating]);

  const resizeEventHandler = () => {
    if (width !== window.innerWidth) {
      resizeHandler();
      setWidth(window.innerWidth);
    }
  };

  const getCitysByIds = async (ids: number[]) => {
    const result = ids.filter(
      (id: number) => !citysCatalog.map((city: any) => +city.id).includes(+id),
    );
    if (result.length == 0) {
      return;
    }
    let tempArray = citysCatalog.map((city: any) => city.id);
    result.forEach((id) => tempArray.push(+id));
    const cityRequest = tempArray.join("&ids=");
    let citys = await getCitiesByCitiesById(cityRequest);
    if (citys.headers.success) {
      setCitysCatalog(get(citys, "data", []));
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth <= 1365) {
      dispatch(hideHistory());
    } else {
      dispatch(showHistory());
    }
  };

  useEffect(() => {
    setHistoryOpen(historyState);
    window.addEventListener("resize", resizeEventHandler);
    return () => {
      window.removeEventListener("resize", resizeEventHandler);
    };
  }, [historyState]);

  useEffect(() => {
    resizeHandler();
  }, []);

  useEffect(() => {
    setHistory(
      rawHistory.map((history) => {
        return {
          objectId: props.objectId,
          date: moment
            .utc(history.date, "YYYY-MM-DDThh:mm:ss")
            .locale(i18n.language as "ru")
            .local()
            .format("DD MMMM 'YY HH:mm"),
          autor: history.autor.fullName[i18n.language as "ru"],
          title: history.description[i18n.language as "ru"],
          type: history.type,
          isReport: isReport,
          changes: history.changes,
        };
      }),
    );
  }, [rawHistory, i18n.language]);

  useEffect(() => {
    if (!historyGeted) {
      setHistoryGeted(true);
      getHistoryCount();
    }
    let history: any;
    let scrollHandler: any;

    history = ref.current.querySelector(".simplebar-content");
    if (history) {
      scrollHandler = () => {
        if (
          !smallLoader &&
          !scrollFinish &&
          history.scrollTop + history.clientHeight >
            history.scrollHeight - history.scrollHeight * 0.2
        ) {
          getNextHistory();
        }
      };
      history.addEventListener("scroll", scrollHandler);
    }

    return () => {
      if (history && scrollHandler) {
        history.removeEventListener("scroll", scrollHandler);
      }
    };
  });

  return (
    <div
      className="history-request"
      ref={ref}
      style={{ display: isHistoryOpen ? "block" : "none", maxHeight: "300px" }}
    >
      <div
        className="history-request-items simplebar-content"
        style={{ padding: "5px 10px 10px 0px", maxHeight: "300px", overflowY: "scroll" }}
      >
        {history.map((item) => (
          <HistoryItem
            businessTargetsCatalog={businessTargetsCatalog}
            expenseTypesCatalog={expenseTypesCatalog}
            citysCatalog={citysCatalog}
            objectId={item.objectId}
            date={item.date}
            autor={item.autor}
            title={item.title}
            type={item.type}
            changes={item.changes}
            isReport={item.isReport}
          />
        ))}
      </div>
    </div>
  );
};

interface HistoryItemProps {
  businessTargetsCatalog?: BusinessTarget[];
  expenseTypesCatalog?: ExpenseTypeItem[];
  citysCatalog?: CitysCatalog[];
  objectId: number;
  date: string;
  autor: string;
  title: string;
  type: number;
  isReport: boolean;
  changes?: {
    id: number;
    property: string;
    changeType: number;
    oldValue: string;
    newValue: string;
    newNameRu: string;
    oldNameRu: string;
    oldNameEn: string;
  }[];
}

const HistoryItem: React.FC<HistoryItemProps> = (props) => {
  const { t, i18n } = useTranslation();

  const generateBody = () => {
    if (props.type == 8 && props.changes) {
      if (props.changes[0].changeType == 0) {
        return (
          <div className="request-list-item-status green">
            {props.changes[0].newValue}
          </div>
        );
      }
      if (props.changes[0].changeType == 2) {
        return (
          <div className="request-list-item-status red">
            {props.changes[0].oldValue}
          </div>
        );
      }
    }

    if (props.type == 6 && props.changes) {
      return props.changes.map((item) => {
        if (
          item.id != props.objectId &&
          !(item.newValue === null || item.newValue === undefined)
        ) {
          return generateProperty(item);
        }
      });
    }

    if (props.type == 20 && props.changes) {
      return props.changes.map((item) => {
        if (!["Number"].includes(item.property)) {
          return generateProperty(item);
        }
      });
    }

    if (props.type == 3 && props.changes) {
      return props.changes.map((item) => {
        if (
          !["Number", "AdvanceCashAmountIsEdited"].includes(item.property) &&
          item.newValue
        ) {
          return generateProperty(item);
        }
      });
    }

    if (props.type == 7 && props.changes) {
      return props.changes.map((item) => {
        if (
          item.id != props.objectId &&
          !(item.newValue === null || item.newValue === undefined)
        ) {
          return generateProperty(item);
        }
      });
    }

    if (props.type == 16 && props.changes) {
      return (
        <Fragment>
          <div className="request-list-item-status red">
            {props.changes[1].oldValue} - {props.changes[0].oldValue},{" "}
            {getCurrencieCode("rub")}
          </div>
          <div className="request-list-item-status">
            {t("history." + props.changes[2].property)}:&nbsp;
            <i>
              {
                props.changes[2][
                  ("oldName" +
                    capitalizeFirstLetter(i18n.language)) as "oldNameRu"
                ]
              }
            </i>
          </div>
        </Fragment>
      );
    }

    if (props.type == 4 && props.changes && !props.isReport) {
      return (
        <div
          className={`request-list-item-status ${statusExpenseStylesMap[getExpenseEnumByName(props.changes[0].newValue)]}`}
        >
          <span></span>Установлен статус:&nbsp;
          <i>
            {t(
              `status.${statusExpenseNameMap[getExpenseEnumByName(props.changes[0].newValue)]}`,
            )}
          </i>
        </div>
      ); //{props.changes[0].newValue}
    }

    if (props.type == 4 && props.changes && props.isReport) {
      return (
        <div
          className={`request-list-item-status ${statusReportStylesMap[getReportEnumByName(props.changes[0].newValue)]}`}
        >
          <span></span>Установлен статус:&nbsp;
          <i>
            {t(
              `report_status.${statusReportNameMap[getReportEnumByName(props.changes[0].newValue)]}`,
            )}
          </i>
        </div>
      ); //{props.changes[0].newValue}
    }

    if (
      props.changes && [
        props.changes[0].property === "Comment" ||
          props.changes[0].property === "CommentToImprove",
      ]
    ) {
      return <div>{props.changes[0].newValue}</div>;
    }
  };

  const getCityById = (id: number) => {
    if (props.citysCatalog) {
      const city = props.citysCatalog.find((city) => city.id == id);
      return city ? get(city, `name.${i18n.language as "en" | "ru"}`, "") : "";
    }
  };

  const getBusinessTargetById = (id: number) => {
    if (props.businessTargetsCatalog) {
      const businessTarget = props.businessTargetsCatalog.find(
        (city) => city.id == id,
      );
      return businessTarget
        ? get(businessTarget, `name.${i18n.language as "en" | "ru"}`, "")
        : "";
    }
  };

  const getExpenseTypeById = (id: number) => {
    if (props.expenseTypesCatalog) {
      const expenseType = props.expenseTypesCatalog.find(
        (city) => city.id == id,
      );
      return expenseType
        ? get(expenseType, `name.${i18n.language as "en" | "ru"}`, "")
        : "";
    }
  };

  const generateProperty = (item: {
    id: number;
    property: string;
    changeType: number;
    oldValue: string;
    newValue: string;
    newNameRu: string;
    oldNameRu: string;
  }) => {
    let numberProperty = ["PersonalDaysNumber"];
    let currencyProperty = [
      "Amount",
      "ApprovedAmountRub",
      "AmountRub",
      "AmountCurrencyRate",
      "ApprovedAmountCurrencyRate",
    ];
    let textProperty = [
      "Description",
      "ApprovedAmountComment",
      "VbsElement",
      "OrderNumber",
    ];
    let translatedProperty = ["PaymentFormId"];
    let booleanProperty = ["AdvanceCashRequired", "NotUsed"];
    let dateProperty = ["StartOn", "EndOn"];
    let cityProperty = ["DepartureFromCityId", "ArrivalToCityId"];
    let businessProperty = ["BusinessTargetId"];
    let expenseProperty = ["ExpenseTypeId"];
    let billDate = ["BillDate"];
    let billNumber = ["BillNumber"];
    let approvedAmount = ["ApprovedAmount"];
    let approvedAmountCurrencyId = ["ApprovedAmountCurrencyId"];
    let businessTargetId = ["BusinessTargetId"];
    let arrivalToCityId = ["ArrivalToCityId"];
    let departureFromCityId = ["DepartureFromCityId"];
    let endOn = ["EndOn"];
    let startOn = ["StartOn"];
    let advanceCashAmount = ["AdvanceCashAmount"];
    let amountCurrencyId = ["AmountCurrencyId"];

    if (includes(numberProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    if (includes(currencyProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    if (includes(textProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    if (includes(cityProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {getCityById(+item.oldValue)}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {getCityById(+item.newValue)}
          </i>
        </div>
      );
    }

    if (includes(expenseProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(billDate, item.property)) {
      let oldValue;
      let newValue;
      if (item.oldValue) {
        oldValue = moment(item.oldValue, "DD.MM.YYYY HH:mm:ss").format(
          "DD/MM/YYYY",
        );
      } else {
        oldValue = "";
      }
      if (item.newValue) {
        newValue = moment(item.newValue, "DD.MM.YYYY HH:mm:ss").format(
          "DD/MM/YYYY",
        );
      } else {
        newValue = "";
      }
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {newValue}
          </i>
        </div>
      );
    }

    if (includes(billNumber, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    if (includes(approvedAmount, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    if (includes(approvedAmountCurrencyId, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(amountCurrencyId, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(businessTargetId, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(departureFromCityId, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(arrivalToCityId, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldNameRu}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newNameRu}
          </i>
        </div>
      );
    }

    if (includes(endOn, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {moment(item.oldValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {moment(item.newValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
          </i>
        </div>
      );
    }

    if (includes(startOn, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {moment(item.oldValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {moment(item.newValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
          </i>
        </div>
      );
    }

    if (includes(businessProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {getBusinessTargetById(+item.oldValue)}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {getBusinessTargetById(+item.newValue)}
          </i>
        </div>
      );
    }

    if (includes(dateProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {moment(item.oldValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {moment(item.newValue, "DD.MM.YYYY HH:mm:ss").format("DD/MM/YYYY")}
          </i>
        </div>
      );
    }

    if (includes(translatedProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {t("history." + item.oldValue)}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {t("history." + item.newValue)}
          </i>
        </div>
      );
    }

    if (includes(booleanProperty, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {t("history." + item.oldValue)}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {t("history." + item.newValue)}
          </i>
        </div>
      );
    }

    if (includes(advanceCashAmount, item.property)) {
      return (
        <div className="request-list-item-status">
          {t("history." + item.property)}:&nbsp;
          <i>
            {item.oldValue}
            <SvgIcon
              className="icon icon-arrow-history-2"
              href="#svg_icon_arrow-history-2"
            />
            {item.newValue}
          </i>
        </div>
      );
    }

    return (
      <div className="request-list-item-status">
        {t("history." + item.property)}:&nbsp;
        <i>
          {item.oldNameRu}
          <SvgIcon
            className="icon icon-arrow-history-2"
            href="#svg_icon_arrow-history-2"
          />
          {item.newNameRu}
        </i>
      </div>
    );
  };

  return (
    <div className="history-request-item">
      <div className="history-request-item-header">
        <div>{props.date}</div>
        <div>{props.autor}</div>
      </div>
      <div className="history-request-item-bold">
        {props.title}
        <SvgIcon className="icon icon-arrow" href="#svg_icon_arrow-history" />
      </div>
      <div className="history-request-item-status">{generateBody()}</div>
    </div>
  );
};

export default History;
