import {
  downloadAdvanceReportApplicationsReport,
  downloadExpenseApplicationsReport,
  downloadPersonalIncomeTaxReport,
  getAdvanceReportApplicationsReportRegistry,
} from "services/ApiService";
import { OptionProp } from "./types";
import moment from "moment";
import { CompanyAutocompleteOptionType } from "../autocomplete/CompanyAutocomplete";
import FileSaver from "file-saver";
import { showErrors } from "store/exception/actions";

export const ReportOptions = [
  {
    id: 1,
    name: {
      ru: "Отчет по заявкам на расход (детализация по расходам)",
      en: "Expense Applications Detailed Report",
    },
    label: "ExpenseApplicationsDetailedReport",
  },
  {
    id: 2,
    name: {
      ru: "Отчет по АО (детализация по расходам)",
      en: "Advance Report Applications Detailed Report",
    },
    label: "AdvanceReportApplicationsDetailedReport",
  },
  {
    id: 3,
    name: {
      ru: "Отчет по заявкам на расход",
      en: "Expense Applications Report",
    },
    label: "ExpenseApplicationsReport",
  },
  {
    id: 4,
    name: {
      ru: "Отчет по АО",
      en: "Advance Report Applications Report",
    },
    label: "AdvanceReportApplicationsReport",
  },
  {
    id: 5,
    name: {
      ru: "Реестр Авансовых отчетов",
      en: "Advance Reports Registry",
    },
    label: "AdvanceReportsRegistry",
  },
  {
    id: 6,
    name: {
      ru: "Отчет по истории согласования АО",
      en: "Advance Reports History",
    },
    label: "AdvanceReportApplicationsApprovalHistoryReport",
  },
  {
    id: 7,
    name: {
      ru: "Отчет по НДФЛ",
      en: "Personal income tax report",
    },
    label: "PersonalIncomeTaxReport",
  },
];

export const typeOptions = [
  {
    name: {
      ru: "Трэвел",
      en: "Travel",
    },
    label: "Travel",
    id: 1,
  },
  {
    name: {
      ru: "Общехозяйственный расход",
      en: "General business",
    },
    label: "General",
    id: 2,
  },
];

export const getStatusesOptions = (type: OptionProp) => {
  let statuses = type.name.ru.includes("АО")
    ? [
        {
          name: {
            ru: "Черновик",
            en: "Draft",
          },
          id: 0,
          label: "draft",
        },
        {
          name: {
            ru: "Требует согласования",
            en: "Approval Requires",
          },
          id: 1,
          label: "approval_requires",
        },
        {
          name: {
            ru: "На согласовании",
            en: "On Approval",
          },
          id: 2,
          label: "on_approval",
        },
        {
          name: {
            ru: "Согласован",
            en: "Approved",
          },
          id: 3,
          label: "approved",
        },
        {
          name: {
            ru: "Не согласован",
            en: "Not Approved",
          },
          id: 5,
          label: "not_approved",
        },
        {
          name: {
            ru: "Отозван",
            en: "Revoked",
          },
          id: 4,
          label: "revoked",
        },
        {
          name: {
            ru: "Проведен",
            en: "Posted",
          },
          id: 6,
          label: "posted",
        },
        {
          name: {
            ru: "Отменен",
            en: "Cancelled",
          },
          id: 7,
          label: "cancelled",
        },
        {
          name: {
            ru: "На доработке",
            en: "On Revision",
          },
          id: 9,
          label: "on_revision",
        },
      ]
    : [
        {
          name: {
            ru: "Черновик",
            en: "Draft",
          },
          id: 0,
          label: "draft",
        },
        {
          name: {
            ru: "Требует согласования",
            en: "Approval Requires",
          },
          id: 1,
          label: "approval_requires",
        },
        {
          name: {
            ru: "На согласовании",
            en: "On Approval",
          },
          id: 2,
          label: "on_approval",
        },
        {
          name: {
            ru: "Согласована",
            en: "Approved",
          },
          id: 3,
          label: "approved",
        },
        {
          name: {
            ru: "Не согласована",
            en: "Not Approved",
          },
          id: 4,
          label: "not_approved",
        },
        {
          name: {
            ru: "Отозвана",
            en: "Revoked",
          },
          id: 5,
          label: "revoked",
        },
        {
          name: {
            ru: "Проведена",
            en: "Posted",
          },
          id: 6,
          label: "posted",
        },
        {
          name: {
            ru: "Отменена",
            en: "Cancelled",
          },
          id: 9,
          label: "cancelled",
        },
        {
          name: {
            ru: "На доработке",
            en: "On Revision",
          },
          id: 10,
          label: "on_revision",
        },
      ];

  return statuses;
};

export const cutStr = (str: string) => {
  if (str.length > 10) {
    return str.substring(0, 10) + "...";
  }
  return str;
};
export const cutNulls = (str: string): string => {
  return str.replace(/^0+/, "");
};

export const getArrayOfInt = (arr: OptionProp[], options?: OptionProp[]) => {
  let result = arr.map(({ id }) => id);

  if (options) {
    const optionsIds = options.map(({ id }) => id);
    const arraysAreEqual = optionsIds.every(
      (optionId, index) => optionId === result[index],
    );

    if (arraysAreEqual) {
      return [];
    }
  }

  return result;
};
export const getExternalReport = async (
  report: OptionProp,
  request: {},
  t: any,
  dispatch: any,
) => {
  if (report.name.ru.includes("АО") && report.label) {
    let result = await downloadAdvanceReportApplicationsReport(
      report.label,
      request,
      { responseType: "blob" },
    );

    if (result.headers.success) {
      let contentDisposition = result.headers["content-disposition"];
      let fileName = contentDisposition.slice(
        contentDisposition.lastIndexOf("'") + 1,
      );
      FileSaver.saveAs(result.data, decodeURI(fileName));
    } else {
      if (result.status === 404) {
        dispatch(
          showErrors({
            code: "registry_downloads",
            message: t("report.error_reports_not_found"),
          }),
        );
      } else {
        dispatch(
          showErrors({
            code: "registry_downloads",
            message: t("report.error_message_download"),
          }),
        );
      }
    }
  }
  if (report.name.ru.includes("заявкам на расход") && report.label) {
    let result = await downloadExpenseApplicationsReport(
      report.label,
      request,
      { responseType: "blob" },
    );
    if (result.headers.success) {
      let contentDisposition = result.headers["content-disposition"];
      let fileName = contentDisposition.slice(
        contentDisposition.lastIndexOf("'") + 1,
      );
      FileSaver.saveAs(result.data, decodeURI(fileName));
    } else {
      if (result.status === 404) {
        dispatch(
          showErrors({
            code: "registry_downloads",
            message: t("report.error_reports_not_found"),
          }),
        );
      } else {
        dispatch(
          showErrors({
            code: "registry_downloads",
            message: t("report.error_message_download"),
          }),
        );
      }
    }
  }
  if (report.name.ru.includes("НДФЛ") && report.label) {
	console.log(report)
	let result = await downloadPersonalIncomeTaxReport(
	  request,
	  { responseType: "blob" },
	);
	if (result.headers.success) {
	  let contentDisposition = result.headers["content-disposition"];
	  let fileName = contentDisposition.slice(
		contentDisposition.lastIndexOf("'") + 1,
	  );
	  FileSaver.saveAs(result.data, decodeURI(fileName));
	} else {
	  if (result.status === 404) {
		dispatch(
		  showErrors({
			code: "registry_downloads",
			message: t("report.error_reports_not_found"),
		  }),
		);
	  } else {
		dispatch(
		  showErrors({
			code: "registry_downloads",
			message: t("report.error_message_download"),
		  }),
		);
	  }
	}
  }
};

export const validateInternalReport = (
  downloadDateStart: null | Date,
  downloadDateEnd: null | Date,
  company: CompanyAutocompleteOptionType,
  setFieldsWithError: (a: string[]) => void,
): boolean => {
  let newFieldsWithError = [] as string[];

  let validDateStart = true;
  let validDateEnd = true;
  let validCompany = true;

  if (!downloadDateStart) {
    validDateStart = false;
    newFieldsWithError.push("date_from");
  }

  if (
    !downloadDateEnd ||
    downloadDateStart.valueOf() > downloadDateEnd.valueOf()
  ) {
    validDateEnd = false;
    newFieldsWithError.push("date_to");
  }

  if (!company || !company.value || company.value == 0) {
    validCompany = false;
    newFieldsWithError.push("company");
  }

  setFieldsWithError(newFieldsWithError);
  return validDateStart && validDateEnd && validCompany;
};

export const getInternalReport = async (
  downloadDateStart: null | Date,
  downloadDateEnd: null | Date,
  company: CompanyAutocompleteOptionType,
  t: any,
  dispatch: any,
) => {
  let response = await getAdvanceReportApplicationsReportRegistry({
    params: {
      dateFrom: moment(downloadDateStart).startOf("day").toDate() as Date,
      dateTo: moment(downloadDateEnd).endOf("day").toDate() as Date,

      companyId: company.value,
    },
    responseType: "blob",
  });

  if (response.headers.success) {
    let contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.slice(
      contentDisposition.lastIndexOf("'") + 1,
    );
    FileSaver.saveAs(response.data, decodeURI(fileName));
  } else {
    if (response.status === 404) {
      dispatch(
        showErrors({
          code: "registry_downloads",
          message: t("reports.registry_form.error_reports_not_found"),
        }),
      );
    } else {
      dispatch(
        showErrors({
          code: "registry_downloads",
          message: t("reports.registry_form.error_message_download"),
        }),
      );
    }
  }
};
