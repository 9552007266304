/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AOExpense,
  TravelDates,
  CurrencyItem,
  IOccupations,
} from "infrastructure/interfaces";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateDetailedAO } from "store/report/actions";
import {
  setCostCenterData,
  setCostCenterIsChanged,
  setPaymentForm as setStorePaymentForm,
} from "store/common/actions";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import { Expense } from "infrastructure/interfaces";
import {
  updateConsumptionType,
  updateCurrencies,
  updatePaymentForm,
  loaderLock,
  loaderUnlock,
  updateCurrencyRate,
  setCurrencyRate,
} from "../../../store/common/actions";
import { Checkbox } from "ui/Checkbox";
import Select, { SelectOptionType } from "../select/Select";
import ModalContainer from "./ModalContainer";
import { forEach, isEmpty, get } from "lodash";
import Config, { ConfigTypes } from "services/ConfigService";
import { showErrors, showInfo } from "store/exception/actions";
import Validator from "../validator/Validator";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import {
  numberFormatter,
  formatter,
  roundNumberToAccuracy,
  betweenTwoDates,
  getCurrencieCode,
  getCurrencyRate,
} from "app/utils";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import {
  STATUS_TYPE_REPORT,
  statusReportNameMap,
} from "infrastructure/enum/status-report.enum";
import { Exception } from "store/exception/actionTypes";
import {
  STATUS_TYPE,
  statusNameMap,
} from "infrastructure/enum/status-type.enum";
import If from "../util/If";
import Daily from "../daily/Daily";
import analitiks from "services/AnaliticsService";
import { isIE } from "react-device-detect";
import {
  ADVANCE_APPLICATION_PROPETIES,
  EXPENSE_PROPETIES,
} from "infrastructure/enum/object-properties.enum";
import CustomProperty from "../custom-property/CustomProperty";
import SelectWithSection from "../select/SelectWithSection";
import ADActModal from "./ADActModal";
import {
  getAdvanceReportApplication,
  getApplicationForExpenseDetail,
  getCommonConsumptionTypes,
  getCommonCurrencies,
  getCommonPaymentForms,
  getCommonUserDetail,
  getCommonApprovedCurrencyRate,
  getCommonAttachmentPermission,
  getCommonStateOptions,
} from "../../../store/selectors";
import {
  deleteAttachmentsById,
  getExpensesApiById,
  getFieldPermissionsCreateExpense,
  getFieldPermissionsUpdateExpense,
  getPerDiemCalculationsByObjectDetails,
  postAttachments,
  postExpenses,
  updateAdvanceReportAdditionalDocumentsAttach,
  updateAdvanceReportAdditionalDocumentsDetach,
  updateAdvanceReportApplicationsById,
  updateExpensesApiById,
  updateExpensesById,
  updateExpensesByIdUsed,
  updatePerDiems,
} from "../../../services/ApiService";
import { Button, Form, Modal } from "react-bootstrap";
import FileAttach from "./FileAttach";

interface CreateOrderModalProps {
  id: number;
  isOpen: boolean;
  onClose: (isSimpleClose?: boolean, isCloseWithNext?: any) => void;
  editId?: number | null;
  isAdvance?: boolean;
  dailyRenew?: boolean;
  dailyRenewWithoutAlert?: boolean;
  hasNext?: boolean;
  isWidget?: boolean;
}

const CreateOrderModal: React.FC<CreateOrderModalProps> = (props) => {
  const { t, i18n } = useTranslation();

  const consumptionTypes = useSelector(getCommonConsumptionTypes);
  const paymentForms = useSelector(getCommonPaymentForms);
  const expenseApplication = useSelector(getApplicationForExpenseDetail);
  const advanceReport = useSelector(getAdvanceReportApplication);
  const currencies = useSelector(getCommonCurrencies);
  const user = useSelector(getCommonUserDetail);
  const newApprovedCurrencyRate = useSelector(getCommonApprovedCurrencyRate);
  const {
    isExpenseApplicationAttachmentPermission,
    isAdvanceReportAttachmentPermission,
  } = useSelector(getCommonAttachmentPermission);
  let stateOptions = useSelector(getCommonStateOptions);

  const dispatch = useDispatch();

  const rubNumCode = 643;

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations
      .map((occupation: IOccupations) => occupation.roles)
      .flat()
      .map((item) => item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  };

  const consumptionTypeOptions: SelectOptionType[] = consumptionTypes.map(
    (type) => ({
      value: type.id,
      text: type.name[i18n.language as "en" | "ru"],
    })
  );

  const paymentFormOptions: SelectOptionType[] = paymentForms.map((form) => ({
    value: form.id,
    text: form.name[i18n.language as "en" | "ru"],
  }));

  const currenciesOptions: SelectOptionType[] = currencies.data.map(
    (option) => ({
      value: option.id,
      text: getCurrencieCode(option.alphaCode),
    })
  );

  let [editMode, setEditMode] = useState(false);
  let [description, setDescription] = useState("");
  let [sumDollar, setSumDollar] = useState("");
  let [sumRuble, setSumRuble] = useState("");
  let [courseDollar, setCourseDollar] = useState("1");
  let [consumptionType, setConsumptionType] = useState({} as SelectOptionType);
  let [paymentForm, setPaymentForm] = useState({} as SelectOptionType);
  let [currencyType, setCurrencyType] = useState({} as SelectOptionType);
  let [approvedCurrencyType, setApprovedCurrencyType] = useState(
    {} as SelectOptionType
  );
  let [approvedCurrencyRate, setApprovedCurrencyRate] = useState("1");
  let [approvedAmount, setApprovedAmount] = useState("");
  let [prepaidExpense, setPrepaidExpense] = useState(true);
  let [external, setExternal] = useState(false);
  let [deletedIds, setDeletedIds] = useState([] as any);
  let [attachFiles, setAttachFiles] = useState([] as any);
  let [approvedAmountComment, setApprovedAmountComment] = useState("");
  let [numberCheck, setNumberCheck] = useState("");
  let [approvedSum, setApprovedSum] = useState("0");
  let [billDate, setBillDate] = useState(null as any);
  let [isOpenCalendar, setOpenCalendar] = useState(false);
  let [expenseDisabled, setExpenseDisabled] = useState(false);
  let [expenseDisabledError, setExpenseDisabledError] = useState(
    {} as Exception
  );
  let [isDailyExpense, setIsDailyExpense] = useState(false);
  let [isChangedNewApprovedSum, setIsChangedNewApprovedSum] = useState(false);
  let [fieldsAccess, setFieldsAccess] = useState({
    type: false,
    description: false,
    amountRub: false,
    paymentForm: false,
    accountableFormNumber: false,
    attachments: false,
    approvedSum: false,
    approvedAmountComment: false,
    advanceCashRequired: false,
    attachFiles: false,
    numberBSO: false,
    amountCurrencyId: false,
    amount: false,
    amountCurrencyRate: false,
    billDate: false,
    billNumber: false,
    billAmountRub: false,
    approvedAmountCurrencyId: false,
    approvedAmount: false,
    approvedAmountCurrencyRate: false,
    approvedAmountRub: false,
  });

  let [allDateResult, setAllDateResult] = useState(
    [] as {
      day: Date;
      amountCurrencyId: number;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      valuesParamByDay: {
        parameterId: number;
        parameterValue: string;
      }[];
    }[]
  );
  let [daylyExpenseStep, setDaylyExpenseStep] = useState(0);
  let [isChangeApprovedSum, setIsChangeApprovedSum] = useState(false);
  let [expenseUsedOld, setExpenseUsedOld] = useState(false);
  let [expenseUsed, setExpenseUsed] = useState(false);
  let [numberBSO, setNumberBSO] = useState("");
  let [editPermissions, setEditPermissions] = useState([] as string[]);
  let [createPermissions, setCreatePermissions] = useState([] as string[]);
  let [readPermissions, setReadPermissions] = useState([] as string[]);
  let [advanceСalculationMethod, setAdvanceСalculationMethod] = useState(0);
  let [
    dependApprovedAmountCommentFromApprovedAmount,
    setDependApprovedAmountCommentFromApprovedAmount,
  ] = useState(false);
  let [abilityUseOrNotExpense, setAbilityUseOrNotExpense] = useState(false);
  let [
    isAvailableCurrencyForApprovedAmount,
    setIsAvailableCurrencyForApprovedAmount,
  ] = useState(false);
  // ZNR
  let [
    isAvailableToUpdateExpenseApplicationByCoordinator,
    setIsAvailableToUpdateExpenseApplicationByCoordinator,
  ] = useState(false);
  let [
    isAvailableToUpdateExpenseByAssigneeAsCurrentApprover,
    setIsAvailableToUpdateExpenseByAssigneeAsCurrentApprover,
  ] = useState(false);
  let [
    isAvailableToUpdateExpenseByCurrentApprover,
    setIsAvailableToUpdateExpenseByCurrentApprover,
  ] = useState(false);

  // AO
  let [
    isAvailableToUpdateAdvanceReportByCoordinator,
    setIsAvailableToUpdateAdvanceReportByCoordinator,
  ] = useState(false);
  let [
    isAvailableToUpdateAdvanceReportByAssigneeAsCurrentApprover,
    setIsAvailableToUpdateAdvanceReportByAssigneeAsCurrentApprover,
  ] = useState(false);
  let [
    isAvailableToUpdateAdvanceReportByCurrentApprover,
    setIsAvailableToUpdateAdvanceReportByCurrentApprover,
  ] = useState(false);

  let [approvedCurrenciesOptions, setApprovedCurrenciesOptions] = useState(
    [] as SelectOptionType[]
  );

  let [isOpenActModal, setIsOpenActModal] = useState(false);
  let [createActDetail, setCreateActDetail] = useState({
    expenseId: 0,
    expenseSum: 0,
    expenseCurrencyCode: "",
    expenseDescription: "",
  });
  let [additionalDocument, setAdditionalDocument] = useState(
    {} as SelectOptionType
  );
  let [canShowSelectReferToAct, setCanShowSelectReferToAct] = useState(false);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const commentRef = useRef({} as HTMLTextAreaElement);
  const numberCheckRef = useRef({} as HTMLInputElement);
  const approvedSumRef = useRef({} as HTMLInputElement);
  const descriptionRef = useRef({} as HTMLTextAreaElement);
  const sumDollarRef = useRef({} as HTMLInputElement);
  const sumRubleRef = useRef({} as HTMLInputElement);
  const courseDollarRef = useRef({} as HTMLInputElement);
  const approvedAmountRef = useRef({} as HTMLInputElement);
  const approvedCurrencyRateRef = useRef({} as HTMLInputElement);
  const numberBSORef = useRef({} as HTMLInputElement);
  const consumptionValidator = useRef({} as any);
  const currenciesValidator = useRef({} as any);
  const sumDollarValidator = useRef({} as any);
  const sumRubleValidator = useRef({} as any);
  const paymentFormValidator = useRef({} as any);
  const courseDollarValidator = useRef({} as any);
  const descriptionValidatorRef = useRef({} as any);
  const commentValidatorRef = useRef({} as any);
  const customPropertyRef = useRef({} as any);
  const dataRef = useRef({} as any);

  const isAttachmentPermission = () => {
    return props.isAdvance
      ? isAdvanceReportAttachmentPermission && !expenseDisabled
      : isExpenseApplicationAttachmentPermission && !expenseDisabled;
  };

  useEffect(() => {
    if (props.isOpen) {
      if (props.isAdvance) {
        isAOExpenseDisabled();
      } else {
        isZNRExpenseDisabled();
      }
      setIsChangeApprovedSum(false);
      setExpenseUsed(false);
      setExpenseUsedOld(false);
      initAdditionalDocument();
    } else {
      clearValues();
      return;
    }

    dispatch(updateCurrencies());

    if (props.editId) {
      initEditMode();
      getUpdatePermissions(props.editId);
      getReadPermissions(props.editId);
    } else {
      setEditMode(false);
      getCreatePermissions();
      if (
        props.isAdvance &&
        advanceReport &&
        advanceReport.applicationType &&
        advanceReport.applicationType.id
      ) {
        dispatch(
          updateConsumptionType(
            advanceReport.applicationType.id,
            advanceReport ? advanceReport.company.id : undefined
          )
        );
      } else if (
        expenseApplication &&
        expenseApplication.applicationType &&
        expenseApplication.applicationType.id
      ) {
        dispatch(
          updateConsumptionType(
            expenseApplication.applicationType.id,
            expenseApplication ? expenseApplication.company.id : undefined
          )
        );
      }
    }
    if (!isDailyExpense) {
      setInitialValues().then(() => {
        customPropertyRef.current.build();
      });
    }
  }, [props.isOpen]);

  const initAdditionalDocument = async () => {
    setCanShowSelectReferToAct(false);
    setAdditionalDocument({
      text: t("modals.create_order_modal.createNewOne"),
      value: 0,
    });

    if (!props.isAdvance) {
      return;
    }

    if (props.editId) {
      let expense = advanceReport.expenses.find(
        (item) => item.id === props.editId
      );

      if (expense) {
        let expenseId = expense.id;
        setCanShowSelectReferToAct(
          expense.expenseType.needIncludeToAdvanceReportAdditionalDocuments ||
            expense.expenseType.needIncludeToBusinessEventReportDocuments
        );
        if (expense.expenseType.needIncludeToAdvanceReportAdditionalDocuments) {
          setDocumentType(
            ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
          );
        } else if (
          expense.expenseType.needIncludeToBusinessEventReportDocuments
        ) {
          setDocumentType(ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT);
        }

        let newAdditionalDocument = advanceReport.additionalDocuments.find(
          (item) => item.expenses.map((ex: any) => ex.id).includes(expenseId)
        );
        if (newAdditionalDocument) {
          setAdditionalDocument(newAdditionalDocument);
        }
      }
    }
  };

  const getReadPermissions = async (id: number) => {
    let result = await getExpensesApiById(id);
    if (result.headers.success) {
      let permissions = get(result, "data.properties", []);
      const expense = props.isAdvance ? advanceReport.expenses.find(
        (item) => item.id === props.editId
	  ) : expenseApplication && expenseApplication.expenses.find(
		(item) => item.id === props.editId
	  );
      expense
        ? setReadPermissions([...permissions, ...expense.properties])
        : setReadPermissions(permissions);
    }
  };

  const getUpdatePermissions = async (id: number) => {
    let result = await getFieldPermissionsUpdateExpense(id);
    if (result.headers.success) {
      const permissionsRequest = result.data;
      setEditPermissions(permissionsRequest);
    }
  };

  const getCreatePermissions = async () => {
    let result = await getFieldPermissionsCreateExpense({
      params: {
        baseEntityId: props.id,
        baseEntityLogicalName: props.isAdvance
          ? "AdvanceReportApplication"
          : "ExpenseApplication",
      },
    });
    if (result.headers.success) {
      const permissionsRequest = result.data;
      setCreatePermissions(permissionsRequest);
    }
  };

  const generateClassName = (properties: string): string => {
    let classes = [];
    if (editMode) {
      !readPermissions.includes(properties) && classes.push("d-none");
      !editPermissions.includes(properties) && classes.push("disabled");
      return classes.join(" ");
    }
    !createPermissions.includes(properties) && classes.push("disabled");
    return classes.join(" ");
  };

  const isReadOnly = (property: string): boolean => {
    return (
      readPermissions.includes(property) &&
      !createPermissions.includes(property) &&
      !editPermissions.includes(property)
    );
  };

  useEffect(() => {
    if (advanceReport || expenseApplication) {
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      setAdvanceСalculationMethod(
        Config.getConfigToCompany(
          ConfigTypes.ADVANCE_CALCULATION_METHOD,
          companyId
        )
      );
      setDependApprovedAmountCommentFromApprovedAmount(
        Config.getConfigToCompany(
          ConfigTypes.DEPEND_APPROVED_AMOUNT_COMMENT_FROM_APPROVED_AMOUNT,
          companyId
        )
      );
      setAbilityUseOrNotExpense(
        Config.getConfigToCompany(
          ConfigTypes.ABILITY_USE_OR_NOT_EXPENSE,
          companyId
        )
      );
      setIsAvailableCurrencyForApprovedAmount(
        Config.getConfigToCompany(
          ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
          companyId
        )
      );

      setIsAvailableToUpdateExpenseApplicationByCoordinator(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_ON_APPROVAL_BY_COORDINATOR,
          companyId
        )
      );

      setIsAvailableToUpdateExpenseByAssigneeAsCurrentApprover(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER,
          companyId
        )
      );

      setIsAvailableToUpdateExpenseByCurrentApprover(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_EXPENSE_APPLICATION_BY_CURRENT_APPROVER,
          companyId
        )
      );

      setIsAvailableToUpdateAdvanceReportByCoordinator(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_ON_APPROVAL_BY_COORDINATOR,
          companyId
        )
      );

      setIsAvailableToUpdateAdvanceReportByAssigneeAsCurrentApprover(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_BY_ASSIGNEE_AS_CURRENT_APPROVER,
          companyId
        )
      );

      setIsAvailableToUpdateAdvanceReportByCurrentApprover(
        Config.getConfigToCompany(
          ConfigTypes.UPDATE_ADVANCE_REPORT_APPLICATION_BY_CURRENT_APPROVER,
          companyId
        )
      );
    }
  }, [props.isAdvance, advanceReport, expenseApplication]);

  useEffect(() => {
    let approvedCurrenciesOptions: SelectOptionType[] =
      isAvailableCurrencyForApprovedAmount
        ? currencies.data.map((option) => ({
            value: option.id,
            text: getCurrencieCode(option.alphaCode),
          }))
        : currencies.data
            .filter((option) => option.numCode === rubNumCode)
            .map((option) => ({
              value: option.id,
              text: getCurrencieCode(option.alphaCode),
            }));

    setApprovedCurrenciesOptions(approvedCurrenciesOptions);
  }, [isAvailableCurrencyForApprovedAmount, currencies]);

  useEffect(() => {
    if (
      props.isAdvance &&
      sumDollarRef &&
      sumDollarRef.current &&
      sumDollarRef.current.value &&
      courseDollar
    ) {
      setSumRuble(
        numberFormatter(
          roundNumberToAccuracy(
            +numberFormatter(sumDollarRef.current.value, true) *
              +numberFormatter(courseDollar, true),
            2
          ).toString()
        )
      );
    }
  }, [courseDollar]);

  useEffect(() => {
    if (props.isAdvance) {
      setApprovedCurrencyRate(
        numberFormatter(newApprovedCurrencyRate.toString())
      );
      if (
        approvedSumRef &&
        approvedSumRef.current &&
        approvedSumRef.current.value &&
        numberFormatter(approvedSumRef.current.value) ===
          numberFormatter(sumRuble)
      ) {
        setIsChangeApprovedSum(false);
      } else {
        setIsChangeApprovedSum(true);
      }
    }
  }, [newApprovedCurrencyRate]);

  useEffect(() => {
    if (paymentForms.length === 1 && !editMode) {
      setPaymentForm({
        value: paymentForms[0].id,
        text: paymentForms[0].name[i18n.language as "en" | "ru"],
      });
    }
  }, [paymentForms]);

  useEffect(() => {
    if (currencies.data.length === 1) {
      setCurrencyType({
        value: currencies.data[0].id,
        text: getCurrencieCode(currencies.data[0].alphaCode),
      });
      setApprovedCurrencyType({
        value: currencies.data[0].id,
        text: getCurrencieCode(currencies.data[0].alphaCode),
      });

      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let isAvailableCurrencyForApprovedAmount = Config.getConfigToCompany(
        ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
        companyId
      );

      if (!isAvailableCurrencyForApprovedAmount) {
        let rub: CurrencyItem =
          currencies.data.find((currency) => currency.numCode === rubNumCode) ||
          currencies.data[0]; //643 - rub numCode
        setApprovedCurrencyType({
          value: rub.id,
          text: getCurrencieCode(rub.alphaCode),
        });
      }
    }

    if (currencies.data.length > 1) {
      let rub: CurrencyItem =
        currencies.data.find(
          (currency) => currency.alphaCode.toLowerCase() === "rub"
        ) || currencies.data[0]; //643 - rub numCode
      setCurrencyType({
        value: rub.id,
        text: getCurrencieCode(rub.alphaCode),
      });
    }
  }, [currencies]);

  useEffect(() => {
    if (consumptionTypes.length === 1) {
      setConsumptionType({
        value: consumptionTypes[0].id,
        text: consumptionTypes[0].name[i18n.language as "en" | "ru"],
      });
    }
    if (consumptionType) {
      let fullConsumptionType = consumptionTypes.find((item) => {
        return item.id === consumptionType.value;
      });
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let perDiemCalculationType = Config.getConfigToCompany(
        ConfigTypes.PERDIEM_CALCULATION_TYPE,
        companyId
      );

      setIsDailyExpense(
        perDiemCalculationType !== 0 &&
          !!fullConsumptionType &&
          fullConsumptionType.expenseGroup.code === "Perdiem"
      );
    }
    if (props.dailyRenew) {
      initDaily();
    }
  }, [consumptionTypes]);

  const initDaily = async () => {
    let dailyType = consumptionTypes.find(
      (item) => item.expenseGroup.code === "Perdiem"
    );
    if (
      props.isAdvance &&
      dailyType &&
      dailyType.id &&
      advanceReport &&
      advanceReport.applicationType &&
      advanceReport.applicationType.id &&
      paymentForms.length === 1
    ) {
      setPaymentForm({} as SelectOptionType);
      await dispatch(
        updatePaymentForm(advanceReport.applicationType.id, dailyType.id)
      );
      setConsumptionType({
        value: dailyType.id,
        text: dailyType.name[i18n.language as "en" | "ru"],
      });
    } else if (
      dailyType &&
      dailyType.id &&
      expenseApplication &&
      expenseApplication.applicationType &&
      expenseApplication.applicationType.id &&
      paymentForms.length === 1
    ) {
      setPaymentForm({} as SelectOptionType);
      await dispatch(
        updatePaymentForm(
          get(expenseApplication, "applicationType.id", 0),
          dailyType.id
        )
      );
      setConsumptionType({
        value: dailyType.id,
        text: dailyType.name[i18n.language as "en" | "ru"],
      });
    }

    if (props.isOpen && !props.dailyRenewWithoutAlert) {
      if (props.isAdvance) {
        setPaymentForm({} as SelectOptionType);
        await dispatch(
          updatePaymentForm(advanceReport.applicationType.id, dailyType.id)
        );
      } else {
        setPaymentForm({} as SelectOptionType);
        await dispatch(
          updatePaymentForm(
            get(expenseApplication, "applicationType.id", 0),
            dailyType.id
          )
        );
      }
      if (dailyType && dailyType.id) {
        setConsumptionType({
          value: dailyType.id,
          text: dailyType.name[i18n.language as "en" | "ru"],
        });
      }
      dispatch(
        showInfo({
          code: "create_expense_daily_allowance_renew",
          message: t("create_expense.daily_allowance.renew"),
        })
      );
    }
  };

  useEffect(() => {
    if (consumptionType) {
      let fullConsumptionType = consumptionTypes.find((item) => {
        return item.id === consumptionType.value;
      });
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let perDiemCalculationType = Config.getConfigToCompany(
        ConfigTypes.PERDIEM_CALCULATION_TYPE,
        companyId
      );

      setIsDailyExpense(
        perDiemCalculationType !== 0 &&
          !!fullConsumptionType &&
          fullConsumptionType.expenseGroup.code === "Perdiem"
      );
    }
  }, [consumptionType]);

  useEffect(() => {
    if (
      props.isAdvance &&
      approvedAmount !== "" &&
      approvedAmountRef &&
      approvedAmountRef.current
    ) {
      setApprovedSum(
        numberFormatter(
          roundNumberToAccuracy(
            +numberFormatter(approvedAmountRef.current.value, true) *
              +numberFormatter(approvedCurrencyRate, true),
            2
          ).toString()
        )
      );
      if (isChangedNewApprovedSum) {
        setApprovedSum(
          numberFormatter(
            roundNumberToAccuracy(
              +numberFormatter(approvedAmountRef.current.value, true) *
                +numberFormatter(approvedCurrencyRate, true),
              2
            ).toString()
          )
        );
        setIsChangedNewApprovedSum(false);
        setIsChangeApprovedSum(true); //false
      }

      if (
        numberFormatter(
          roundNumberToAccuracy(
            +numberFormatter(approvedAmountRef.current.value, true) *
              +numberFormatter(approvedCurrencyRate, true),
            2
          ).toString()
        ) === numberFormatter(sumRuble)
      ) {
        setIsChangeApprovedSum(false);
      } else {
        setIsChangeApprovedSum(true);
      }
    }
  }, [approvedCurrencyRate]);

  useEffect(() => {
    if (props.isOpen) {
      setInitialValues();
    }
  }, [advanceReport, expenseApplication]);

  useEffect(() => {
    if (!isEmpty(currencies.data) && !editMode) {
      currencies.data.forEach((currency) => {
        if (currency.alphaCode === "rub") {
          setCurrencyType({
            text: getCurrencieCode(currency.alphaCode),
            value: currency.id,
          });
          if (props.isAdvance) {
            dispatch(
              updateCurrencyRate(
                1,
                "rub",
                advanceReport.startApprovalDate
                  ? advanceReport.startApprovalDate
                  : advanceReport.createdAt
              )
            );
          }
        }
      });
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let isAvailableCurrencyForApprovedAmount = Config.getConfigToCompany(
        ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
        companyId
      );

      if (!isAvailableCurrencyForApprovedAmount) {
        let rub: CurrencyItem =
          currencies.data.find((currency) => currency.numCode === rubNumCode) ||
          currencies.data[0]; ////643 - rub numCode
        setApprovedCurrencyType({
          value: rub.id,
          text: getCurrencieCode(rub.alphaCode),
        });
      }
    }
  }, [currencies]);

  const initEditMode = async () => {
    setEditMode(true);
    if (props.isAdvance) {
      await dispatch(await updateDetailedAO(props.id));
    } else {
      dispatch(updateApplicationForExpenseDetail(props.id));
    }
  };

  const hasState = (state: string) => {
    if (isEmpty(stateOptions)) {
      return false;
    }

    if (stateOptions.indexOf(state) !== -1) {
      return true;
    }

    return false;
  };

  const clearValues = async () => {
    setDescription("");
    setSumRuble("");
    setSumDollar("");
    setCourseDollar("1");
    setApprovedCurrencyRate("1");
    setConsumptionType({} as SelectOptionType);
    setPaymentForm({} as SelectOptionType);
    setPrepaidExpense(true);
    setNumberCheck("");
    setNumberBSO("");
    setBillDate(null);
    setApprovedAmountComment("");
    setExpenseUsed(false);
    setExpenseUsedOld(false);
    dispatch(setCurrencyRate(1));
    dispatch(setStorePaymentForm([]));
  };

  const getCurrencyTypeById = (id: number) => {
    let result = { text: "", value: 0, alphaCode: "" };
    currencies.data.forEach((item) => {
      if (item.id === id) {
        result = {
          text: getCurrencieCode(item.alphaCode),
          value: item.id,
          alphaCode: item.alphaCode.toLowerCase(),
        };
      }
    });
    return result;
  };

  const getApprovedCurrencyType = (id: number) => {
    let result = { text: "", value: 0, alphaCode: "" };
    let companyId = props.isAdvance
      ? advanceReport && advanceReport.company
        ? advanceReport.company.id
        : 0
      : expenseApplication && expenseApplication.company
        ? expenseApplication.company.id
        : 0;
    let isAvailableCurrencyForApprovedAmount = Config.getConfigToCompany(
      ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
      companyId
    );

    if (!isAvailableCurrencyForApprovedAmount) {
      let rub: CurrencyItem =
        currencies.data.find((currency) => currency.numCode === rubNumCode) ||
        currencies.data[0]; //643 - rub numCode

      if (rub) {
        const rubAlphaCode = rub.alphaCode;
        const rubId = rub.id;
        result = {
          text: getCurrencieCode(rubAlphaCode),
          value: rubId,
          alphaCode: rubAlphaCode.toLowerCase(),
        };
      }
    } else {
      currencies.data.forEach((item) => {
        if (item.id === id) {
          result = {
            text: getCurrencieCode(item.alphaCode),
            value: item.id,
            alphaCode: item.alphaCode.toLowerCase(),
          };
        }
      });
    }
    return result;
  };

  const setInitialValues = async () => {
    if (props.isAdvance && advanceReport && advanceReport.expenses) {
      let expense = advanceReport.expenses.find(
        (item) => item.id === props.editId
      );
      if (expense) {
        setExternal(expense.external);
        setPrepaidExpense(!!expense.advanceCashRequired);
        setDescription(expense.description);
        setSumRuble(
          expense.amountRub ? numberFormatter(expense.amountRub.toString()) : ""
        );
        setSumDollar(
          expense.amount ? numberFormatter(expense.amount.toString()) : ""
        );
        setApprovedAmountComment(expense.approvedAmountComment);
        setBillDate(expense.billDate);
        setApprovedSum(numberFormatter(expense.approvedAmountRub.toString()));
        setIsChangeApprovedSum(
          numberFormatter(expense.approvedAmountRub.toString()) !==
            (expense.amountRub
              ? numberFormatter(expense.amountRub.toString())
              : "")
        );
        setNumberCheck(expense.billNumber);
        setCurrencyType(getCurrencyTypeById(expense.amountCurrency.id));
        let companyId = props.isAdvance
          ? advanceReport && advanceReport.company
            ? advanceReport.company.id
            : 0
          : expenseApplication && expenseApplication.company
            ? expenseApplication.company.id
            : 0;
        let isAvailableCurrencyForApprovedAmount = Config.getConfigToCompany(
          ConfigTypes.IS_AVAILABLE_CURRENCY_FOR_APPROVED_AMOUNT,
          companyId
        );
        setApprovedAmount(
          !!expense.approvedAmount
            ? numberFormatter(expense.approvedAmount.toString())
            : isAvailableCurrencyForApprovedAmount
              ? numberFormatter(expense.amount.toString())
              : numberFormatter(expense.amountRub.toString())
        );
        setApprovedCurrencyRate(
          !!expense.approvedAmountCurrencyRate
            ? numberFormatter(expense.approvedAmountCurrencyRate.toString())
            : isAvailableCurrencyForApprovedAmount
              ? numberFormatter(expense.amountCurrencyRate.toString())
              : "1"
        );
        setApprovedCurrencyType(
          getApprovedCurrencyType(expense.approvedAmountCurrency.id)
        );
        if (expense.amountCurrencyRate === 0) {
          dispatch(
            updateCurrencyRate(
              expense.amountCurrency.id,
              expense.amountCurrency.alfaCode.toLocaleLowerCase(),
              advanceReport.startApprovalDate
                ? advanceReport.startApprovalDate
                : advanceReport.createdAt
            )
          );
        } else {
          setCourseDollar(
            numberFormatter(expense.amountCurrencyRate.toString())
          );
        }
        dispatch(
          updateConsumptionType(
            advanceReport.applicationType.id,
            advanceReport ? advanceReport.company.id : undefined
          )
        );
        setConsumptionType({
          value: expense.expenseType.id,
          text: expense.expenseType.name
            ? expense.expenseType.name[i18n.language as "en" | "ru"]
            : "",
        });
        dispatch(
          updatePaymentForm(
            advanceReport.applicationType.id,
            expense.expenseType.id
          )
        );
        expense.paymentFormId &&
          expense.paymentFormId.name &&
          setPaymentForm({
            value: expense.paymentFormId.id,
            text: expense.paymentFormId.name[i18n.language as "en" | "ru"],
          });
        setExpenseUsed(!!expense.notUsed);
        setExpenseUsedOld(!!expense.notUsed);
        setNumberBSO(expense.accountableFormNumber);
      }
    } else if (!props.isAdvance && expenseApplication) {
      let expense = expenseApplication.expenses.find(
        (item: any) => item.id === props.editId
      );
      if (expense) {
        setExternal(expense.external);
        setPrepaidExpense(!!expense.advanceCashRequired);
        setDescription(expense.description);
        setSumRuble(
          expense.amountRub ? numberFormatter(expense.amountRub.toString()) : ""
        );
        setSumDollar(
          expense.amount ? numberFormatter(expense.amount.toString()) : ""
        );
        setCurrencyType(
          expense.amountCurrency
            ? getCurrencyTypeById(expense.amountCurrency.id)
            : ({} as SelectOptionType)
        );
        setConsumptionType(
          expense.expenseType
            ? {
                value: expense.expenseType.id,
                text: expense.expenseType.name[i18n.language as "en" | "ru"],
              }
            : ({} as SelectOptionType)
        );
        if (
          expenseApplication &&
          expenseApplication.applicationType &&
          expense.expenseType
        ) {
          dispatch(
            updateConsumptionType(
              expenseApplication.applicationType.id,
              expenseApplication ? expenseApplication.company.id : undefined
            )
          );
          dispatch(
            updatePaymentForm(
              expenseApplication.applicationType.id,
              expense.expenseType.id
            )
          );
        }
        expense &&
          expense.paymentForm &&
          expense.paymentForm.id &&
          expense.paymentForm.name &&
          setPaymentForm({
            value: expense.paymentForm.id,
            text: expense.paymentForm.name[i18n.language as "en" | "ru"],
          });
        setExpenseUsed(!!expense.notUsed);
        setExpenseUsedOld(!!expense.notUsed);
        setNumberBSO(expense.accountableFormNumber);
      }
    }
  };

  const changeDesctiption = () => {
    setDescription(descriptionRef.current.value);
  };

  const changeComment = () => {
    setApprovedAmountComment(commentRef.current.value);
  };

  const changeNumberCheck = () => {
    setNumberCheck(numberCheckRef.current.value);
  };

  const changeNumberBSO = () => {
    setNumberBSO(numberBSORef.current.value);
  };

  const changeApprovedSum = () => {
    setApprovedSum(numberFormatter(approvedSumRef.current.value));
    if (
      numberFormatter(approvedSumRef.current.value) ===
      numberFormatter(sumRuble)
    ) {
      setIsChangeApprovedSum(false);
    } else {
      setIsChangeApprovedSum(true);
    }
  };

  const changeSumDollar = () => {
    if (isNumber16_2InputValid(sumDollarRef)) {
      sumDollarRef.current.value = sumDollar;
      return;
    }
    setSumDollar(sumDollarRef.current.value);
    setSumRuble(
      numberFormatter(
        roundNumberToAccuracy(
          +numberFormatter(sumDollarRef.current.value, true) *
            +numberFormatter(courseDollar, true),
          2
        ).toString()
      )
    );
  };

  const changeApprovedAmount = () => {
    if (isNumber16_2InputValid(approvedAmountRef)) {
      approvedAmountRef.current.value = approvedAmount;
      return;
    }
    setApprovedAmount(approvedAmountRef.current.value);
    setApprovedSum(
      numberFormatter(
        roundNumberToAccuracy(
          +numberFormatter(approvedAmountRef.current.value, true) *
            +numberFormatter(approvedCurrencyRate, true),
          2
        ).toString()
      )
    );
    if (
      numberFormatter(
        roundNumberToAccuracy(
          +numberFormatter(approvedAmountRef.current.value, true) *
            +numberFormatter(approvedCurrencyRate, true),
          2
        ).toString()
      ) === numberFormatter(sumRuble)
    ) {
      setIsChangeApprovedSum(false);
    } else {
      setIsChangeApprovedSum(true);
    }
  };

  const changeSumRuble = () => {
    if (isNumber16_2InputValid(sumRubleRef)) {
      sumRubleRef.current.value = sumRuble;
      return;
    }
    setSumRuble(sumRubleRef.current.value);
  };

  const toggleCalendar = () => {
    setOpenCalendar(!isOpenCalendar);
  };

  const changeCourseDollar = () => {
    if (isNumber16_8InputValid(courseDollarRef)) {
      courseDollarRef.current.value = courseDollar;
      return;
    }
    setCourseDollar(courseDollarRef.current.value);
    setSumRuble(
      numberFormatter(
        roundNumberToAccuracy(
          +numberFormatter(sumDollar, true) *
            +numberFormatter(courseDollarRef.current.value, true),
          2
        ).toString()
      )
    );
    setIsChangedNewApprovedSum(true);
  };

  const changeApprovedCourseDollar = () => {
    if (isNumber16_8InputValid(approvedCurrencyRateRef)) {
      approvedCurrencyRateRef.current.value = approvedCurrencyRate;
      return;
    }
    setApprovedCurrencyRate(approvedCurrencyRateRef.current.value);
    setIsChangedNewApprovedSum(true);
    if (
      numberFormatter(approvedSumRef.current.value) ===
      numberFormatter(sumRuble)
    ) {
      setIsChangeApprovedSum(false);
    } else {
      setIsChangeApprovedSum(true);
    }
  };

  const isNumber16_2InputValid = (ref: any) => {
    return (
      ref.current.value &&
      ref.current.value.match(/^\d{0,16}([,.]\d{0,2})?$/) === null
    );
  };

  const isNumber16_8InputValid = (ref: any) => {
    return (
      ref.current.value &&
      ref.current.value.match(/^\d{0,16}([,.]\d{0,8})?$/) === null
    );
  };

  const consumptionTypeChoseHandler = (option: SelectOptionType) => {
    if (!props.dailyRenew) {
      setPaymentForm({} as SelectOptionType);
      setConsumptionType(option);
      dispatch(
        updatePaymentForm(
          props.isAdvance
            ? advanceReport.applicationType.id
            : get(expenseApplication, "applicationType.id", 0),
          +option.value
        )
      );
    } else {
      if (
        props.isAdvance &&
        advanceReport &&
        advanceReport.applicationType &&
        advanceReport.applicationType.id
      ) {
        setPaymentForm({} as SelectOptionType);
        setConsumptionType(option);
        dispatch(
          updatePaymentForm(advanceReport.applicationType.id, +option.value)
        );
      } else if (
        expenseApplication &&
        expenseApplication.applicationType &&
        expenseApplication.applicationType.id
      ) {
        setPaymentForm({} as SelectOptionType);
        setConsumptionType(option);
        dispatch(
          updatePaymentForm(
            get(expenseApplication, "applicationType.id", 0),
            +option.value
          )
        );
      }
    }
    customPropertyRef.current.clear();
    customPropertyRef.current.build();
  };

  const paymentFormChoseHandler = (option: SelectOptionType) => {
    setPaymentForm(option);
  };

  const currencyTypeChoseHandler = async (option: SelectOptionType) => {
    setCurrencyType(option);
    let currentCurrencie = currencies.data.find((item) => {
      return item.id === option.value;
    });

    if (currentCurrencie) {
      if (currentCurrencie.alphaCode.toLocaleLowerCase() === "rub") {
        setCourseDollar("1");
      }

      dispatch(loaderLock());

      let rate = await getCurrencyRate(
        +option.value,
        currentCurrencie.alphaCode.toLocaleLowerCase(),
        advanceReport.startApprovalDate
          ? advanceReport.startApprovalDate
          : advanceReport.createdAt
      );
      if (rate) {
        setCourseDollar(numberFormatter(rate.toString()));
      } else {
        dispatch(
          showErrors({
            code: "get_currency_rate",
            message: "Не удалось получить курс валюты",
          })
        );
      }

      dispatch(loaderUnlock());
    }
  };

  const approvedCurrencyTypeChoseHandler = async (option: SelectOptionType) => {
    setApprovedCurrencyType(option);
    let currentCurrencie = currencies.data.find((item) => {
      return item.id === option.value;
    });

    if (currentCurrencie) {
      if (currentCurrencie.alphaCode.toLocaleLowerCase() === "rub") {
        setApprovedCurrencyRate("1");
      }

      dispatch(loaderLock());

      let rate = await getCurrencyRate(
        +option.value,
        currentCurrencie.alphaCode.toLocaleLowerCase(),
        advanceReport.startApprovalDate
          ? advanceReport.startApprovalDate
          : advanceReport.createdAt
      );
      if (rate) {
        setApprovedCurrencyRate(numberFormatter(rate.toString()));
      } else {
        dispatch(
          showErrors({
            code: "get_currency_rate",
            message: "Не удалось получить курс валюты",
          })
        );
      }

      dispatch(loaderUnlock());
    }
  };

  const prepaidExpenseClickHandler = () => {
    setPrepaidExpense(!prepaidExpense);
  };

  const onSelectBillDate = (value: any) => {
    setBillDate(value.startDate);
    toggleCalendar();
  };

  const loadingAttachFiles = (formData: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    return postAttachments(formData, config);
  };

  const toggleSubmitAttachFile = (filesId: any) => {
    const promiseAttachArray: any = [];
    forEach(attachFiles, (file) => {
      let formData = new FormData();
      formData.append("Content", file);
      formData.append("ObjectId.Id", filesId);
      formData.append("ObjectId.LogicalName", "Expense");
      promiseAttachArray.push(loadingAttachFiles(formData));
    });
    return Promise.all(promiseAttachArray);
  };

  const loadingDeleteFile = (deletedId: number) => {
    return deleteAttachmentsById(deletedId);
  };

  const toggleSubmitDeleteFile = (deletedIds: number[]) => {
    const promiseDeleteFileArray: any = [];
    forEach(deletedIds, (item) => {
      promiseDeleteFileArray.push(loadingDeleteFile(item));
    });
    return Promise.all(promiseDeleteFileArray);
  };

  const onChangeDailyStep = (values: number) => {
    setDaylyExpenseStep(values);
  };

  const onChangeExpenseUsed = () => {
    setExpenseUsed(!expenseUsed);
  };

  const onChangeResult = (
    values: {
      day: Date;
      amountCurrencyId: number;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      valuesParamByDay: {
        parameterId: number;
        parameterValue: string;
      }[];
    }[]
  ) => {
    setAllDateResult(values);
  };

  const daysCount = (applicationTravelDates: TravelDates[]) => {
    let daysCount = 0;
    applicationTravelDates.forEach((dates: TravelDates) => {
      daysCount = daysCount + betweenTwoDates(dates.startDate, dates.endDate);
    });
    return daysCount;
  };

  const saveDays = async (data: any) => {
    props.isAdvance
      ? await updateAdvanceReportApplicationsById(advanceReport.id, data)
      : await updateExpensesById(
          expenseApplication ? expenseApplication.id : 0,
          data
        );
  };

  const savePersonalTravel = async (dates: any) => {
    if (props.isAdvance) {
      if (advanceReport) {
        let data: any = {
          applicationTypeId: advanceReport.applicationType.id,
          businessTargetId: advanceReport.businessTarget.id,
          description: advanceReport.description,
          businessTripOption: {
            departureFromCityId: advanceReport.departureFromCityId.id,
            startOn: advanceReport.startOn,
            endOn: advanceReport.endOn,
            arrivalToCityId: advanceReport.arrivalToCityId.id,
            personalDaysNumber: daysCount(dates),
            applicationTravelDates: dates,
          },
        };
        await saveDays(data);
      }
    } else {
      if (expenseApplication) {
        let data: any = {
          applicationTypeId: expenseApplication.applicationType.id,
          businessTargetId: expenseApplication.businessTarget.id,
          description: expenseApplication.description,
          businessTripOption: {
            departureFromCityId: expenseApplication.departureFromCity.id,
            startOn: expenseApplication.startOn,
            endOn: expenseApplication.endOn,
            arrivalToCityId: expenseApplication.arrivalToCity.id,
            personalDaysNumber: daysCount(dates),
            applicationTravelDates: dates,
          },
        };
        await saveDays(data);
      }
    }
  };

  const saveAdditionalDocument = async (expenseId: number) => {
    if (!props.isAdvance) {
      return;
    }

    dispatch(loaderLock());
    if (canShowSelectReferToAct) {
      if (additionalDocument.value === 0) {
        setCreateActDetail({
          expenseId: expenseId,
          expenseSum: +numberFormatter(sumRuble, true),
          expenseCurrencyCode: getCurrencieCode("RUB"),
          expenseDescription: `${consumptionType.text} - ${description}`,
        });
        setIsOpenActModal(true);
      } else {
        let attachedExpense =
          await updateAdvanceReportAdditionalDocumentsAttach(
            additionalDocument.value,
            expenseId
          );
        await dispatch(updateDetailedAO(props.id));
      }
    }
    dispatch(loaderUnlock());
  };

  const editAdditionalDocument = async () => {
    if (!props.isAdvance) {
      return;
    }

    dispatch(loaderLock());
    if (canShowSelectReferToAct) {
      if (additionalDocument.value === 0) {
        setCreateActDetail({
          expenseId: props.editId ? props.editId : 0,
          expenseSum: +numberFormatter(sumRuble, true),
          expenseCurrencyCode: getCurrencieCode("RUB"),
          expenseDescription: description,
        });
        setIsOpenActModal(true);
      } else {
        let additionalDocumentsWithExpense = advanceReport.additionalDocuments
          .filter((document) =>
            document.expenses.map((ex: any) => ex.id).includes(props.editId)
          )
          .map((document) => document.id);

        let attachAdditionalDocuments = additionalDocumentsWithExpense.find(
          (item) => item === additionalDocument.value
        );
        let detachAdditionalDocuments = additionalDocumentsWithExpense.filter(
          (item) => item !== additionalDocument.value
        );

        if (!attachAdditionalDocuments) {
          let attachedExpense =
            await updateAdvanceReportAdditionalDocumentsAttach(
              additionalDocument.value,
              props.editId
            );
        }

        for (let index = 0; index < detachAdditionalDocuments.length; index++) {
          const detachAdditionalDocument = detachAdditionalDocuments[index];
          let detachedExpense =
            await updateAdvanceReportAdditionalDocumentsDetach(
              detachAdditionalDocument,
              props.editId
            );
        }

        await dispatch(updateDetailedAO(props.id));
        props.onClose(false, true);
      }
    }
    dispatch(loaderUnlock());
  };

  const handleActClose = () => {
    setIsOpenActModal(false);
    props.onClose(true, true);
  };

  const handleActComplete = async () => {
    dispatch(loaderLock());
    setIsOpenActModal(false);
    await dispatch(await updateDetailedAO(props.id));
    props.onClose(false, true);
    dispatch(loaderUnlock());
  };

  const saveDailyExpense = async () => {
    if (daylyExpenseStep === 1) {
      dispatch(
        showErrors({
          code: "save_expense",
          message: t("create_expense.daily_allowance.error_step_1"),
        })
      );
      return;
    }
    if (daylyExpenseStep === 2) {
      dispatch(
        showErrors({
          code: "save_expense",
          message: t("create_expense.daily_allowance.error_step_2"),
        })
      );
      return;
    }
    if (daylyExpenseStep === 3) {
      dispatch(
        showErrors({
          code: "save_expense",
          message: t("create_expense.daily_allowance.error_step_3"),
        })
      );
      return;
    }
    if (
      editPermissions.includes(
        EXPENSE_PROPETIES.EXPENSE_RESTRICT_EXPENSE_AMOUNT_CHANGES
      )
    ) {
      let calculationResult = await getPerDiemCalculationsByObjectDetails(
        props.id,
        props.isAdvance ? "AdvanceReportApplication" : "ExpenseApplication"
      );
      if (
        calculationResult.data &&
        calculationResult.data.value.calculations.length > 0
      ) {
        const startSum = calculationResult.data.value.calculations.reduce(
          (acc: number, item: any) => acc + item.calculation.amountRub,
          0
        );
        const sum = allDateResult.reduce(
          (acc: number, item: any) => acc + item.amountRub,
          0
        );
        if (startSum < sum) {
          dispatch(
            showErrors({
              code: "save_expense",
              message: t("modals.create_order_modal.validate.decrement"),
            })
          );
          return;
        }
      }
    }
    dispatch(loaderLock());
    const privateDay = allDateResult.map((item) => {
      let privatPrams = item.valuesParamByDay.filter(
        (param) => param.parameterId === 1 && param.parameterValue === "true"
      );
      if (privatPrams[0]) {
        return item;
      }
    });

    const travelDates = privateDay
      .filter((item) => item)
      .map((date) => {
        if (date) {
          return { startDate: date.day, endDate: date.day };
        }
      });
    await savePersonalTravel(travelDates);

    let newAllDateResult = allDateResult.map((item) => {
      item.amountRub = roundNumberToAccuracy(item.amountRub, 2);
      return item;
    });

    let uniqCurrency = [] as string[];

    allDateResult
      .filter((item) => item.amountRub !== 0)
      .forEach((item) => {
        if (
          !uniqCurrency.includes(
            item.amountCurrencyId + "-" + item.currencyRate
          )
        ) {
          uniqCurrency.push(item.amountCurrencyId + "-" + item.currencyRate);
        }
      });

    let newExpense = uniqCurrency.map((currencyAndRate) => {
      let newData: any = {
        applicationId: {
          id: +props.id,
          logicalName: props.isAdvance
            ? "AdvanceReportApplication"
            : "ExpenseApplication",
        },
        expenseTypeId: +consumptionType.value,
        advanceCashRequired: false,
        amount: 0,
        amountCurrencyId: 0,
        amountRub: 0,
        paymentFormId: 0,
        description: consumptionType.text,
        amountCurrencyRate: 0,
      };
      allDateResult
        .filter((item) => item.amountRub !== 0)
        .forEach((date) => {
          if (
            currencyAndRate ===
            date.amountCurrencyId + "-" + date.currencyRate
          ) {
            newData.amount += date.amountCurrency;
            newData.amountRub += date.amountRub;
            newData.paymentFormId = date.paymentForm.value;
            newData.amountCurrencyId = date.amountCurrencyId;
            newData.amountCurrencyRate = date.currencyRate;
          }
        });
      newData.amount = roundNumberToAccuracy(newData.amount, 2);
      newData.amountRub = roundNumberToAccuracy(
        newData.amount * newData.amountCurrencyRate,
        2
      );
      return newData;
    });
    const logicalName = props.isAdvance
      ? "AdvanceReportApplication"
      : "ExpenseApplication";

    let сustomPropertiesValue = await customPropertyRef.current.getData();
    const newdata = {
      applicationTravelDates: travelDates,
      calculations: newAllDateResult,
      expenses: newExpense,
      companyId: props.isAdvance
        ? advanceReport.company.id
        : expenseApplication.company.id,
      accountId: user.id,
      personalDaysNumber: props.isAdvance
        ? advanceReport.personalDaysNumber
        : expenseApplication.personalDaysNumber,
      сustomProperties: сustomPropertiesValue ? сustomPropertiesValue : [],
    };
    await updatePerDiems(props.id, logicalName, newdata);

    props.isAdvance
      ? await dispatch(await updateDetailedAO(props.id))
      : await dispatch(updateApplicationForExpenseDetail(props.id));

    props.onClose(false);

    dispatch(loaderUnlock());
  };

  const saveSubmit = async (data: any) => {
    dispatch(loaderLock());
    let response = await postExpenses(data);
    if (response.headers.success) {
      await customPropertyRef.current.saveById(response.data.id);
      await saveAdditionalDocument(response.data.id);
      analitiks(
        props.isAdvance ? "AddingExpenseToAO" : "AddingExpenseToZNR",
        props.isAdvance
          ? {
              expenseId: response.data.id,
              type: response.data.expenseType.id,
              amount: response.data.amountRub,
              aoId: advanceReport ? advanceReport.id : null,
              employeeId: user.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
            }
          : {
              expenseId: response.data.id,
              type: response.data.expenseType.id,
              amount: response.data.amountRub,
              znrId: expenseApplication ? expenseApplication.id : null,
              employeeId: user.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
            }
      );
      if (!isEmpty(attachFiles)) {
        await toggleSubmitAttachFile(response.data.id);
      }
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let abilityUseOrNotExpense = Config.getConfigToCompany(
        ConfigTypes.ABILITY_USE_OR_NOT_EXPENSE,
        companyId
      );
      if (abilityUseOrNotExpense && props.isAdvance) {
        await usedExpense(response.data.id, expenseUsed);
      }
      let adjustAdvanceAmountWarning = get(
        response.data,
        "adjustAdvanceAmountWarning",
        false
      );
      if (adjustAdvanceAmountWarning) {
        dispatch(
          showInfo({
            code: "info.adjust_advance_amount",
            message: t("modals.info.adjust_advance_amount"),
          })
        );
      }
      props.isAdvance
        ? await dispatch(await updateDetailedAO(props.id))
        : dispatch(updateApplicationForExpenseDetail(props.id));
      props.onClose(false);
    } else {
      dispatch(
        showErrors({
          code: "save_expense",
          message: "Не удалось создать расход",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const editSubmit = async (data: any) => {
    dispatch(loaderLock());
    let companyId = props.isAdvance
      ? advanceReport && advanceReport.company
        ? advanceReport.company.id
        : 0
      : expenseApplication && expenseApplication.company
        ? expenseApplication.company.id
        : 0;
    let abilityUseOrNotExpense = Config.getConfigToCompany(
      ConfigTypes.ABILITY_USE_OR_NOT_EXPENSE,
      companyId
    );

    if (
      props.isAdvance &&
      abilityUseOrNotExpense &&
      expenseUsed !== expenseUsedOld
    ) {
      await usedExpense(props.editId as number, expenseUsed);
    }

    let expense: any;
    if (props.isAdvance) {
      expense =
        advanceReport.expenses.find((item) => item.id === props.editId) ||
        ({} as AOExpense);
    }

    let notChangedExpense = ["Avia", "Train", "Hotel", "Transfer", "Visa"];
    if (
      props.isAdvance &&
      expense.external &&
      notChangedExpense.includes(expense.expenseType.groupCode)
    ) {
      if (!isEmpty(attachFiles)) {
        await toggleSubmitAttachFile(props.editId);
      }
      let companyId = props.isAdvance
        ? advanceReport && advanceReport.company
          ? advanceReport.company.id
          : 0
        : expenseApplication && expenseApplication.company
          ? expenseApplication.company.id
          : 0;
      let editCostExternalExpense = Config.getConfigToCompany(
        ConfigTypes.EDIT_COST_EXTERNAL_EXPENSE,
        companyId
      );

      if (editCostExternalExpense) {
        let response = await updateExpensesApiById(props.editId, data);
        if (response.headers.success) {
          await editAdditionalDocument();
          analitiks(
            props.isAdvance ? "EditingExpenseAO" : "EditingExpenseZNR",
            props.isAdvance
              ? {
                  aoId: data.applicationId.id,
                  expenseId: props.editId,
                  employeeId: user.id,
                  occupationId: user.occupations[0].occupationId,
                  companyId: user.occupations[0].company.id,
                }
              : {
                  expenseId: props.editId,
                  znrId: data.applicationId.id,
                  employeeId: user.id,
                  occupationId: user.occupations[0].occupationId,
                  companyId: user.occupations[0].company.id,
                }
          );
          let adjustAdvanceAmountWarning = get(
            response.data,
            "adjustAdvanceAmountWarning",
            false
          );
          if (adjustAdvanceAmountWarning) {
            dispatch(
              showInfo({
                code: "info.adjust_advance_amount",
                message: t("modals.info.adjust_advance_amount"),
              })
            );
          }
          await dispatch(await updateDetailedAO(props.id));
          props.onClose(false);
          dispatch(loaderUnlock());
          return;
        } else {
          dispatch(
            showErrors({
              code: "edit_expense",
              message: "Не удалось отредактировать расход",
            })
          );
          await dispatch(await updateDetailedAO(props.id));
          props.onClose(false);
          dispatch(loaderUnlock());
          return;
        }
      } else {
        await dispatch(await updateDetailedAO(props.id));
        props.onClose(false);
        dispatch(loaderUnlock());
        return;
      }
    }

    let response = await updateExpensesApiById(props.editId, data);
    if (response.headers.success) {
      await customPropertyRef.current.edit();
      await editAdditionalDocument();
      analitiks(
        props.isAdvance ? "EditingExpenseAO" : "EditingExpenseZNR",
        props.isAdvance
          ? {
              expenseId: props.editId,
              aoId: data.applicationId.id,
              employeeId: user.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
            }
          : {
              expenseId: props.editId,
              znrId: data.applicationId.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
              employeeId: user.id,
            }
      );
      if (!isEmpty(attachFiles)) {
        await toggleSubmitAttachFile(props.editId);
      }
      if (!isEmpty(deletedIds)) {
        await toggleSubmitDeleteFile(deletedIds);
      }
      let adjustAdvanceAmountWarning = get(
        response.data,
        "adjustAdvanceAmountWarning",
        false
      );
      if (adjustAdvanceAmountWarning) {
        dispatch(
          showInfo({
            code: "info.adjust_advance_amount",
            message: t("modals.info.adjust_advance_amount"),
          })
        );
      }
      props.isAdvance
        ? await dispatch(await updateDetailedAO(props.id))
        : await dispatch(updateApplicationForExpenseDetail(props.id));
      props.onClose(false);
    } else {
      dispatch(
        showErrors({
          code: "edit_expense",
          message: "Не удалось отредактировать расход",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const usedExpense = async (id: number, state: boolean) => {
    dispatch(loaderLock());
    const config = {
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json-patch+json",
      },
    };
    let response = await updateExpensesByIdUsed(id, state, config);
    if (response.headers.success) {
      await editAdditionalDocument();
    } else {
      dispatch(
        showErrors({
          code: "edit_expense",
          message: "Не удалось отредактировать расход",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const validate = (): boolean => {
    let isValid = true;
    if (
      typeof sumRubleValidator.current.clear !== "function" ||
      typeof sumRubleValidator.current.validate !== "function"
    ) {
      return false;
    }
    let expense =
      (expenseApplication &&
        expenseApplication.expenses.find(
          (item: any) => item.id === props.editId
        )) ||
      ({} as Expense);
    if (external && !props.isAdvance && expenseApplication) {
      let notChangedExpense = ["Avia", "Train", "Hotel", "Transfer", "Visa"];
      if (
        notChangedExpense.includes(expense.expenseType.groupCode) &&
        (expense.amount < +sumDollar || expense.amountRub < +sumRuble)
      ) {
        dispatch(
          showErrors({
            code: "save_edit_expense",
            message: t("modals.create_order_modal.validate.decrement"),
          })
        );
        return false;
      }
    }
    let twoItems: string[] = sumRuble.split(",");
    let values =
      advanceReport &&
      advanceReport.expenses &&
      advanceReport.expenses.find((item) => item.id === props.editId);
    if (
      editPermissions.includes(
        EXPENSE_PROPETIES.EXPENSE_RESTRICT_EXPENSE_AMOUNT_CHANGES
      ) &&
      values &&
      (values.amount < +sumDollar ||
        values.amountRub < +sumRuble ||
        values.approvedAmount < Number(approvedSum) ||
        values.approvedAmountRub < Number(approvedAmount))
    ) {
      dispatch(
        showErrors({
          code: "save_edit_expense",
          message: t("modals.create_order_modal.validate.decrement"),
        })
      );
      isValid = false;
      setInitialValues();
    }
    if (twoItems[0].length > 18) {
      dispatch(
        showErrors({
          code: "save_edit_expense",
          message: t("modals.create_order_modal.validate.length18", {
            fieldName: t("modals.create_order_modal.amount_expense", {
              currency: getCurrencieCode("rub"),
            }),
          }),
        })
      );
      isValid = false;
    }
    if (+numberFormatter(sumRuble, true) === 0) {
      sumRubleRef.current.focus();
      sumRubleValidator.current.clear();
      sumRubleValidator.current.validate();
      isValid = false;
    }
    let validateList = [
      paymentFormValidator.current.validate(),
      consumptionValidator.current.validate(),
      descriptionValidatorRef.current.validate(),
    ];

    let companyId = props.isAdvance
      ? advanceReport && advanceReport.company
        ? advanceReport.company.id
        : 0
      : expenseApplication && expenseApplication.company
        ? expenseApplication.company.id
        : 0;
    let dependApprovedAmountCommentFromApprovedAmount =
      Config.getConfigToCompany(
        ConfigTypes.DEPEND_APPROVED_AMOUNT_COMMENT_FROM_APPROVED_AMOUNT,
        companyId
      );

    if (
      dependApprovedAmountCommentFromApprovedAmount &&
      isChangeApprovedSum &&
      isBookerOccupation() &&
      advanceReport.status === STATUS_TYPE_REPORT.ON_APPROVAL &&
      hasState("Approve") &&
      advanceReport.assigneeEmployee.id !== user.id
    ) {
      validateList.push(commentValidatorRef.current.validate());
    }
    if (props.isAdvance) {
      validateList = [
        ...validateList,
        sumDollarValidator.current.validate(),
        courseDollarValidator.current.validate(),
        currenciesValidator.current.validate(),
      ];
    } else {
      validateList = [...validateList, sumRubleValidator.current.validate()];
    }
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
      }
    });
    if (!customPropertyRef.current.validate()) {
      isValid = false;
    }
    return isValid;
  };

  const saveHandler = () => {
    dispatch(setCostCenterData(null));
    dispatch(setCostCenterIsChanged(false));
    if (expenseDisabled) {
      dispatch(showErrors(expenseDisabledError));
      return;
    }

    if (isDailyExpense) {
      saveDailyExpense();
      return;
    }
    if (validate()) {
      if (props.editId) {
        let companyId = props.isAdvance
          ? advanceReport && advanceReport.company
            ? advanceReport.company.id
            : 0
          : expenseApplication && expenseApplication.company
            ? expenseApplication.company.id
            : 0;
        let advanceСalculationMethod = Config.getConfigToCompany(
          ConfigTypes.ADVANCE_CALCULATION_METHOD,
          companyId
        );
        editSubmit(dataRef.current);
      } else {
        let companyId = props.isAdvance
          ? advanceReport && advanceReport.company
            ? advanceReport.company.id
            : 0
          : expenseApplication && expenseApplication.company
            ? expenseApplication.company.id
            : 0;
        let advanceСalculationMethod = Config.getConfigToCompany(
          ConfigTypes.ADVANCE_CALCULATION_METHOD,
          companyId
        );
        saveSubmit(dataRef.current);
      }
    }
  };

  useEffect(() => {
    dataRef.current.applicationId = {
      id: +props.id,
      logicalName: props.isAdvance
        ? "AdvanceReportApplication"
        : "ExpenseApplication",
    };
    dataRef.current.advanceCashRequired = !advanceСalculationMethod
      ? props.isAdvance
        ? false
        : prepaidExpense
      : false;
  }, [props]);

  useEffect(() => {
    dataRef.current.amountCurrencyId = currencyType.value;
  }, [currencyType]);

  useEffect(() => {
    dataRef.current.amountRub = +numberFormatter(sumRuble, true);
    dataRef.current.amount = props.isAdvance
      ? +numberFormatter(sumDollar, true)
      : +numberFormatter(sumRuble, true);
  }, [sumRuble]);

  useEffect(() => {
    dataRef.current.paymentFormId = +paymentForm.value;
  }, [paymentForm]);

  useEffect(() => {
    dataRef.current.description = description;
  }, [description]);

  useEffect(() => {
    if (props.isAdvance) {
      dataRef.current.billNumber = numberCheck;
    }
  }, [numberCheck]);

  useEffect(() => {
    dataRef.current.amountCurrencyRate = +numberFormatter(courseDollar, true);
  }, [courseDollar]);

  useEffect(() => {
    dataRef.current.accountableFormNumber = numberBSO;
  }, [numberBSO]);

  useEffect(() => {
    dataRef.current.billDate = billDate;
  }, [billDate]);

  useEffect(() => {
    dataRef.current.expenseTypeId = +consumptionType.value;
  }, [consumptionType]);

  useEffect(() => {
    dataRef.current.approvedAmountComment = approvedAmountComment;
  }, [approvedAmountComment]);

  useEffect(() => {
    dataRef.current.approvedAmountRub = +numberFormatter(approvedSum, true);
  }, [approvedSum]);

  useEffect(() => {
    dataRef.current.approvedAmount = +numberFormatter(approvedAmount, true);
  }, [approvedAmount]);

  useEffect(() => {
    dataRef.current.approvedAmountCurrencyId = approvedCurrencyType.value;
  }, [approvedCurrencyType.value]);

  useEffect(() => {
    dataRef.current.approvedAmountCurrencyRate = +numberFormatter(
      approvedCurrencyRate,
      true
    );
  }, [approvedCurrencyRate]);

  useEffect(() => {
    dataRef.current.consumptionType = consumptionType;
  }, [consumptionType]);

  const isAOExpenseDisabled = () => {
    let isAvailableToUpdateAsCurrentApproval =
      advanceReport.status === STATUS_TYPE_REPORT.ON_APPROVAL &&
      isAvailableToUpdateAdvanceReportByAssigneeAsCurrentApprover &&
      isAvailableToUpdateAdvanceReportByCurrentApprover;
    let isAvailableToUpdateAsCoordinator =
      advanceReport.status === STATUS_TYPE_REPORT.ON_APPROVAL &&
      isAvailableToUpdateAdvanceReportByCoordinator;

    if (advanceReport) {
      if (
        isAvailableToUpdateAsCurrentApproval ||
        isAvailableToUpdateAsCoordinator ||
        isBookerOccupation()
      ) {
        setExpenseDisabled(false);
      } else if (
        !isAvailableToUpdateAsCurrentApproval &&
        !isAvailableToUpdateAsCoordinator &&
        (advanceReport.status === STATUS_TYPE_REPORT.APPROVED ||
          advanceReport.status === STATUS_TYPE_REPORT.ON_APPROVAL)
      ) {
        setExpenseDisabled(true);
        setExpenseDisabledError({
          code: "",
          message: t("status_error_message.status_expense_edit_RA", {
            status: t(
              `report_status.${
                statusReportNameMap[advanceReport.status as STATUS_TYPE_REPORT]
              }`
            ),
          }),
        });
      } else if (
        !isAvailableToUpdateAsCurrentApproval &&
        !isAvailableToUpdateAsCoordinator &&
        advanceReport.status === STATUS_TYPE_REPORT.POSTED
      ) {
        setExpenseDisabled(true);
        setExpenseDisabledError({
          code: "",
          message: t("status_error_message.status__expense_edit_RA", {
            status: t(
              `report_status.${
                statusReportNameMap[advanceReport.status as STATUS_TYPE_REPORT]
              }`
            ),
          }),
        });
      } else {
        setExpenseDisabled(false);
      }
    }
  };

  const isZNRExpenseDisabled = () => {
    let isAvailableToUpdateAsCurrentApproval =
      expenseApplication.status === STATUS_TYPE.ON_APPROVAL &&
      isAvailableToUpdateExpenseByAssigneeAsCurrentApprover &&
      isAvailableToUpdateExpenseByCurrentApprover;
    let isAvailableToUpdateAsCoordinator =
      expenseApplication.status === STATUS_TYPE.ON_APPROVAL &&
      isAvailableToUpdateExpenseApplicationByCoordinator;

    if (expenseApplication) {
      if (
        isAvailableToUpdateAsCurrentApproval ||
        isAvailableToUpdateAsCoordinator
      ) {
        setExpenseDisabled(false);
      }
      if (
        !isBookerOccupation() &&
        !isAvailableToUpdateAsCurrentApproval &&
        !isAvailableToUpdateAsCoordinator &&
        (expenseApplication.status === STATUS_TYPE.APPROVED ||
          expenseApplication.status === STATUS_TYPE.ON_APPROVAL)
      ) {
        setExpenseDisabled(true);
        setExpenseDisabledError({
          code: "",
          message: t("status_error_message.status_expense_edit_EA", {
            status: t(
              `status.${
                statusNameMap[expenseApplication.status as STATUS_TYPE]
              }`
            ),
          }),
        });
      } else if (
        !isAvailableToUpdateAsCurrentApproval &&
        !isAvailableToUpdateAsCoordinator &&
        (expenseApplication.status === STATUS_TYPE.REPORT_PREPARED ||
          expenseApplication.status === STATUS_TYPE.EXECUTED)
      ) {
        setExpenseDisabled(true);
        setExpenseDisabledError({
          code: "",
          message: t("status_error_message.status__expense_edit_EA", {
            status: t(
              `status.${
                statusNameMap[expenseApplication.status as STATUS_TYPE]
              }`
            ),
          }),
        });
      } else {
        setExpenseDisabled(false);
      }
    }
  };

  const generatePermissions = () => {
    let permissionsList = [] as string[];

    if (props.editId) {
      permissionsList = editPermissions;
    } else {
      permissionsList = createPermissions;
    }

    let fieldsAccess = {
      type: permissionsList.includes(EXPENSE_PROPETIES.TYPE), //ExpenseTypeId
      description: permissionsList.includes(EXPENSE_PROPETIES.DESCRIPTION), //Description
      amountRub: permissionsList.includes(EXPENSE_PROPETIES.AMOUNT_RUB), //AmountRub
      paymentForm: permissionsList.includes(EXPENSE_PROPETIES.PAYMENT_FORM), //PaymentFormId
      accountableFormNumber: permissionsList.includes(
        EXPENSE_PROPETIES.ACCOUNTABLE_FORM_NUMBER
      ),
      attachments: permissionsList.includes(EXPENSE_PROPETIES.ATTACHMENTS),
      billDate: permissionsList.includes(EXPENSE_PROPETIES.BILL_DATE), //BillDate
      approvedSum: props.isAdvance
        ? true
        : permissionsList.includes(EXPENSE_PROPETIES.APPROVED_AMOUNT_RUB), //ApprovedAmountRub
      approvedAmountComment: permissionsList.includes(
        EXPENSE_PROPETIES.APPROVED_AMOUNT_COMMENT
      ), //ApprovedAmountComment
      advanceCashRequired: permissionsList.includes(
        EXPENSE_PROPETIES.ADVANCE_CASH_REQUIRED
      ), //AdvanceCashRequired
      attachFiles: true,
      numberBSO: permissionsList.includes(
        EXPENSE_PROPETIES.ACCOUNTABLE_FORM_NUMBER
      ),
      amountCurrencyId: permissionsList.includes(
        EXPENSE_PROPETIES.AMOUNT_CURRENCY_ID
      ),
      amount: permissionsList.includes(EXPENSE_PROPETIES.AMOUNT),
      amountCurrencyRate: permissionsList.includes(
        EXPENSE_PROPETIES.AMOUNT_CURRENCY_RATE
      ),
      billNumber: permissionsList.includes(EXPENSE_PROPETIES.BILL_NUMBER),
      billAmountRub: permissionsList.includes(
        EXPENSE_PROPETIES.BILL_AMOUNT_RUB
      ),
      approvedAmountCurrencyId: permissionsList.includes(
        EXPENSE_PROPETIES.APPROVED_AMOUNT_CURRENCY_ID
      ),
      approvedAmount: permissionsList.includes(
        EXPENSE_PROPETIES.APPROVED_AMOUNT
      ),
      approvedAmountCurrencyRate: permissionsList.includes(
        EXPENSE_PROPETIES.APPROVED_AMOUNT_CURRENCY_RATE
      ),
      approvedAmountRub: permissionsList.includes(
        EXPENSE_PROPETIES.APPROVED_AMOUNT_RUB
      ),
    };

    setFieldsAccess(fieldsAccess);
  };

  useEffect(() => {
    if (props.isOpen) {
      generatePermissions();
    }
  }, [props.isOpen, readPermissions, editPermissions, createPermissions]);

  const handlerReferToAct = (option: SelectOptionType) => {
    setAdditionalDocument(option);
  };

  useEffect(() => {
    if (props.isAdvance && consumptionTypes) {
      const result: any = consumptionTypes.find(
        (item) => item.id === consumptionType.value
      );
      if (result) {
        if (
          result.needIncludeToAdvanceReportAdditionalDocuments ||
          result.needIncludeToBusinessEventReportDocuments
        ) {
          setCanShowSelectReferToAct(true);
          if (result.needIncludeToAdvanceReportAdditionalDocuments) {
            setDocumentType(
              ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
            );
            if (
              advanceReport.additionalDocuments.length === 1 &&
              advanceReport.additionalDocuments[0].documentType ===
                ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
            ) {
              setAdditionalDocument(advanceReport.additionalDocuments[0]);
            } else {
              setAdditionalDocument({
                text: t("modals.create_order_modal.createNewOne"),
                value: 0,
              });
            }
          } else if (result.needIncludeToBusinessEventReportDocuments) {
            setDocumentType(
              ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
            );
            if (
              advanceReport.additionalDocuments.length === 1 &&
              advanceReport.additionalDocuments[0].documentType ===
                ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
            ) {
              setAdditionalDocument(advanceReport.additionalDocuments[0]);
            } else {
              setAdditionalDocument({
                text: t("modals.create_order_modal.createNewOne"),
                value: 0,
              });
            }
          }
          if (advanceReport.additionalDocuments.length !== 1) {
            setAdditionalDocument({
              text: t("modals.create_order_modal.createNewOne"),
              value: 0,
            });
          }
        } else {
          setCanShowSelectReferToAct(false);
          setAdditionalDocument({
            text: t("modals.create_order_modal.createNewOne"),
            value: 0,
          });
        }
      }
    }
  }, [consumptionType]);

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return props.isWidget ? (
    <>
      {" "}
      {/*Implemented for ZNR only*/}
      <Modal
        show={props.isOpen}
        onHide={() => {
          props.onClose(true);
        }}
        size="lg"
      >
        <Modal.Header closeButton={true} translate="no">
          <Modal.Title>
            {!editMode
              ? t("modals.create_order_modal.add_consumption")
              : t("modals.create_order_modal.edit_consumption")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.isOpen && (
            <>
              <Form>
                <div className={`input-wrap`} id="add-expenses">
                  <Validator
                    ref={consumptionValidator}
                    type={"select"}
                    isWidget={props.isWidget}
                  >
                    <Form.Group
                      className={`${generateClassName(EXPENSE_PROPETIES.TYPE)}`}
                    >
                      <Form.Label className="input-label">
                        {t("modals.create_order_modal.type_expense")}
                        <i className="input-required">*</i>
                      </Form.Label>
                      <Select
                        options={consumptionTypeOptions}
                        defaultValue={
                          editMode || props.dailyRenew ? consumptionType : null
                        }
                        defaultText={t(
                          "modals.create_order_modal.select_expense_type"
                        )}
                        isWidget={props.isWidget}
                        onChoose={consumptionTypeChoseHandler}
                        readOnly={generateClassName(
                          EXPENSE_PROPETIES.TYPE
                        ).includes("disabled")}
                      />
                    </Form.Group>
                  </Validator>
                  <If condition={!isDailyExpense}>
                    <Validator ref={descriptionValidatorRef} type={"textarea"}>
                      <Form.Group
                        className={`${generateClassName(
                          EXPENSE_PROPETIES.DESCRIPTION
                        )}`}
                      >
                        <Form.Label className="input-label">
                          {t("modals.create_order_modal.description")}
                          <i className="input-required">*</i>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          ref={descriptionRef}
                          maxLength={400}
                          onChange={changeDesctiption}
                          value={description}
                          readOnly={generateClassName(
                            EXPENSE_PROPETIES.DESCRIPTION
                          ).includes("disabled")}
                          tabIndex={isTabIndexDisabled ? -1 : 0}
                        />
                      </Form.Group>
                    </Validator>
                    <div
                      className={`input-item-row ${
                        !props.isAdvance ? "line-two" : ""
                      }`}
                    >
                      <Validator ref={sumRubleValidator} type={"text"}>
                        <Form.Group
                          className={`${generateClassName(
                            EXPENSE_PROPETIES.AMOUNT_RUB
                          )}`}
                        >
                          <Form.Label className="input-label">
                            {t("modals.create_order_modal.amount_expense", {
                              currency: getCurrencieCode("rub"),
                            })}
                            <i className="input-required">*</i>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            ref={sumRubleRef}
                            onInput={changeSumRuble}
                            value={sumRuble}
                            readOnly={generateClassName(
                              EXPENSE_PROPETIES.AMOUNT_RUB
                            ).includes("disabled")}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </Form.Group>
                      </Validator>
                      {!props.isAdvance ? (
                        <Validator
                          ref={paymentFormValidator}
                          type={"select"}
                          isWidget={props.isWidget}
                        >
                          <>
                            <Form.Group
                              className={`${generateClassName(
                                EXPENSE_PROPETIES.PAYMENT_FORM
                              )}`}
                            >
                              <Form.Label className="input-label">
                                {t("modals.create_order_modal.payment_form")}
                                <i className="input-required">*</i>
                              </Form.Label>
                              <Select
                                options={paymentFormOptions}
                                defaultValue={paymentForm}
                                defaultText={t(
                                  "modals.create_order_modal.enter_payment"
                                )}
                                isWidget={props.isWidget}
                                onChoose={paymentFormChoseHandler}
                                readOnly={generateClassName(
                                  EXPENSE_PROPETIES.PAYMENT_FORM
                                ).includes("disabled")}
                              />
                            </Form.Group>
                          </>
                        </Validator>
                      ) : null}
                    </div>
                    {!props.isAdvance && fieldsAccess.accountableFormNumber ? (
                      <Form.Group className="input-item">
                        <Form.Label className="input-label">
                          {t("modals.create_order_modal.numberBSO")}
                        </Form.Label>
                        <Form.Control
                          ref={numberBSORef}
                          type="text"
                          onChange={changeNumberBSO}
                          value={numberBSO}
                          readOnly={generateClassName(
                            EXPENSE_PROPETIES.PAYMENT_FORM
                          ).includes("disabled")}
                          tabIndex={isTabIndexDisabled ? -1 : 0}
                        />
                      </Form.Group>
                    ) : null}
                    {isAttachmentPermission() ? (
                      <FileAttach
                        isOpen={props.isOpen}
                        isWidget={props.isWidget}
                        isEdit={editMode}
                        editId={props.editId}
                        getAttachFiles={(attachFiles) => {
                          setAttachFiles(attachFiles);
                        }}
                        getDeletedIds={(DeleteIds) => setDeletedIds(DeleteIds)}
                      />
                    ) : null}
                    {!props.isAdvance && !advanceСalculationMethod ? (
                      <Checkbox
                        name={"req"}
                        id={3}
                        label={t("modals.create_order_modal.advance_required")}
                        checked={prepaidExpense}
                        onClick={() => {
                          if (!fieldsAccess.advanceCashRequired) {
                            prepaidExpenseClickHandler();
                          }
                        }}
                      />
                    ) : null}
                  </If>
                </div>

                <If condition={isDailyExpense}>
                  <Daily
                    isShow={isDailyExpense}
                    editMode={!!props.editId}
                    expenseId={props.editId ? props.editId : 0}
                    documentId={+props.id}
                    isAdvance={props.isAdvance}
                    isExternal={external}
                    onChangeResult={onChangeResult}
                    onChangeDailyStep={onChangeDailyStep}
                    permissions={
                      !!props.editId ? editPermissions : createPermissions
                    }
                    isWidget={props.isWidget}
                    calculationDetails={readPermissions}
                  />
                </If>

                <div className="box-modal-form">
                  <div
                    className="box-modal-form-block"
                    style={{ marginTop: "45px", marginBottom: "0px" }}
                  >
                    <CustomProperty
                      availableProperties={[]}
                      isOrder={true}
                      id={props.editId ? props.editId : undefined}
                      isEdit={!!props.editId}
                      isExpense={true}
                      ref={customPropertyRef}
                      permissions={
                        !!props.editId ? editPermissions : createPermissions
                      }
                      occupationsId={
                        props.isAdvance
                          ? advanceReport.assigneeEmployee.occupationId
                          : expenseApplication &&
                              expenseApplication.assigneeEmployee
                            ? expenseApplication.assigneeEmployee.occupationId
                            : 0
                      }
                      companyId={
                        props.isAdvance
                          ? advanceReport.company.id
                          : expenseApplication
                            ? expenseApplication.company.id
                            : undefined
                      }
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                      isWidget={props.isWidget}
                      type={"Expense"}
                      dataExpense={dataRef.current}
                    />
                  </div>
                </div>
              </Form>
              <Modal.Footer className="create-expense-bootstrap-footer">
                <Button
                  variant={"outline-dark"}
                  onClick={() => {
                    props.onClose(true);
                  }}
                >
                  {t("modals.create_order_modal.cancel")}
                </Button>
                {props.hasNext ? (
                  <Button
                    variant={"success"}
                    onClick={saveHandler}
                    tabIndex={isTabIndexDisabled ? -1 : 0}
                  >
                    {t("request_detail.request_button_controls.next")}
                  </Button>
                ) : (
                  <Button
                    variant={"success"}
                    onClick={saveHandler}
                    tabIndex={isTabIndexDisabled ? -1 : 0}
                  >
                    {t("modals.create_order_modal.complete")}
                  </Button>
                )}
              </Modal.Footer>
            </>
          )}{" "}
        </Modal.Body>
      </Modal>
      <ADActModal
        documentType={documentType}
        isOpen={isOpenActModal}
        documentId={props.id}
        createActDetail={createActDetail}
        onClose={handleActClose}
        onComplete={handleActComplete}
      />
    </>
  ) : (
    <>
      <ModalContainer
        onEnter={saveHandler}
        isOpen={props.isOpen}
        destroy={true}
        highestModal={isHighestModal}
        overlayClick={() => {
          dispatch(setCostCenterIsChanged(false));
          dispatch(setCostCenterData(null));
          props.onClose(true);
        }}
      >
        {props.isOpen && (
          <div
            className="box-modal"
            style={isDailyExpense ? { width: "900px" } : {}}
          >
            <div
              className="box-modal_close arcticmodal-close"
              onClick={() => {
                props.onClose(true);
              }}
            />
            <div className="box-modal-title">
              {!editMode
                ? t("modals.create_order_modal.add_consumption")
                : t("modals.create_order_modal.edit_consumption")}
            </div>
            <div className="box-modal-content">
              <div className={`input-wrap`} id="add-expenses">
                <Validator ref={consumptionValidator} type={"select"}>
                  <div
                    className={`${generateClassName(EXPENSE_PROPETIES.TYPE)}`}
                  >
                    <label className="input-label">
                      {t("modals.create_order_modal.type_expense")}
                      <i
                        className={
                          isReadOnly(EXPENSE_PROPETIES.TYPE)
                            ? "d-none"
                            : "input-required"
                        }
                      >
                        *
                      </i>
                    </label>
                    <Select
                      options={consumptionTypeOptions}
                      defaultValue={
                        editMode || props.dailyRenew ? consumptionType : null
                      }
                      defaultText={t(
                        "modals.create_order_modal.select_expense_type"
                      )}
                      onChoose={consumptionTypeChoseHandler}
                    />
                  </div>
                </Validator>
                <If condition={!isDailyExpense}>
                  <Validator ref={descriptionValidatorRef} type={"textarea"}>
                    <div
                      className={`${generateClassName(
                        EXPENSE_PROPETIES.DESCRIPTION
                      )}`}
                    >
                      <label className="input-label">
                        {t("modals.create_order_modal.description")}
                        <i
                          className={
                            isReadOnly(EXPENSE_PROPETIES.TYPE)
                              ? "d-none"
                              : "input-required"
                          }
                        >
                          *
                        </i>
                      </label>
                      <textarea
                        ref={descriptionRef}
                        maxLength={400}
                        onChange={changeDesctiption}
                        value={description}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                    </div>
                  </Validator>
                  <div
                    className={`input-item-row ${
                      !props.isAdvance ? "line-two" : ""
                    }`}
                  >
                    {props.isAdvance ? (
                      <>
                        <Validator ref={currenciesValidator} type={"select"}>
                          <div
                            className={`${generateClassName(
                              EXPENSE_PROPETIES.AMOUNT_CURRENCY_ID
                            )}`}
                          >
                            <label className="input-label">
                              {t("modals.create_order_modal.currency")}
                            </label>
                            <Select
                              options={currenciesOptions}
                              defaultValue={currencyType}
                              defaultText={"Выб"}
                              onChoose={currencyTypeChoseHandler}
                            />
                          </div>
                        </Validator>
                        <Validator ref={sumDollarValidator} type={"text"}>
                          <div
                            className={`${generateClassName(
                              EXPENSE_PROPETIES.AMOUNT
                            )}`}
                          >
                            <label className="input-label">
                              {t(
                                "modals.create_order_modal.amount_expense_dollar",
                                {
                                  currency: get(
                                    currencyType,
                                    "text",
                                    getCurrencieCode("rub")
                                  ),
                                }
                              )}
                              <i
                                className={
                                  isReadOnly(EXPENSE_PROPETIES.TYPE)
                                    ? "d-none"
                                    : "input-required"
                                }
                              >
                                *
                              </i>
                            </label>
                            <input
                              className="input"
                              type="text"
                              defaultValue="100"
                              ref={sumDollarRef}
                              onInput={changeSumDollar}
                              value={sumDollar}
                              tabIndex={isTabIndexDisabled ? -1 : 0}
                            />
                          </div>
                        </Validator>
                        <Validator ref={courseDollarValidator} type={"text"}>
                          <div
                            className={`${generateClassName(
                              EXPENSE_PROPETIES.AMOUNT_CURRENCY_RATE
                            )}`}
                          >
                            <label className="input-label">
                              {t("modals.create_order_modal.course", {
                                currency: get(
                                  currencyType,
                                  "text",
                                  getCurrencieCode("rub")
                                ),
                              })}
                            </label>
                            <input
                              className="input"
                              type="text"
                              ref={courseDollarRef}
                              onInput={changeCourseDollar}
                              value={courseDollar}
                              tabIndex={isTabIndexDisabled ? -1 : 0}
                            />
                            <div className="descr">
                              {t(
                                "modals.create_order_modal.at_time_application_creation"
                              )}
                            </div>
                          </div>
                        </Validator>
                      </>
                    ) : null}
                    <Validator ref={sumRubleValidator} type={"text"}>
                      <div
                        className={`${generateClassName(
                          EXPENSE_PROPETIES.AMOUNT_RUB
                        )}`}
                      >
                        <label className="input-label">
                          {t("modals.create_order_modal.amount_expense", {
                            currency: getCurrencieCode("rub"),
                          })}
                          <i
                            className={
                              isReadOnly(EXPENSE_PROPETIES.TYPE)
                                ? "d-none"
                                : "input-required"
                            }
                          >
                            *
                          </i>
                        </label>
                        <input
                          className="input"
                          type="text"
                          ref={sumRubleRef}
                          onInput={changeSumRuble}
                          value={sumRuble}
                          tabIndex={isTabIndexDisabled ? -1 : 0}
                        />
                      </div>
                    </Validator>
                    {!props.isAdvance ? (
                      <Validator ref={paymentFormValidator} type={"select"}>
                        <div
                          className={`${generateClassName(
                            EXPENSE_PROPETIES.PAYMENT_FORM
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.payment_form")}
                            <i
                              className={
                                isReadOnly(EXPENSE_PROPETIES.PAYMENT_FORM)
                                  ? "d-none"
                                  : "input-required"
                              }
                            >
                              *
                            </i>
                          </label>
                          <Select
                            options={paymentFormOptions}
                            defaultValue={paymentForm}
                            defaultText={t(
                              "modals.create_order_modal.enter_payment"
                            )}
                            onChoose={paymentFormChoseHandler}
                          />
                        </div>
                      </Validator>
                    ) : null}
                  </div>
                  {props.isAdvance ? (
                    <div className={"input-item-row line-tree"}>
                      <Validator ref={paymentFormValidator} type={"select"}>
                        <div
                          className={`${generateClassName(
                            EXPENSE_PROPETIES.PAYMENT_FORM
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.payment_form")}
                            <i
                              className={
                                isReadOnly(EXPENSE_PROPETIES.PAYMENT_FORM)
                                  ? "d-none"
                                  : "input-required"
                              }
                            >
                              *
                            </i>
                          </label>
                          <Select
                            options={paymentFormOptions}
                            defaultValue={paymentForm}
                            defaultText={t(
                              "modals.create_order_modal.enter_payment"
                            )}
                            onChoose={paymentFormChoseHandler}
                          />
                        </div>
                      </Validator>
                    </div>
                  ) : null}
                  {props.isAdvance && !editMode ? (
                    <>
                      <div className="input-item-row line-two">
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.BILL_NUMBER
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.check")}
                          </label>
                          <input
                            ref={numberCheckRef}
                            className="input"
                            type="text"
                            onChange={changeNumberCheck}
                            value={numberCheck}
                          />
                        </div>
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.BILL_DATE
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.bill_date")}
                          </label>
                          <div className={`input-date`}>
                            <input
                              value={
                                billDate
                                  ? formatter(
                                      "D MMMM YYYY",
                                      i18n.language,
                                      billDate
                                    )
                                  : ""
                              }
                              onClick={toggleCalendar}
                              className="input datepicker pointer"
                              type="text"
                              id="datepicker"
                              placeholder={t(
                                "modals.create_order_modal.choose"
                              )}
                              readOnly={true}
                              tabIndex={isTabIndexDisabled ? -1 : 0}
                            />
                            <SvgIcon
                              className={"icon icon-calendar"}
                              href={"#svg_icon_calendar"}
                              onClick={toggleCalendar}
                            />
                          </div>
                          <DatePickerCalendar
                            startDate={billDate}
                            isShown={isOpenCalendar}
                            onClose={toggleCalendar}
                            isCleansed={false}
                            onSelected={onSelectBillDate}
                            isMultiChoice={false}
                          />
                        </div>
                      </div>
                      {fieldsAccess.accountableFormNumber && (
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.ACCOUNTABLE_FORM_NUMBER
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.numberBSO")}
                          </label>
                          <input
                            ref={numberBSORef}
                            className="input"
                            type="text"
                            onChange={changeNumberBSO}
                            value={numberBSO}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                  {!props.isAdvance && fieldsAccess.accountableFormNumber ? (
                    <div
                      className={`input-item ${generateClassName(
                        EXPENSE_PROPETIES.ACCOUNTABLE_FORM_NUMBER
                      )}`}
                    >
                      <label className="input-label">
                        {t("modals.create_order_modal.numberBSO")}
                      </label>
                      <input
                        ref={numberBSORef}
                        className="input"
                        type="text"
                        onChange={changeNumberBSO}
                        value={numberBSO}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                    </div>
                  ) : null}
                  {props.isAdvance && editMode ? (
                    <>
                      <div className="input-item-row line-two">
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.BILL_NUMBER
                          )}`}
                          style={{ width: isIE ? "37%" : "50%" }}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.check")}
                          </label>
                          <input
                            ref={numberCheckRef}
                            className="input"
                            type="text"
                            onChange={changeNumberCheck}
                            value={numberCheck}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.BILL_DATE
                          )}`}
                          style={{ width: isIE ? "35%" : "50%" }}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.bill_date")}
                          </label>
                          <div className={`input-date`}>
                            <input
                              value={
                                billDate
                                  ? formatter(
                                      "D MMMM YYYY",
                                      i18n.language,
                                      billDate
                                    )
                                  : ""
                              }
                              onClick={toggleCalendar}
                              className="input datepicker pointer"
                              type="text"
                              id="datepicke1"
                              placeholder={t(
                                "modals.create_order_modal.choose"
                              )}
                              readOnly={true}
                              tabIndex={isTabIndexDisabled ? -1 : 0}
                            />
                            <SvgIcon
                              className={"icon icon-calendar"}
                              href={"#svg_icon_calendar"}
                              onClick={toggleCalendar}
                            />
                          </div>
                          <DatePickerCalendar
                            startDate={billDate}
                            isShown={isOpenCalendar}
                            onClose={toggleCalendar}
                            isCleansed={false}
                            onSelected={onSelectBillDate}
                            isMultiChoice={false}
                          />
                        </div>
                      </div>
                      <div className="input-item-row approved-amount-block">
                        <>
                          <Validator ref={currenciesValidator} type={"select"}>
                            <>
                              <div
                                style={{
                                  display: readPermissions.includes(
                                    EXPENSE_PROPETIES.APPROVED_AMOUNT_CURRENCY_ID
                                  )
                                    ? undefined
                                    : "none",
                                }}
                              >
                                <label className="input-label">
                                  {t("modals.create_order_modal.currency")}
                                </label>
                                <Select
                                  options={approvedCurrenciesOptions}
                                  defaultValue={approvedCurrencyType}
                                  defaultText={"Выб"}
                                  disabled={
                                    !fieldsAccess.approvedAmountCurrencyId
                                  }
                                  // onChoose={currencyTypeChoseHandler} readOnly={fieldsAccess.approvedСurrencies} />
                                  onChoose={approvedCurrencyTypeChoseHandler}
                                />
                              </div>
                            </>
                          </Validator>
                          <Validator ref={sumDollarValidator} type={"text"}>
                            <>
                              <div
                                style={{
                                  display: readPermissions.includes(
                                    EXPENSE_PROPETIES.APPROVED_AMOUNT
                                  )
                                    ? undefined
                                    : "none",
                                }}
                              >
                                <label className="input-label">
                                  {t(
                                    "modals.create_order_modal.approved_amount_dollar",
                                    {
                                      currency: get(
                                        approvedCurrencyType,
                                        "text",
                                        getCurrencieCode("rub")
                                      ),
                                    }
                                  )}
                                  <i className="input-required">*</i>
                                </label>
                                <input
                                  className="input"
                                  type="text"
                                  disabled={!fieldsAccess.approvedAmount}
                                  defaultValue="100"
                                  ref={approvedAmountRef}
                                  onInput={changeApprovedAmount}
                                  value={approvedAmount}
                                  tabIndex={isTabIndexDisabled ? -1 : 0}
                                />
                              </div>
                            </>
                          </Validator>
                          <Validator ref={courseDollarValidator} type={"text"}>
                            <>
                              <div
                                style={{
                                  display: readPermissions.includes(
                                    EXPENSE_PROPETIES.APPROVED_AMOUNT_CURRENCY_RATE
                                  )
                                    ? undefined
                                    : "none",
                                }}
                              >
                                <label className="input-label">
                                  {t("modals.create_order_modal.course", {
                                    currency: get(
                                      approvedCurrencyType,
                                      "text",
                                      getCurrencieCode("rub")
                                    ),
                                  })}
                                </label>
                                <input
                                  className="input"
                                  type="text"
                                  disabled={
                                    !fieldsAccess.approvedAmountCurrencyRate
                                  }
                                  ref={approvedCurrencyRateRef}
                                  onInput={changeApprovedCourseDollar}
                                  value={approvedCurrencyRate}
                                  tabIndex={isTabIndexDisabled ? -1 : 0}
                                />
                                <div className="descr">
                                  {t(
                                    "modals.create_order_modal.at_time_application_creation"
                                  )}
                                </div>
                              </div>
                            </>
                          </Validator>
                        </>
                        <div
                          className="input-item w-180 m-l-auto m-l-auto"
                          style={{
                            display: readPermissions.includes(
                              EXPENSE_PROPETIES.APPROVED_AMOUNT_RUB
                            )
                              ? undefined
                              : "none",
                          }}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.approved_amount", {
                              currency: getCurrencieCode("rub"),
                            })}
                          </label>
                          <input
                            ref={approvedSumRef}
                            className="input"
                            disabled={!fieldsAccess.approvedAmountRub}
                            type="text"
                            onChange={changeApprovedSum}
                            value={approvedSum}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      </div>
                      <div className="input-wrap">
                        <div
                          className={`input-item`}
                          style={{
                            display: readPermissions.includes(
                              EXPENSE_PROPETIES.APPROVED_AMOUNT_COMMENT
                            )
                              ? undefined
                              : "none",
                          }}
                        >
                          <Validator
                            ref={commentValidatorRef}
                            type={"textarea"}
                          >
                            <>
                              <label className="input-label">
                                {t("modals.create_order_modal.comment")}
                                <If
                                  condition={
                                    dependApprovedAmountCommentFromApprovedAmount &&
                                    isChangeApprovedSum &&
                                    isBookerOccupation() &&
                                    advanceReport.status ===
                                      STATUS_TYPE_REPORT.ON_APPROVAL &&
                                    hasState("Approve") &&
                                    advanceReport.assigneeEmployee.id !==
                                      user.id
                                  }
                                >
                                  <i className="input-required">*</i>
                                </If>
                              </label>
                              <textarea
                                ref={commentRef}
								disabled={!fieldsAccess.approvedAmountComment}
                                onChange={changeComment}
                                value={approvedAmountComment}
                                tabIndex={isTabIndexDisabled ? -1 : 0}
                              />
                            </>
                          </Validator>
                        </div>
                      </div>
                      {fieldsAccess.accountableFormNumber && (
                        <div
                          className={`input-item ${generateClassName(
                            EXPENSE_PROPETIES.ACCOUNTABLE_FORM_NUMBER
                          )}`}
                        >
                          <label className="input-label">
                            {t("modals.create_order_modal.numberBSO")}
                          </label>
                          <input
                            ref={numberBSORef}
                            className="input"
                            type="text"
                            onChange={changeNumberBSO}
                            value={numberBSO}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      )}
                    </>
                  ) : null}
                  {fieldsAccess.attachments ? (
                    <FileAttach
                      isOpen={props.isOpen}
                      isEdit={editMode}
                      editId={props.editId}
                      getAttachFiles={(attachFiles) => {
                        setAttachFiles(attachFiles);
                      }}
                      getDeletedIds={(DeleteIds) => setDeletedIds(DeleteIds)}
                    />
                  ) : null}
                  {!props.isAdvance && !advanceСalculationMethod ? (
                    <Checkbox
                      name={"req"}
                      id={3}
                      label={t("modals.create_order_modal.advance_required")}
                      checked={prepaidExpense}
                      onClick={() => {
                        if (fieldsAccess.advanceCashRequired) {
                          prepaidExpenseClickHandler();
                        }
                      }}
                    />
                  ) : null}
                </If>

                <If condition={abilityUseOrNotExpense && props.isAdvance}>
                  <Checkbox
                    name={"used"}
                    id={4}
                    label={t("modals.create_order_modal.expenseUsed")}
                    checked={expenseUsed}
                    onClick={onChangeExpenseUsed}
                  />
                </If>
              </div>
              <If condition={isDailyExpense}>
                <Daily
                  isShow={isDailyExpense}
                  editMode={!!props.editId}
                  expenseId={props.editId ? props.editId : 0}
                  documentId={+props.id}
                  isAdvance={props.isAdvance}
                  isExternal={external}
                  onChangeResult={onChangeResult}
                  onChangeDailyStep={onChangeDailyStep}
                  permissions={
                    !!props.editId ? editPermissions : createPermissions
                  }
                  calculationDetails={readPermissions}
                />
              </If>

              <div className="box-modal-form">
                <div
                  className="box-modal-form-block"
                  style={{ marginTop: "45px", marginBottom: "0px" }}
                >
                  <CustomProperty
                    availableProperties={[]}
                    isOrder={true}
                    type={"Expense"}
                    dataExpense={dataRef.current}
                    id={props.editId ? props.editId : undefined}
                    isEdit={!!props.editId}
                    isExpense={true}
                    ref={customPropertyRef}
                    permissions={
                      !!props.editId ? editPermissions : createPermissions
                    }
                    occupationsId={
                      props.isAdvance
                        ? advanceReport.assigneeEmployee.occupationId
                        : expenseApplication &&
                            expenseApplication.assigneeEmployee
                          ? expenseApplication.assigneeEmployee.occupationId
                          : 0
                    }
                    companyId={
                      props.isAdvance
                        ? advanceReport.company.id
                        : expenseApplication
                          ? expenseApplication.company.id
                          : undefined
                    }
                    tabIndex={isTabIndexDisabled ? -1 : 0}
                  />
                </div>
              </div>
            </div>
            <div className="box-modal-content">
              <div className="input-wrap">
                <div className={`${generateClassName(EXPENSE_PROPETIES.TYPE)}`}>
                  {canShowSelectReferToAct &&
                  props.isAdvance &&
                  (advanceReport.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
                  ) ||
                    advanceReport.properties.includes(
                      ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
                    )) ? (
                    <>
                      <p
                        className=""
                        style={{
                          marginBottom: 10,
                          color: "#AEB5B9",
                          fontSize: 14,
                        }}
                      >
                        {t(
                          `modals.create_order_modal.${documentType.toLowerCase()}_warning`
                        )}
                      </p>
                      <label className="input-label">
                        {t(
                          `modals.create_order_modal.${documentType.toLowerCase()}_referToAct`
                        )}
                      </label>
                      <SelectWithSection
                        options={[
                          {
                            text: t("modals.create_order_modal.createNewOne"),
                            value: 0,
                            section: "",
                          },
                          ...advanceReport.additionalDocuments.filter(
                            (el) => el.documentType === documentType
                          ),
                        ]}
                        defaultValue={additionalDocument}
                        defaultText={t(
                          "modals.create_order_modal.createNewOne"
                        )}
                        onChoose={handlerReferToAct}
                        readOnly={generateClassName(
                          EXPENSE_PROPETIES.TYPE
                        ).includes("disabled")}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="box-modal-footer">
              <div
                className="arcticmodal-close btn btn_black pointer"
                onClick={() => {
                  dispatch(setCostCenterIsChanged(false));
                  dispatch(setCostCenterData(null));
                  props.onClose(true);
                }}
              >
                {t("modals.create_order_modal.cancel")}
              </div>
              {props.hasNext ? (
                <a
                  className="btn-expense btn_green pointer"
                  onClick={saveHandler}
                  tabIndex={isTabIndexDisabled ? -1 : 0}
                >
                  {t("request_detail.request_button_controls.next")}
                </a>
              ) : (
                <a
                  className="btn-expense btn_green pointer"
                  onClick={saveHandler}
                  tabIndex={isTabIndexDisabled ? -1 : 0}
                >
                  {t("modals.create_order_modal.complete")}
                </a>
              )}
            </div>
          </div>
        )}
      </ModalContainer>
      <ADActModal
        documentType={documentType}
        isOpen={isOpenActModal}
        documentId={props.id}
        createActDetail={createActDetail}
        onClose={handleActClose}
        onComplete={handleActComplete}
      />
    </>
  );
};

export default CreateOrderModal;
