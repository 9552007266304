import SvgIcon from "app/component/svg-icon/SvgIcon";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { NameFormats, nameFormatter } from "app/utils";
import { showErrors } from "store/exception/actions";
import { get } from "lodash";
import ModalContainer from "app/component/modal/ModalContainer";
import {
  getAssistancesById,
  getEmployeeByParams,
} from "../../../services/ApiService";
import AssistanceModalRow from "./AssistanceModalRow";
import styles from "./Assistance.module.css";

interface AssistanceModalProps {
  isOpen: boolean;
  assistant: {
    assistantId: number;
    assistantName: {
      ru: string;
      en: string;
    };
    assistantCompany: {
      id: number;
      ru: string;
      en: string;
    };
  };
  onClose: () => void;
  onSave: (assistantIds: number[], employeeIds: number[]) => void;
}

const AssistanceModal: React.FC<AssistanceModalProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let [inputValue, setInputValue] = useState("");
  let [employeeTimer, setEmployeeTimer] = useState({} as any);

  let [searchUsers, setSearchUsers] = useState(
    [] as {
      id: number;
      name: { ru: string; en: string };
      company: { id: number; ru: string; en: string };
    }[]
  );
  let [assistantUsers, setAssistantUsers] = useState(
    [] as {
      id: number;
      name: { ru: string; en: string };
      company: { id: number; ru: string; en: string };
    }[]
  );
  let [assistanceUsers, setAssistanceUsers] = useState(
    [] as {
      assistanceId: number;
      employeeId: number;
      name: { ru: string; en: string };
      company: { id: number; ru: string; en: string };
    }[]
  );
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  let inputRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    if (props.isOpen) {
      let newAssistantUsers = [];
      if (props.assistant.assistantId != 0) {
        newAssistantUsers.push({
          id: props.assistant.assistantId,
          name: props.assistant.assistantName,
          company: {
            id: props.assistant.assistantCompany.id,
            ru: props.assistant.assistantCompany.ru,
            en: props.assistant.assistantCompany.en,
          },
        });
        getAllAssistances();
      }
      setAssistantUsers(newAssistantUsers);
      setSearchUsers([]);
      setAssistanceUsers([]);
      setInputValue("");
    }
  }, [props.isOpen]);

  const changeInput = () => {
    setSearchUsers([]);
    setInputValue(inputRef.current.value);
    clearTimeout(employeeTimer);
    if (inputRef.current.value.length > 2) {
      let name = inputRef.current.value;
      (() => {
        setEmployeeTimer(
          setTimeout(() => {
            getEmployee(name);
          }, 800)
        );
      })();
    }
  };

  const getEmployee = async (nameValue: string) => {
    dispatch(loaderLock());

    let result = await getEmployeeByParams({ params: { Name: nameValue } });
    if (result.config.params.Name == inputRef.current.value) {
      let newSearchUser = [];
      if (result.headers.success == true) {
        newSearchUser = result.data.data.map((item: any) => {
          return item.occupations.map((occupation: any) => {
            return {
              id: occupation.id,
              name: {
                ru: nameFormatter(item.name, NameFormats.FULL, "ru"),
                en: nameFormatter(item.name, NameFormats.FULL, "en"),
              },
              occupation: {
                id: occupation.id,
                name: {
                  ru: occupation.name.ru,
                  en: occupation.name.ru,
                },
              },
              company: {
                id: occupation.company.id,
                ru: `${occupation.company.name.ru} - ${occupation.name.ru}`,
                en: `${occupation.company.name.en} - ${occupation.name.ru}`,
              },
            };
          });
        });
      }

      newSearchUser = newSearchUser.flatMap((item: any) => item);

      let assistantUsersIds = assistantUsers.map((item) => item.id);
      let assistanceUsersIds = assistanceUsers.map((item) => item.employeeId);

      newSearchUser = newSearchUser.filter((item: any) => {
        return (
          !assistantUsersIds.includes(item.id) &&
          !assistanceUsersIds.includes(item.id)
        );
      });
      setSearchUsers(newSearchUser);
    }
    dispatch(loaderUnlock());
  };

  const getAllAssistances = async () => {
    dispatch(loaderLock());
    let response = await getAssistancesById(props.assistant.assistantId);
    if (response.headers.success) {
      let newAssistanceList = response.data.value.assistances.map(
        (item: any) => {
          return {
            assistanceId: get(item, "assistanceId", 0),
            employeeId: get(item, "employeeOccupationId", 0),
            //employeeId: get(item, 'employeeId', 0),
            name: {
              ru: get(item, "employeeName.ru", ""),
              en: get(item, "employeeName.en", ""),
            },
            occupation: {
              id: get(item, "employeeOccupationId", 0),
              name: {
                ru: get(item, "employeeOccupationName.ru", ""),
                en: get(item, "employeeOccupationName.ru", ""),
              },
            },
            company: {
              id: get(item, "company.id", 0),
              ru: `${get(item, "company.name.ru", "")} - ${get(item, "employeeOccupationName.ru", "")}`,
              en: `${get(item, "company.name.en", "")} - ${get(item, "employeeOccupationName.ru", "")}`,
            },
          };
        }
      );
      setAssistanceUsers(newAssistanceList);
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось загрузить сотрудников",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const addHandler = (
    assistantId: number,
    type: "assistant" | "assistance"
  ) => {
    if (type == "assistant") {
      let newUser = searchUsers.find((item) => item.id == assistantId);
      if (newUser) {
        let newAssistantUsers = [...assistantUsers];
        newAssistantUsers.push({
          id: newUser.id,
          name: newUser.name,
          company: newUser.company,
        });
        setAssistantUsers(newAssistantUsers);
      }
    }

    if (type == "assistance") {
      let newUser = searchUsers.find((item) => item.id == assistantId);
      if (newUser) {
        let newAssistanceUsers = [...assistanceUsers];
        newAssistanceUsers.push({
          assistanceId: 0,
          employeeId: newUser.id,
          name: newUser.name,
          company: newUser.company,
        });
        setAssistanceUsers(newAssistanceUsers);
      }
    }

    let newSearchUsers = searchUsers.filter((item) => item.id != assistantId);
    setSearchUsers(newSearchUsers);
  };

  const deleteAssistantHandler = (assistantId: number) => {
    setAssistantUsers(assistantUsers.filter((item) => item.id != assistantId));
  };

  const deleteAssistanceHandler = (assistantId: number) => {
    setAssistanceUsers(
      assistanceUsers.filter((item) => item.employeeId != assistantId)
    );
  };

  const onSaveHandler = () => {
    props.onSave(
      assistantUsers.map((item) => item.id),
      assistanceUsers.map((item) => item.employeeId)
    );
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <ModalContainer
      isOpen={props.isOpen}
      highestModal={isHighestModal}
      overlayClick={props.onClose}
      destroy={true}
    >
      <div className="box-modal" id="add-reassignment">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={props.onClose}
        ></div>
        <div className="box-modal-title">
          {t("assistance_list.modal.header")}
        </div>
        <div className="box-modal-content">
          <div className="box-modal-form">
            <div className="box-modal-form-block">
              <div className="input-item-row">
                <div className={"input-item"}>
                  <label className="input-label">
                    {t("assistance_list.modal.search")}
                  </label>
                  <div className="input-search">
                    <input
                      className="input"
                      type="text"
                      ref={inputRef}
                      value={inputValue}
                      onChange={changeInput}
                      placeholder={t(
                        "assistance_list.modal.search_placeholder"
                      )}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                    <SvgIcon
                      href={"#svg_icon_search"}
                      className={"icon icon-search"}
                    />
                  </div>
                </div>
              </div>
              <div className="box-modal-form-block">
                <div className={styles.assistanceWrapper}>
                  {searchUsers.map((item) => {
                    return (
                      <AssistanceModalRow
                        key={item.id}
                        type="search"
                        assistantId={item.id}
                        name={item.name[i18n.language as "ru"]}
                        company={item.company[i18n.language as "ru"]}
                        companyId={item.company.id}
                        onAdd={addHandler}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="box-modal-form-block">
              <div className="title">
                {t("assistance_list.modal.assistant_header")}
              </div>
              <div className="box-modal-form-block">
                <div className={styles.assistanceWrapper}>
                  {assistantUsers.length > 0 ? (
                    assistantUsers.map((item) => {
                      return (
                        <AssistanceModalRow
                          key={item.id}
                          type="assistant"
                          assistantId={item.id}
                          name={item.name[i18n.language as "ru"]}
                          company={item.company[i18n.language as "ru"]}
                          companyId={item.company.id}
                          onDelete={deleteAssistantHandler}
                        />
                      );
                    })
                  ) : (
                    <AssistanceModalRow
                      type="message"
                      assistantId={0}
                      name={t("assistance_list.modal.add_assistant_message")}
                      company={""}
                      companyId={0}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="box-modal-form-block">
              <div className="title">
                {t("assistance_list.modal.employee_header")}
              </div>
              <div className="box-modal-form-block">
                <div className={styles.assistanceWrapper}>
                  {assistanceUsers.length > 0 ? (
                    assistanceUsers.map((item) => {
                      return (
                        <AssistanceModalRow
                          key={item.employeeId}
                          type="assistance"
                          assistantId={item.employeeId}
                          name={item.name[i18n.language as "ru"]}
                          company={item.company[i18n.language as "ru"]}
                          companyId={item.company.id}
                          onDelete={deleteAssistanceHandler}
                        />
                      );
                    })
                  ) : (
                    <AssistanceModalRow
                      type="message"
                      assistantId={0}
                      name={t("assistance_list.modal.add_employee_message")}
                      company={""}
                      companyId={0}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-modal-footer">
          <a
            className="arcticmodal-close btn btn_black"
            onClick={props.onClose}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("assistance_list.modal.cancel")}
          </a>
          <a
            className="btn-expense btn_green"
            onClick={onSaveHandler}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("assistance_list.modal.save")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AssistanceModal;
