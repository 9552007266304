import SvgIcon from "app/component/svg-icon/SvgIcon";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import Config, { ConfigTypes } from "services/ConfigService";
import { showErrors } from "store/exception/actions";
import If from "app/component/util/If";
import { get } from "lodash";
import {
  getAssistancesById,
} from "../../../services/ApiService";

interface AssistanceRowProps {
  assistantId: number;
  assistantName: {
	ru: string;
	en: string;
  };
  assistantCompany: {
	id: number;
	ru: string;
	en: string;
  };
  assistantOccupation: {
	id: number;
	name: {
	  ru: string;
	  en: string;
	};
  };
  assistantCompanyId: number;
  numberOfAssistances: number;
  onEdit: (editUser: {
	assistantId: number;
	assistantName: { ru: string; en: string };
	assistantCompany: { id: number; ru: string; en: string };
  }) => void;
  onDelete: (assistantId: number) => void;
  updateTime: number;
}

const AssistanceRow: React.FC<AssistanceRowProps> = (props) => {
  const { t, i18n } = useTranslation();

  const assistanceSource = Config.getConfigToCompany(
	ConfigTypes.ASSISTANCE_SOURCE,
	props.assistantCompanyId
  );

  const dispatch = useDispatch();

  let [isOpen, setOpen] = useState(false);
  let [assistanceList, setAssistanceList] = useState(
	[] as {
	  assistanceId: number;
	  employeeId: number;
	  employeeName: { ru: string; en: string };
	}[]
  );

  useEffect(() => {
	if (isOpen) {
	  getAllAssistances();
	}
  }, [props.updateTime]);

  const toggleRow = async () => {
	if (!isOpen) {
	  await getAllAssistances();
	}
	setOpen(!isOpen);
  };

  const getAllAssistances = async () => {
	dispatch(loaderLock());
	let response = await getAssistancesById(props.assistantId);
	if (response.headers.success) {
	  let newAssistanceList = response.data.value.assistances.map(
		(item: any) => {
		  return {
			assistanceId: get(item, "assistanceId", 0),
			employeeId: get(item, "employeeId", 0),
			employeeName: {
			  ru: `${get(item, "employeeName.ru", "")} - ${get(item, "employeeOccupationName.ru", "")}`,
			  en: `${get(item, "employeeName.ru", "")} - ${get(item, "employeeOccupationName.ru", "")}`,
			},
		  };
		}
	  );
	  setAssistanceList(newAssistanceList);
	} else {
	  dispatch(
		showErrors({
		  code: "del_substituation",
		  message: "Не удалось загрузить сотрудников",
		})
	  );
	}
	dispatch(loaderUnlock());
  };

  const handleEdit = async (
	event: any,
	user: {
	  assistantId: number;
	  assistantName: { ru: string; en: string };
	  assistantCompany: { id: number; ru: string; en: string };
	}
  ) => {
	props.onEdit(user);
	event.stopPropagation();
  };

  const handleDelete = async (event: any, assistantId: number) => {
	props.onDelete(assistantId);
	event.stopPropagation();
  };

  return (
	<Fragment>
	  <div className="profile-calc-table-row" onClick={toggleRow}>
		<div
		  className="profile-calc-table-td"
		  style={{
			maxWidth: "none",
			width: "calc(50% - 75px)",
			background: isOpen ? "rgba(233, 236, 242, 0.3)" : "#FFF",
		  }}
		>
		  {props.assistantName[i18n.language as "ru" | "en"]} -{" "}
		  {props.assistantOccupation.name[i18n.language as "ru" | "en"]}
		</div>
		<div
		  className="profile-calc-table-td"
		  style={{
			maxWidth: "none",
			width: "calc(50% + 75px)",
			background: isOpen ? "rgba(233, 236, 242, 0.3)" : "#FFF",
			justifyContent: "space-between",
		  }}
		>
		  <div style={{ float: "left" }}>{props.numberOfAssistances}</div>
		  <If condition={assistanceSource == 1}>
			<div style={{ float: "right", display: "flex" }}>
			  <div
				className="btn btn_gray add-trip"
				onClick={(event) => {
				  handleEdit(event, {
					assistantId: props.assistantId,
					assistantName: props.assistantName,
					assistantCompany: props.assistantCompany,
				  });
				}}
				style={{ marginRight: "10px" }}
			  >
				{t("assistance_list.table.edit")}
			  </div>
			  <div
				onClick={(event) => {
				  handleDelete(event, props.assistantId);
				}}
			  >
				<SvgIcon
				  className="icon icon-remove"
				  href="#svg_icon_remove"
				  type={"delete"}
				/>
			  </div>
			</div>
		  </If>
		</div>
	  </div>

	  {assistanceList.map((item) => {
		return (
		  <div
			className="profile-calc-table-row"
			style={{ display: isOpen ? "flex" : "none" }}
		  >
			<div
			  className="profile-calc-table-td"
			  style={{
				maxWidth: "none",
				width: "calc(50% - 75px)",
				background: "rgba(233, 236, 242, 0.3)",
			  }}
			></div>
			<div
			  className="profile-calc-table-td"
			  style={{
				maxWidth: "none",
				width: "calc(50% + 75px)",
				background: "rgba(233, 236, 242, 0.3)",
				justifyContent: "space-between",
			  }}
			>
			  <div style={{ float: "left" }}>
				{item.employeeName[i18n.language as "ru" | "en"]}
			  </div>
			</div>
		  </div>
		);
	  })}
	</Fragment>
  );
};

export default AssistanceRow;

