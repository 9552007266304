import { Reducer } from "redux";
import {
  ExpenseType,
  BusinessGoal,
  BusinessTarget,
  ConsumptionType,
  PaymentForm,
  FilterParams,
  UserDetail,
  CurrencyList,
  Posting,
  AttachmentPermission,
  ManualExpenseCurrencyPermission,
  Clearing,
  ExpenseTypeItem,
  ICompany,
} from "infrastructure/interfaces";
import { CommonActions, COMMON } from "./actionTypes";
import { uniqBy } from "lodash";

export interface CommonState {
  expenseTypes: ExpenseType[];
  consumptionTypes: ConsumptionType[];
  paymentForms: PaymentForm[];
  businessGoals: BusinessGoal[];
  businessTargetsCatalog: BusinessTarget[];
  expenseTypesCatalog: ExpenseTypeItem[];
  companiesCatalog: ICompany[];
  loaderState: boolean;
  loaderCount: number;
  stateOptions: string[];
  filterParams: FilterParams;
  userDetail: UserDetail;
  attachmentPermission: AttachmentPermission;
  manualExpenseCurrencyPermission: ManualExpenseCurrencyPermission;
  filterState: boolean;
  currencies: CurrencyList;
  postings: Posting;
  isArchivedPostings: boolean;
  transactionBatchId: number;
  attachmentList: any[];
  onboardState: boolean;
  isAttachmentsToggle: boolean;
  attachmentsExpense: any[];
  historyNeedUpdating: boolean;
  openModal: number[];
  subcontoIsChanged: boolean;
  subcontoData: any;
  costCenterData: any;
  costCenterIsChanged: boolean;
  modalIdCounter: number;
  chatState: boolean;
  historyState: boolean;
  currencyRate: number;
  approvedCurrencyRate: number;
  transactionsUpdateFieldPermissions: string[];
  transactionsReadFieldPermissions: string[];
  transactionsJournalFieldPermissions: string[];
  profileFieldPermissions: string[];
  menuFieldPermissions: string[];
  filterFieldPermissions: string[];
  noticePanel: {
    open: boolean;
    newMessageCount: number;
  };
  clearingData: {
    dateStart: Date;
    dateEnd: Date;
    clearings: Clearing[];
    balanceStart: number;
    balanceEnd: number;
  };
  certificateId: number;
  attachmentListCount: number;
}

export const initialState: CommonState = {
  costCenterIsChanged: false,
  costCenterData: {},
  subcontoIsChanged: false,
  subcontoData: {},
  expenseTypes: [],
  businessGoals: [],
  businessTargetsCatalog: [],
  attachmentListCount: 0,
  expenseTypesCatalog: [],
  companiesCatalog: [] as ICompany[],
  consumptionTypes: [],
  paymentForms: [],
  loaderState: false,
  loaderCount: 0,
  stateOptions: [],
  filterParams: {},
  userDetail: {} as UserDetail,
  attachmentPermission: {
    isExpenseApplicationAttachmentPermission: true,
    isAdvanceReportAttachmentPermission: true,
  },
  manualExpenseCurrencyPermission: {
    isZNRManualExpenseCurrencyPermission: false,
    isAOManualExpenseCurrencyPermission: false,
  },
  filterState: true,
  currencies: {
    data: [],
    itemsCount: 0,
    itemsPerPage: 0,
    pageNumber: 0,
  },
  postings: { transactions: [] } as Posting,
  isArchivedPostings: null as unknown as boolean,
  transactionBatchId: 0,
  attachmentList: [],
  onboardState: false,
  isAttachmentsToggle: true,
  attachmentsExpense: [],
  historyNeedUpdating: false,
  openModal: [],
  modalIdCounter: 0,
  chatState: true,
  historyState: true,
  currencyRate: 1,
  approvedCurrencyRate: 1,
  transactionsUpdateFieldPermissions: [],
  transactionsReadFieldPermissions: [],
  transactionsJournalFieldPermissions: [],
  profileFieldPermissions: [],
  menuFieldPermissions: [],
  filterFieldPermissions: [],
  noticePanel: {
    open: false,
    newMessageCount: 0,
  },
  clearingData: {
    dateStart: new Date(),
    dateEnd: new Date(),
    clearings: [],
    balanceStart: 0,
    balanceEnd: 0,
  },
  certificateId: 0,
};

export const reducer: Reducer<CommonState> = (
  state: CommonState = initialState,
  action,
) => {
  switch ((action as CommonActions).type) {
	case COMMON.SET_ATTACHMENTS_LIST_COUNT:
		return { ...state, attachmentListCount: action.payload.attachmentListCount };
    case COMMON.SET_SUBCONTO_DATA:
      return { ...state, subcontoData: action.payload.subcontoData };
    case COMMON.SET_SUBCONTO_IS_CHANGED:
      return { ...state, subcontoIsChanged: action.payload.subcontoIsChanged };
    case COMMON.SET_COST_CENTER_DATA:
      return { ...state, costCenterData: action.payload.costCenterData };
    case COMMON.SET_COST_CENTER_IS_CHANGED:
      return {
        ...state,
        costCenterIsChanged: action.payload.costCenterIsChanged,
      };
    case COMMON.SET_EXPENSE_TYPE:
      return { ...state, expenseTypes: action.payload.expenseTypes };
    case COMMON.SET_CONSUMPTION_TYPE:
      return { ...state, consumptionTypes: action.payload.consumptionTypes };
    case COMMON.SET_PAYMENT_FORM:
      return { ...state, paymentForms: action.payload.paymentForms };
    case COMMON.SET_BUSINESS_GOAL:
      return { ...state, businessGoals: action.payload.businessGoals };
    case COMMON.SET_BUSINESS_TARGETS_CATALOG:
      return { ...state, businessTargetsCatalog: action.payload.catalog };
    case COMMON.SET_EXPENSE_TYPES_CATALOG:
      return { ...state, expenseTypesCatalog: action.payload.catalog };
    case COMMON.SET_COMPANIES_CATALOG:
      return { ...state, companiesCatalog: action.payload.catalog };
    case COMMON.INCREMENT_LOADER_COUNT:
      return {
        ...state,
        loaderCount: state.loaderCount + 1,
        loaderState: state.loaderCount >= 0,
      };
    case COMMON.DECREMENT_LOADER_COUNT:
      return {
        ...state,
        loaderCount: state.loaderCount - 1,
        loaderState: state.loaderCount > 1,
      };
    case COMMON.SET_FILTER_PARAMS:
      return { ...state, filterParams: action.payload.filterParams };
    case COMMON.SET_STATE_OPTIONS:
      return { ...state, stateOptions: action.payload.stateOptions };
    case COMMON.SET_USER_DETAIL:
      return { ...state, userDetail: action.payload.userDetail };
    case COMMON.SET_ATTACHMENT_PERMISSION:
      return {
        ...state,
        attachmentPermission: action.payload.attachmentPermission,
      };
    case COMMON.SET_MANUAL_EXPENSE_CURRENCY_PERMISSION:
      return {
        ...state,
        manualExpenseCurrencyPermission:
          action.payload.manualExpenseCurrencyPermission,
      };
    case COMMON.SET_FILTER_SHOW:
      return { ...state, filterState: action.payload.filterState };
    case COMMON.SET_CURRENCIES:
      return { ...state, currencies: action.payload.currencies };
    case COMMON.SET_POSTINGS:
      return { ...state, postings: action.payload.postings };
    case COMMON.SET_TRANSACTION_BATCH_ID: {
      return {
        ...state,
        transactionBatchId: action.payload.transactionBatchId,
      };
    }
    case COMMON.SET_ATTACHMENT_LIST:
      if (action.payload.isAddExpense) {
        let copyAttachmentList = uniqBy(
          [...state.attachmentList, ...action.payload.attachmentList],
          "id",
        );
        return { ...state, attachmentList: copyAttachmentList };
      }
      return {
        ...state,
        attachmentList: uniqBy(action.payload.attachmentList, "id"),
      };
    case COMMON.TOGGLE_ONBOARD: {
      return { ...state, onboardState: action.payload.state };
    }
    case COMMON.TOGGLE_ATTACHMENTS: {
      return { ...state, isAttachmentsToggle: !state.isAttachmentsToggle };
    }
    case COMMON.SET_ATTACHMENTS_EXPENSE: {
      return {
        ...state,
        attachmentsExpense: action.payload.attachmentsExpense,
      };
    }
    case COMMON.ADD_ATTACHMENTS_EXPENSE: {
      let copyAttachmentsExpense = [...state.attachmentsExpense];
      copyAttachmentsExpense.push(action.payload.attachmentsExpense);
      return {
        ...state,
        attachmentsExpense: uniqBy(copyAttachmentsExpense, "id"),
      };
    }
    case COMMON.HISTORY_NEED_UPDATING: {
      return { ...state, historyNeedUpdating: true };
    }
    case COMMON.HISTORY_UPDATED: {
      return { ...state, historyNeedUpdating: false };
    }
    case COMMON.ADD_NEW_TOP_MODAL: {
      return {
        ...state,
        openModal: [...state.openModal, action.payload.newTopModal],
        modalIdCounter: state.modalIdCounter + 1,
      };
    }
    case COMMON.DEL_TOP_MODAL: {
      return {
        ...state,
        openModal: state.openModal.filter(
          (item) => item != action.payload.topModal,
        ),
      };
    }
    case COMMON.SET_CHAT_SHOW:
      return { ...state, chatState: action.payload.chatState };
    case COMMON.SET_CURRENCY_RATE:
      return { ...state, currencyRate: action.payload.currencyRate };
    case COMMON.SET_APPROVED_CURRENCY_RATE:
      return { ...state, approvedCurrencyRate: action.payload.currencyRate };
    case COMMON.SET_HISTORY_SHOW:
      return { ...state, historyState: action.payload.historyState };
    case COMMON.SET_NOTICE_PANEL_OPEN:
      return {
        ...state,
        noticePanel: { ...state.noticePanel, open: action.payload.open },
      };
    case COMMON.SET_NOTICE_PANEL_NEW_MESSAGE_COUNT:
      return {
        ...state,
        noticePanel: {
          ...state.noticePanel,
          newMessageCount: action.payload.newMessageCount,
        },
      };
    case COMMON.SET_CLEARINGS:
      return { ...state, clearingData: action.payload.clearingData };
    case COMMON.SET_IS_ARCHIVED_POSTINGS: {
      return { ...state, isArchivedPostings: action.payload.isArchived };
    }
    case COMMON.SET_MENU_FIELD_PERMISSIONS: {
      return {
        ...state,
        menuFieldPermissions: action.payload.menuFieldPermissions,
      };
    }
    case COMMON.SET_FILTER_FIELD_PERMISSIONS: {
      return {
        ...state,
        filterFieldPermissions: action.payload.filterFieldPermissions,
      };
    }
    case COMMON.SET_PROFILE_FIELD_PERMISSIONS: {
      return {
        ...state,
        profileFieldPermissions: action.payload.profileFieldPermissions,
      };
    }
    case COMMON.SET_TRANSACTIONS_UPDATE_FIELD_PERMISSIONS: {
      return {
        ...state,
        transactionsUpdateFieldPermissions:
          action.payload.transactionsUpdateFieldPermissions,
      };
    }
    case COMMON.SET_TRANSACTIONS_READ_FIELD_PERMISSIONS: {
      return {
        ...state,
        transactionsReadFieldPermissions:
          action.payload.transactionsReadFieldPermissions,
      };
    }
    case COMMON.SET_TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS: {
      return {
        ...state,
        transactionsJournalFieldPermissions:
          action.payload.transactionsJournalFieldPermissions,
      };
    }
    case COMMON.SET_CERTIFICATE_ID:
      return { ...state, certificateId: action.payload.id };
    default:
      return state;
  }
};

export default reducer;
