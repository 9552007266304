import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AllSelected,
  CheckboxLabelTextEmployee,
  Checkmark,
  Container,
  EmployeeList,
  EmployeeOptionContainer,
  Input,
  InputTokenContainer,
  Label,
  MultiSelectInputLength,
  OptionEmployee,
  OptionID,
  StyledCheckbox,
  Token,
  TokenList,
  Wrapper,
} from "../ReportFormStyled";
import { SvgIcon } from "ui/SvgIcon";
import SvgIconS from "../../svg-icon/SvgIcon";
import { cutNulls, cutStr } from "../utils";
import { Employee } from "infrastructure/interfaces";
import { OptionProp } from "../types";
import Loader from "app/component/loader/Loader";
import { Spinner } from "react-bootstrap";

interface TokenInputProps {
  employees: Employee[];
  label: string;
  selectedEmployees: Employee[];
  setSelectedEmployees: (a: Employee[]) => void;
  report: OptionProp;
  reset: boolean;
  isLoading: boolean;
}

const TokenInput: React.FC<TokenInputProps> = ({
  employees,
  label,
  selectedEmployees,
  setSelectedEmployees,
  report,
  reset,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>("");
  const [showAll, setShowAll] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<Employee[]>([]);

  const selectedCount = selectedEmployees.length;
  const selectedEmployeesList = selectedEmployees.slice(0, 1);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOptions([]);
    setSearchValue("");
  }, [report, reset]);

  useEffect(() => {
    if (options.length) {
      setSelectedEmployees(options);
    } else {
      setSelectedEmployees(employees);
    }
  }, [employees, options]);

  useEffect(() => {
    if (selectedCount === employees.length) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [selectedCount]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const handleEmployeeSelect = (employee: Employee) => {
    if (options.some((e) => e.id === employee.id)) {
      setOptions(options.filter((e) => e.id !== employee.id));
    } else {
      setOptions([...options, employee]);
    }
  };

  const handleClearAll = () => {
    setSelectedEmployees(employees);
    setOptions([]);
    setSearchValue("");
    setIsOpen(false);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const toggle = () => {
    setIsOpen(true);
  };

  const filteredEmployees = employees.filter((employee) => {
    const fullName = employee.name.ru.toLowerCase();

    const idString = employee.occupations.map((el) =>
      el.company.id === employee.companyID ? cutNulls(el.vendor) : null,
    );

    const searchString = searchValue.toLowerCase();
    const fullNameMatches = fullName.toLowerCase().includes(searchString);
    const idMatches = idString.includes(searchString);
    const searchStringLength = searchString.length;
    const fullNameLength = fullName.length;
    const fullNameContainsSearchString =
      fullNameLength >= searchStringLength &&
      fullName.slice(0, searchStringLength) === searchString;
    return fullNameContainsSearchString || idMatches || fullNameMatches;
  });

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputTokenContainer ref={ref}>
        <SvgIcon
          style={{ position: "absolute", right: "8px", top: "14px", zIndex: 2 }}
          id={"vectorDown"}
          color={"#545B5E"}
          size={15}
        />
        {showAll ? (
          <AllSelected>{isLoading ? <div className="d-flex align-items-center"><Spinner animation="border" size="sm" /><p style={{marginLeft: "10px", paddingTop: "1px"}}>Загрузка...</p></div> : t("report.showAll")}</AllSelected>
        ) : (
          <TokenList>
            {selectedEmployeesList.map((employee) => (
              <Token key={employee.id}>
                {cutStr(employee.name[i18n.language as "ru" | "en"])}{" "}
                <SvgIconS
                  className="icon icon-close"
                  href="#svg_icon_close"
                  onClick={() =>
                    setSelectedEmployees(
                      selectedEmployees.filter((e) => e.id !== employee.id),
                    )
                  }
                  style={{ width: "8px", cursor: "pointer" }}
                />
              </Token>
            ))}
            {selectedCount > 1 && (
              <MultiSelectInputLength>
                +{selectedCount - 1}{" "}
              </MultiSelectInputLength>
            )}
          </TokenList>
        )}

        <EmployeeList>
          <div style={{ marginBottom: "16px", width: "100%" }}>
            <Input
              type="text"
              value={searchValue}
			  disabled={isLoading}
              onClick={toggle}
              onChange={handleSearch}
            />
          </div>
          {isOpen && !isLoading && (
            <EmployeeOptionContainer>
              {filteredEmployees.map((employee, i) => (
                <Container
                  key={`${employee.id} ${i}`}
                  onClick={() => handleEmployeeSelect(employee)}
                >
                  <StyledCheckbox
                    type="checkbox"
                    value={employee.name[i18n.language as "ru" | "en"]}
                    checked={options.some((e) => e.id === employee.id)}
                  />
                  <Checkmark />
                  <CheckboxLabelTextEmployee
                    htmlFor={employee.name[i18n.language as "ru" | "en"]}
                  >
                    {employee.occupations.map((el) =>
                      el.company.id === employee.companyID ? (
                        <>
                          <OptionEmployee>
                            {employee.name[i18n.language as "ru" | "en"]}{" "}
                            {cutNulls(el.vendor)}
                          </OptionEmployee>

                          <OptionID>
                            {el.company.name[i18n.language as "ru" | "en"]}
                          </OptionID>
                        </>
                      ) : null,
                    )}
                  </CheckboxLabelTextEmployee>
                </Container>
              ))}
            </EmployeeOptionContainer>
          )}
        </EmployeeList>

        {selectedCount > 0 && (
          <SvgIconS
            className="icon icon-close"
            href="#svg_icon_close"
            onClick={handleClearAll}
            style={{
              position: "absolute",
              right: "28px",
              top: "11px",
              zIndex: 2,
              width: "9px",
              cursor: "pointer",
            }}
          />
        )}
      </InputTokenContainer>
    </Wrapper>
  );
};

export default TokenInput;
