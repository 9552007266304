import React, { useState, useRef, useEffect, ReactNode } from "react";
import { get, uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import { ChatMessageMapper } from "infrastructure/mappers";
import { ChatMessage } from "infrastructure/interfaces";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  hideChat,
  showChat,
} from "store/common/actions";
import { showErrors } from "store/exception/actions";
import analitiks from "services/AnaliticsService";
import { isIE } from "react-device-detect";
import {
  getCommonChatState,
  getCommonUserDetail,
  getCommonUserDetailEmail,
} from "../../../store/selectors";
import {
  getCommunicationsByLogicalName,
  postCommunications,
} from "../../../services/ApiService";
import { Trans } from "react-i18next";

export interface ChatProps {
  objectId: number;
  logicalName: string;
  title: string;
}

const Chat: React.FC<ChatProps> = (props) => {
  let { t, i18n } = useTranslation();
  const currentUserEmail = useSelector(getCommonUserDetailEmail);
  let chatState: boolean = useSelector(getCommonChatState);
  const user = useSelector(getCommonUserDetail);

  const dispatch = useDispatch();

  let [messages, setMessages] = useState([] as ChatMessageProps[]);
  let [rawMessages, setRawMessages] = useState([] as ChatMessage[]);
  let [messageValue, setMessageValue] = useState("");
  let [messageBoxActive, setMessageBoxActive] = useState(false);
  let [messageGeted, setMessageGeted] = useState(false);
  let [firstDropDown, setFirstDropDown] = useState(false);
  let [messagesCount, setMessagesCount] = useState(0);
  let [isChatOpen, setChatOpen] = useState(chatState);
  let [width, setWidth] = useState(0);
  let [smallLoader, setSmallLoader] = useState(false);
  let [scrollFinish, setScrollFinish] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);
  let [sendLoader, setSendLoader] = useState(false);
  let [sendError, setSendError] = useState(false);
  let [isLoaded, setLoaded] = useState(false);
  let [currentDate, setCurrentDate] = useState(moment());

  const handleSend = async () => {
    if (sendLoader) {
      return;
    }
    if (messageValue.trim() == "") {
      setMessageValue("");
      return;
    }

    setSendLoader(true);
    let postData = {
      content: messageValue,
      object: {
        id: props.objectId,
        logicalName: props.logicalName,
      },
    };

    let result = await postCommunications(postData);
    if (result.headers.success) {
      analitiks(
        postData.object.logicalName == "ExpenseApplication"
          ? "SendMessageToChatZNR"
          : "SendMessageToChatAO",
        postData.object.logicalName == "ExpenseApplication"
          ? {
              znrId: postData.object.id,
              employeeId: user.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
            }
          : {
              aoId: postData.object.id,
              employeeId: user.id,
              occupationId: user.occupations[0].occupationId,
              companyId: user.occupations[0].company.id,
            },
      );
      setMessageValue("");
      setRawMessages(
        uniqBy(
          [
            {
              id: get(result.data, "id", 0),
              read: !!get(result.data, "read", false),
              author: {
                id: get(result.data, "sent.actor.id", 0),
                fullName: {
                  ru: get(result.data, "sent.actor.fullName.ru", ""),
                  en: get(result.data, "sent.actor.fullName.en", ""),
                },
                login: get(result.data, "sent.actor.login", ""),
              },
              time: get(result.data, "sent.timestamp", ""),
              message: get(result.data, "content", ""),
            },
            ...rawMessages,
          ],
          "id",
        ),
      );
      getMessageCount();
      setSendError(false);
    } else {
      setSendError(true);
    }
    setSendLoader(false);
  };

  const getMessage = async (page: number) => {
    const params: any = {
      objectId: props.objectId,
      pageSize: 20,
      page: page,
      sort: 1,
    };
    if (isIE) {
      params.date = new Date();
    }
    let result = await getCommunicationsByLogicalName(props.logicalName, {
      params,
    });
    if (result.headers.success) {
      if (result.status == 200) {
        let chatMessageMapper = new ChatMessageMapper();
        if (page == 1) {
          setRawMessages(
            chatMessageMapper.responsesToEntitys(result.data.data),
          );
        } else {
          setRawMessages(
            uniqBy(
              [
                ...rawMessages,
                ...chatMessageMapper.responsesToEntitys(result.data.data),
              ],
              "id",
            ),
          );
        }
        if (result.data.data.length == 0) {
          setScrollFinish(true);
        }
      }
    } else {
      dispatch(
        showErrors({
          code: "get_chat",
          message: t("chat.request_error", { status: result.status }),
        }),
      );
    }
    setLoaded(true);
    setSmallLoader(false);
    scrollDownOnce();
  };

  const getNextMessage = () => {
    setSmallLoader(true);
    getMessage(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const getFirstMessage = async () => {
    const params: any = {
      objectId: props.objectId,
      pageSize: 10,
      page: 1,
      sort: 1,
    };
    if (isIE) {
      params.date = new Date();
    }
    let result = await getCommunicationsByLogicalName(props.logicalName, {
      params,
    });
    if (result.headers.success) {
      if (result.status == 200) {
        let chatMessageMapper = new ChatMessageMapper();
        setRawMessages(
          uniqBy(
            [
              ...chatMessageMapper.responsesToEntitys(result.data.data),
              ...rawMessages,
            ],
            "id",
          ),
        );
      }
    } else {
      dispatch(
        showErrors({
          code: "get_chat",
          message: t("chat.request_error", { status: result.status }),
        }),
      );
    }
    setSmallLoader(false);
  };

  const getMessageCount = async () => {
    const params: any = {
      objectId: props.objectId,
      CountOnly: true,
    };
    if (isIE) {
      params.date = new Date();
    }
    let result = await getCommunicationsByLogicalName(props.logicalName, {
      params,
    });
    if (result.headers.success) {
      if (result.status == 200) {
        if (messagesCount != result.data.itemsCount) {
          setMessagesCount(result.data.itemsCount);
        }
      }
    }
  };

  const handleChangeTextarea = (event: any) => {
    setMessageValue(event.target.value);
  };

  const handleFocusTextarea = () => {
    setMessageBoxActive(true);
  };

  const handleBlurTextarea = (event: any) => {
    const target = get(event, ["relatedTarget", "dataset", "target"], null);
    setMessageBoxActive(false);
    if (target) {
      handleSend();
    }
  };

  const ref = useRef({} as any);

  useEffect(() => {
    setCurrentDate(moment());
  }, []);

  useEffect(() => {
    getFirstMessage();
  }, [messagesCount]);

  useEffect(() => {
    let newMessages = rawMessages
      .map((message) => {
        return {
          id: message.id,
          author: message.author.fullName[i18n.language as "ru"],
          time: moment
            .utc(message.time, "YYYY-MM-DDThh:mm:ss")
            .locale(i18n.language as "ru")
            .local()
            .format("HH:mm"),
          date: moment
            .utc(message.time, "YYYY-MM-DDThh:mm:ss")
            .locale(i18n.language as "ru")
            .local()
            .format("DD MMMM"),
          message: [
            {
              text: message.message,
              to: undefined,
            },
          ],
          read: message.read,
          left: currentUserEmail != message.author.login,
          right: currentUserEmail == message.author.login,
          orange: currentUserEmail != message.author.login,
          gray: currentUserEmail == message.author.login,
          agent: currentUserEmail == message.author.login,
        };
      })
      .reverse();
    if (!newMessages || !newMessages.length) {
      newMessages.push({
        author: t("chat.aeroclub_author"),
        time: moment
          .utc(currentDate, "YYYY-MM-DDThh:mm:ss")
          .locale(i18n.language as "ru")
          .local()
          .format("HH:mm"),
        date: moment
          .utc(currentDate, "YYYY-MM-DDThh:mm:ss")
          .locale(i18n.language as "ru")
          .local()
          .format("DD MMMM"),
        message: [
          {
            text: (
              <Trans
                i18nKey="chat.zero_message"
                values={{
                  username: user.name[i18n.language as "ru"],
                  logicalName:
                    props.logicalName === "ExpenseApplication"
                      ? t("chat.expenseApplication")
                      : t("chat.advanceReport"),
                }}
              />
            ),
            to: undefined,
          },
        ],
        read: false,
        left: true,
        right: false,
        orange: false,
        gray: true,
        agent: true,
      });
    }
    setMessages(newMessages);
  }, [rawMessages, i18n.language]);

  const scrollDownOnce = () => {
    if (!firstDropDown) {
      let chat = ref.current.querySelector(".simplebar-content");
      chat.scrollTo(0, chat.scrollHeight);
      setFirstDropDown(true);
    }
  };

  useEffect(() => {
    if (!messageGeted) {
      setMessageGeted(true);
      getMessage(1);
      getMessageCount();
    }

    let chat: any;
    let scrollHandler: any;

    chat = ref.current.querySelector(".simplebar-content");

    if (chat) {
      scrollHandler = () => {
        if (!scrollFinish && !smallLoader && chat && chat.scrollTop < 1000) {
          getNextMessage();
        }
      };

      chat.addEventListener("scroll", scrollHandler);
    }

    return () => {
      if (chat && scrollHandler) {
        chat.removeEventListener("scroll", scrollHandler);
      }
    };
  });

  useEffect(() => {
    let timerID = setInterval(() => getMessageCount(), 10000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const resizeEventHandler = () => {
    if (width !== window.innerWidth) {
      resizeHandler();
      setWidth(window.innerWidth);
    }
  };

  const resizeHandler = () => {
    if (window.innerWidth <= 959) {
      dispatch(hideChat());
    } else {
      dispatch(showChat());
    }
  };

  useEffect(() => {
    setChatOpen(chatState);
    window.addEventListener("resize", resizeEventHandler);
    return () => {
      window.removeEventListener("resize", resizeEventHandler);
    };
  }, [chatState]);

  useEffect(() => {
    resizeHandler();
  }, []);

  const loadData = () => {};

  const prepareMessages = () => {
    let currentDate = "";
    let components = [] as ReactNode[];
    messages.forEach((item) => {
      if (currentDate == item.date) {
        components.push(
          <ChatMessageItem
            key={item.id}
            id={item.id}
            author={item.author}
            date={item.date}
            time={item.time}
            message={item.message}
            left={item.left}
            right={item.right}
            orange={item.orange}
            gray={item.gray}
            agent={item.agent}
            read={item.read}
          />,
        );
      } else {
        currentDate = item.date;
        components.push(
          <ChatDate key={+("321" + item.id + "123")} date={item.date} />,
        );
        components.push(
          <ChatMessageItem
            key={item.id}
            id={item.id}
            author={item.author}
            date={item.date}
            time={item.time}
            message={item.message}
            left={item.left}
            right={item.right}
            orange={item.orange}
            gray={item.gray}
            agent={item.agent}
            read={item.read}
          />,
        );
      }
    });

    return components;
  };

  const handleKeyPressTextarea = (event: React.KeyboardEvent) => {
    if (sendLoader) {
      event.preventDefault();
      return;
    }
    setSendError(false);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      return handleSend();
    }
  };

  return (
    <div
      className="filters chat-block"
      style={{ display: isChatOpen ? "block" : "none" }}
    >
      <div className="filters-title">{props.title}</div>
      <div className="chat" ref={ref}>
        <div
          className="chat-content simplebar-content"
          style={{ padding: "10px 37px 0 20px" }}
        >
          {isLoaded && prepareMessages()}
        </div>
        <div className="chat-controls-wrap">
          <div
            className={"chat-controls " + (messageBoxActive ? "active" : "")}
            style={{
              position: "relative",
              borderColor: sendError ? "#FF3B30" : undefined,
            }}
          >
            <div
              style={{
                zIndex: 1000,
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "black",
                opacity: 0.1,
                display: sendLoader ? "block" : "none",
              }}
            ></div>
            <div className="input-item">
              <textarea
                className="input"
                style={{ fontFamily: "HarmoniaSansProCyr" }}
                placeholder={t("chat.placeholder")}
                value={messageValue}
                onChange={handleChangeTextarea}
                onFocus={handleFocusTextarea}
                onBlur={handleBlurTextarea}
                onKeyDown={handleKeyPressTextarea}
                tabIndex={-1}
              />
            </div>
            <div className="chat-controls-more">
              <div className="chat-controls-btns">
                <button
                  type="button"
                  className="btn-expense btn_green pointer"
                  onMouseDown={handleSend}
                  data-target="true"
                >
                  {t("chat.send")}
                </button>
              </div>
            </div>
            <div
              style={{
                display: sendError ? undefined : "none",
                padding: "2px 10px",
                fontSize: "12px",
                color: "#FF3B30",
              }}
            >
              {t("chat.sendError")}
            </div>
            <div
              style={{
                zIndex: 1000,
                position: "absolute",
                top: "calc(50% - 32px)",
                left: "calc(50% - 32px)",
                display: sendLoader ? "block" : "none",
              }}
              className="lds-dual-ring"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ChatMessageProps {
  id: number;
  author: string;
  time: string;
  date: string;
  message: {
    text: string | Element;
    to?: string;
  }[];

  read: boolean;
  left: boolean;
  right: boolean;
  orange: boolean;
  gray: boolean;
  agent: boolean;
}

const ChatMessageItem: React.FC<ChatMessageProps> = (props) => {
  const messageClassName = (property: any): string => {
    let className =
      "chat-content-mess " +
      (property.left ? "left " : "") +
      (property.right ? "right " : "") +
      (property.orange ? "orange " : "") +
      (property.gray ? "gray " : "") +
      (property.agent ? "agent " : "");

    return className;
  };

  return (
    <div className={messageClassName(props)}>
      <div className="chat-content-mess-author">{`@${props.author} ${props.time}`}</div>
      {props.message.map((item, index) => {
        return (
          <div className="chat-content-mess-text" key={index}>
            {item.to ? <div>To: {item.to}</div> : null}
            {item.text}
            {/* {props.read ? (<SvgIcon className="icon icon-view-message" href="#svg_icon_view_message" />) : (<SvgIcon className="icon icon-view-message" href="#svg_icon_view_message-small" />)} */}
          </div>
        );
      })}
    </div>
  );
};

interface ChatDateProps {
  date: string;
}

const ChatDate: React.FC<ChatDateProps> = (props) => {
  return <div className="chat-date">{props.date}</div>;
};

export default Chat;
