import SvgIcon from "app/component/svg-icon/SvgIcon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Config, { ConfigTypes } from "services/ConfigService";
import { RadioButton } from "ui/RadioButton";
import styles from "./Assistance.module.css";

interface AssistanceModalRowProps {
  assistantId: number;
  name: string;
  company: string;
  companyId: number;
  type: "search" | "assistant" | "assistance" | "message";
  onAdd?: (assistantId: number, type: "assistant" | "assistance") => void;
  onDelete?: (assistantId: number, type: "assistant" | "assistance") => void;
}

const AssistanceModalRow: React.FC<AssistanceModalRowProps> = (props) => {
  const { t } = useTranslation();

  let [background, setBackground] = useState("");
  let [isAssistant, setAssistant] = useState(false);
  let [assistanceSource, setAssistanceSource] = useState(0);

  useEffect(() => {
    if (props.companyId) {
      setAssistanceSource(
        Config.getConfigToCompany(
          ConfigTypes.ASSISTANCE_SOURCE,
          props.companyId
        )
      );
    }
  }, [props.companyId]);

  useEffect(() => {
    switch (props.type) {
      case "search":
        setBackground("#D9E2E7");
        break;
      case "assistant":
        setBackground("#F0F0EF");
        break;
      case "assistance":
        setBackground("#E8EDEE");
        break;
      default:
        setBackground("#F0F0EF");
        break;
    }
  }, [props.type]);

  const radioAssistantClickHandler = () => {
    setAssistant(true);
  };

  const radioEmployerClickHandler = () => {
    setAssistant(false);
  };

  const addClickHandler = () => {
    props.onAdd &&
      props.onAdd(props.assistantId, isAssistant ? "assistant" : "assistance");
  };

  const deleteClickHandler = () => {
    props.onDelete &&
      props.onDelete(
        props.assistantId,
        isAssistant ? "assistant" : "assistance"
      );
  };

  return (
    <>
      {props.type !== "search" ? (
        <div
          style={{
            background: background,
          }}
          className={styles.assistanceRowContainer}
        >
          <div className={styles.assistanceRow}>
            <div className={styles.assistanceRowName}>{props.name}</div>
            <div className={styles.assistanceRowCompany}>{props.company}</div>
          </div>
          <div>
            {(props.type === "assistant" || props.type === "assistance") &&
            assistanceSource === 1 ? (
              <div onClick={deleteClickHandler}>
                <SvgIcon className="icon icon-close" href="#svg_icon_close" />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          style={{
            background: background,
          }}
          className={styles.assistanceSearchRowContainer}
        >
          <div className={styles.assistanceRow}>
            <div>
              <div className={styles.assistanceRowName}>{props.name}</div>
              <div className={styles.assistanceRowCompany}>{props.company}</div>
            </div>
          </div>
          <div className={styles.assistanceSearchRadioContainer}>
            {assistanceSource === 1 ? (
              <>
                <RadioButton
                  key={1}
                  name={"employeTypeRadio" + props.assistantId}
                  id={1}
                  label={t("assistance_list.modal.option_assistant")}
                  checked={isAssistant}
                  onClick={radioAssistantClickHandler}
                />
                <RadioButton
                  key={2}
                  name={"employeTypeRadio" + props.assistantId}
                  id={2}
                  label={t("assistance_list.modal.option_employee")}
                  checked={!isAssistant}
                  onClick={radioEmployerClickHandler}
                />
              </>
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {assistanceSource === 1 ? (
              <div onClick={addClickHandler}>
                <SvgIcon className="icon icon-close" href="#svg_icon_plus" />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default AssistanceModalRow;
