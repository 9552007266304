import { ActionCreator } from "redux";
import { ApplicationState } from "store/reducers";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  JOURNAL,
  JournalActions,
  SetJournalAllPage,
  SetJournalFilter,
  SetJournalIsArchive,
  SetJournalFieldPermissions,
  SetJournalList,
  SetJournalNextPage,
  SetJournalPage,
  REPORTS,
  SetRegistriesDownloadParameters,
} from "./actionTypes";
import { loaderLock, loaderUnlock, setPostings } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import {
  DownloadTransactionMapper,
  JournalItemMapper,
} from "infrastructure/mappers";
import { JournalItem } from "infrastructure/interfaces";
import { CommonActions } from "store/common/actionTypes";
import {
  getBatches,
  getBatchesById,
  getBatchesInternal,
  getFieldPermissionsReadJournal,
} from "../../services/ApiService";

export const setRegistriesDownloadParameters: ActionCreator<
  SetRegistriesDownloadParameters
> = (filter) => ({
  type: REPORTS.SET_REPORTS_REGISTRIES_PARAMETERS,
  payload: { filter },
});

export const setJournalList: ActionCreator<SetJournalList> = (
  journalList: JournalItem[],
  firstPage: boolean
) => ({
  type: JOURNAL.SET_JOURNAL_LIST,
  payload: { journalList, firstPage },
});

export const setJournalFieldPermissions: ActionCreator<
  SetJournalFieldPermissions
> = (fieldPermissions) => ({
  type: JOURNAL.SET_JOURNAL_FIELD_PERMISSIONS,
  payload: { fieldPermissions },
});

export const setJournalFilter: ActionCreator<SetJournalFilter> = (filter) => ({
  type: JOURNAL.SET_JOURNAL_FILTER,
  payload: { filter },
});

export const setJournalAllPage: ActionCreator<SetJournalAllPage> = (
  pageAll
) => ({
  type: JOURNAL.SET_JOURNAL_ALL_PAGE,
  payload: { pageAll },
});

export const setJournalPage: ActionCreator<SetJournalPage> = (page) => ({
  type: JOURNAL.SET_JOURNAL_PAGE,
  payload: { page },
});

export const setJournalNextPage: ActionCreator<SetJournalNextPage> = () => ({
  type: JOURNAL.SET_JOURNAL_NEXT_PAGE,
});

export const setJournalIsArchive: ActionCreator<SetJournalIsArchive> = (
  isArchive
) => ({
  type: JOURNAL.SET_JOURNAL_IS_ARCHIVE,
  payload: { isArchive },
});

export interface JournalListFilter {
  uploadNumber?: string;
  tripNumber?: string;
  itemNumber?: string;
  unloaded?: number;
  employee?: number;
  downloadDateStart?: Date;
  downloadDateEnd?: Date;
  downloadLastDateStart?: Date;
  downloadLastDateEnd?: Date;
  tripDateStart?: Date;
  tripDateEnd?: Date;
  cityId?: any;
  batchNumber?: number;
  postingDateStart?: Date;
  postingDateEnd?: Date;
  dateCreate?: Date;
}

export const updateJournalIsArchive = (
  isArchive: boolean
): ThunkAction<Promise<void>, ApplicationState, any, JournalActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, JournalActions>,
    getState
  ): Promise<void> => {
    const state = getState();
    if (state.journal.isArchive !== isArchive) {
      dispatch(updateJournalFieldPermissions(isArchive));
	  dispatch(setJournalPage(1));
	  dispatch(setJournalAllPage(0));
    }
    dispatch(setJournalIsArchive(isArchive));
  };
};

export const updateJournalFieldPermissions = (
  isArchive: boolean = false
): ThunkAction<Promise<void>, ApplicationState, any, JournalActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, JournalActions>
  ): Promise<void> => {
    dispatch(loaderLock());
    let response = await getFieldPermissionsReadJournal(isArchive);
    if (response.headers.success) {
      dispatch(setJournalFieldPermissions(response.data));
    }
    dispatch(loaderUnlock());
  };
};

export const updateJournalList = (
  objectType: string,
  nextPage: boolean,
  callback?: () => void
): ThunkAction<Promise<void>, ApplicationState, any, JournalActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, JournalActions>,
    getState
  ): Promise<void> => {
    let state = getState();
    let filter = state.journal.filter;
    let page = state.journal.page;
    let pageAll = state.journal.pageAll;
    let isArchive = state.journal.isArchive;

    if (pageAll == page && nextPage) {
      return;
    }

    await dispatch(loaderLock());
    const getParams = () => {
      let result = "";
      let params: string[] = [];
      let cities = filter.cityId;
      if (cities && cities.length > 0) {
        cities.forEach((item: any) => {
          params.push(`CityId=${item}`);
        });
      }
      let itemNumbers = filter.itemNumber && filter.itemNumber.split(",");
      if (itemNumbers && itemNumbers.length) {
        itemNumbers.forEach((itemNumber: string) => {
          params.push(`ObjectIds=${itemNumber.trim()}`);
        });
      }
      if (params.length > 0) {
        result += "?";
        result += params.join("&");
      }
      return result;
    };
    let requestParams = {
      objectType: objectType,
      CreateDate: filter.dateCreate,
      BatchState: filter.unloaded,
      loadDateStart: filter.downloadDateStart,
      loadDateEnd: filter.downloadDateEnd,
      LastLoadDateStart: filter.downloadLastDateStart,
      LastLoadDateEnd: filter.downloadLastDateEnd,
      JourneyNum: filter.tripNumber,
      JourneyStartDate: filter.tripDateStart,
      JourneyEndDate: filter.tripDateEnd,
      EmployeeId: filter.employee,
      batchNumber: filter.batchNumber,
      postingDateStart: filter.postingDateStart,
      postingDateEnd: filter.postingDateEnd,
      // CityId: filter.cityId,
      batchID: filter.uploadNumber,
      Page: page + +nextPage,
      PageSize: 20,
      isArchive: isArchive,
    };
    let journalItemMapper = new JournalItemMapper();
    let response = await getBatchesInternal(filter.cityId ? getParams() : "", {
      params: requestParams,
    });
    if (callback) {
      callback();
    }

    if (response.headers.success) {
      dispatch(
        setJournalList(
          journalItemMapper.responsesToEntitys(response.data.data),
          requestParams.Page == 1
        )
      );
      let allPage =
        Math.floor(response.data.itemsCount / response.data.itemsPerPage) + 1;
      dispatch(setJournalAllPage(allPage));
      dispatch(setJournalPage(requestParams.Page));
    } else {
      //dispatch(showErrors({ code: 'update_detailed_report_error', message: `Не удалось загрузить авансовый отчет` }));
    }
    await dispatch(loaderUnlock());
  };
};

export const updateDownlandJournalList = (
  nextPage: boolean
): ThunkAction<Promise<void>, ApplicationState, any, JournalActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, JournalActions>,
    getState
  ): Promise<void> => {
    let state = getState();
    let filter = state.journal.filter;
    let page = state.journal.page;
    let pageAll = state.journal.pageAll;

    if (pageAll == page && nextPage) {
      return;
    }

    dispatch(loaderLock());
    let requestParams = {
      batchID: filter.uploadNumber,
      authorID: filter.employee,
      startDate: filter.downloadDateStart,
      endDate: filter.downloadDateEnd,
      Page: page + +nextPage,
      PageSize: 20,
    };
    let journalItemMapper = new JournalItemMapper();
    let response = await getBatches({ params: requestParams });
    if (response.headers.success) {
      dispatch(
        setJournalList(
          journalItemMapper.responsesToEntitys(response.data.data),
          requestParams.Page == 1
        )
      );
      let allPage =
        Math.floor(response.data.itemsCount / response.data.itemsPerPage) + 1;
      dispatch(setJournalAllPage(allPage));
      dispatch(setJournalPage(requestParams.Page));
    }

    await dispatch(loaderUnlock());
  };
};

export interface UpdateDownlandJournalFilters {
  postingKey?: number;
  lager?: string;
  employee?: number;
}

export const updateDownlandJournalDetail = (
  id: number,
  filter: UpdateDownlandJournalFilters
): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
  return async (
    dispatch: ThunkDispatch<ApplicationState, any, CommonActions>,
    getState
  ): Promise<void> => {
    await dispatch(loaderLock());
    dispatch(setPostings({ transactions: [] }));

    let params = {
      PageSize: 500,
      postKey: filter.postingKey,
      lagerAccount: filter.lager,
      employee: filter.employee,
    };

    let transactionMapper = new DownloadTransactionMapper();

    let response = await getBatchesById(id, { params });
    if (response.headers.success) {
      dispatch(
        setPostings({
          transactions: transactionMapper.responsesToEntitys(
            response.data.data
          ),
        })
      );
    } else {
      dispatch(setPostings({ transactions: [] }));
      await dispatch(
        showErrors({ code: "delete_downland", message: "Что-то пошло не так" })
      );
    }
    await dispatch(loaderUnlock());
  };
};
