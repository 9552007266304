import React from "react";
import { Switch, Route } from "react-router";
import { YMInitializer } from "react-yandex-metrika";
import Header from "./component/header/Header";
import HeaderContent from "./component/header/HeaderContent";
import Menu from "./component/menu/Menu";
import Body from "./component/body/Body";
import Footer from "./component/footer/Footer";
import Loader from "./component/loader/Loader";
import RightSide from "./component/rightSide/RightSide";
import ExceptionModal from "./component/modal/ExceptionModal";
import Errors from "./component/modal/Errors";
import Infos from "./component/modal/Infos";
import OnboardingWizard from "./component/modal/OnboardingWizard";
import NoticePanel from "./component/notice/NoticePanel";
import ZNRExpensesWidget from "./component/widgets/ZNRExpensesWidget";
import "./App.css";
import AccessDenied from "./pages/access-denied/AccessDenied";

const { install } = require("ga-gtag");

interface AppProps {
  accessDenied: boolean;
  widgetMode: boolean;
}

const App: React.FC<AppProps> = (props) => {
  install("G-FLPH63RZRB");

  return props.widgetMode ? (
    <>
      <Switch>
        <Route
          path="/widget/ApplicationForExpense/Expenses"
          component={ZNRExpensesWidget}
        />
      </Switch>
      <YMInitializer
        accounts={[55232485]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          ecommerce: "dataLayer",
        }}
        version="2"
      />
      <Loader isWidget={true} />
      <ExceptionModal isWidget={true} />
      <Errors isWidget={true} />
      <Infos />
    </>
  ) : (
    <div className="App">
      <Header accessDenied={props.accessDenied} />
      <div className="wrap">
        <div className="red-line red-line-right"></div>
        <div className="red-line red-line-left"></div>
        <div className="container">
          <HeaderContent accessDenied={props.accessDenied} />
          <div className="content">
            {!props.accessDenied ? (
              <>
                <Menu />
                <div className={"main-wrap"}>
                  <Body />
                  <Switch>
                    <Route path="/user" children={null} />
                    <Route path="/PowersOfAttorney" children={null} />
                    <Route path="/Reports" children={null} />
                    <Route path="/Substitutions" children={null} />
                    <Route path="*" component={RightSide} />
                  </Switch>
                </div>
              </>
            ) : (
              <AccessDenied />
            )}
          </div>
        </div>
        {!props.accessDenied ? <NoticePanel /> : null}
      </div>
      <Footer />
      <Loader />
      <ExceptionModal />
      <OnboardingWizard />
      <Errors />
      <Infos />
      <div>
        <div>
          <YMInitializer
            accounts={[55232485]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              ecommerce: "dataLayer",
            }}
            version="2"
          />
        </div>
      </div>
    </div>
  );
};

export default App;
