import { useTranslation } from "react-i18next";
import ModalContainer from "./ModalContainer";
import React, { useEffect, useState } from "react";
import { RadioButton } from "ui/RadioButton";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import { formatter, toDate } from "../../utils";
import SvgIcon from "../svg-icon/SvgIcon";
import { useSelector } from "react-redux";
import {
  getAdvanceReportApplication,
  getApplicationForExpenseDetail,
  getCommonPostings,
} from "../../../store/selectors";

interface DownloadPostingsModalInterface {
  isOpen: boolean;
  isList?: boolean;
  onClose: () => void;
  onSubmit: (date?: string) => void;
}

const DownloadPostingsModal: React.FC<DownloadPostingsModalInterface> = (
  props,
) => {
  const { t, i18n } = useTranslation();

  const [isChangeDateButtonChecked, setChangeDateButtonChecked] = useState(false);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [showDefaultDate, setShowDefaultDate] = useState(false);
  const [defaultDate, setDefaultDate] = useState(null as any);
  const [date, setDate] = useState(null as any);

  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail,
  );
  const AODetail = useSelector(getAdvanceReportApplication);
  const postings = useSelector(getCommonPostings);

  useEffect(() => {
    setDate(null);
    setShowDefaultDate(false);
    setChangeDateButtonChecked(false);
    if (props.isList) {
      setDate(new Date());
      return;
    }
    if (
      applicationForExpenseDetail &&
      applicationForExpenseDetail.postingDate
    ) {
      setDate(toDate(applicationForExpenseDetail.postingDate));
      setDefaultDate(toDate(applicationForExpenseDetail.postingDate));
      setShowDefaultDate(true);
    } else if (AODetail && AODetail.postingDate) {
      setDate(toDate(AODetail.postingDate));
      setDefaultDate(toDate(AODetail.postingDate));
      setShowDefaultDate(true);
    } else if (
      postings &&
      postings.transactions &&
      postings.transactions.length
    ) {
      setDate(toDate(postings.transactions[0].postingDate));
      setDefaultDate(toDate(postings.transactions[0].postingDate));
      setShowDefaultDate(true);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (!isChangeDateButtonChecked && defaultDate) {
      setDate(defaultDate);
    }
  }, [isChangeDateButtonChecked]);

  const toggleCalendar = () => {
    setOpenCalendar(!isOpenCalendar);
  };

  const onDateSelect = (value: any) => {
    setDate(value.startDate);
    toggleCalendar();
  };

  return (
    <ModalContainer isOpen={props.isOpen} overlayClick={props.onClose}>
      <div className="box-modal" id="download-postings">
        <div
          className="box-modal_close arcticmodal-close pointer"
          onClick={props.onClose}
        />
        <div className="box-modal-title">
          {t("modals.download_postings.title")}
        </div>
        <div className="box-modal-content">
          <div className="box-modal-form">
            <div className="box-modal-form-block">
              <div className="box-modal-checkbox-wrap">
                <RadioButton
                  name={"radio1"}
                  id={1}
                  label={t("modals.download_postings.default")}
                  checked={!isChangeDateButtonChecked}
                  onClick={() => {
                    setChangeDateButtonChecked(false);
                  }}
                />
                <RadioButton
                  name={"radio2"}
                  id={2}
                  label={t("modals.download_postings.change_date")}
                  checked={isChangeDateButtonChecked}
                  onClick={() => {
                    setChangeDateButtonChecked(true);
                  }}
                />
              </div>
              {isChangeDateButtonChecked || showDefaultDate ? (
                <div
                  className={`input-item ${showDefaultDate && !isChangeDateButtonChecked ? "disabled" : ""}`}
                >
                  <label className="input-label">
                    {t("modals.download_postings.title")}
                  </label>
                  <div className={`input-date`}>
                    <input
                      value={
                        date
                          ? formatter("D MMMM YYYY", i18n.language, date)
                          : ""
                      }
                      onClick={toggleCalendar}
                      className="input datepicker pointer"
                      type="text"
                      id="datepicker"
                      placeholder={t("modals.download_postings.choose")}
                      readOnly={true}
                      tabIndex={-1}
                    />
                    <SvgIcon
                      className={"icon icon-calendar"}
                      href={"#svg_icon_calendar"}
                      onClick={toggleCalendar}
                    />
                  </div>
                  <DatePickerCalendar
                    month={date}
                    startDate={date}
                    isShown={isOpenCalendar}
                    onClose={toggleCalendar}
                    isCleansed={false}
                    onSelected={onDateSelect}
                    isMultiChoice={false}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="box-modal-footer">
          <a
            className="arcticmodal-close btn btn_black pointer"
            onClick={props.onClose}
          >
            {t("modals.download_postings.cancel")}
          </a>
          <a
            className={`btn-expense btn_green pointer ${!date && isChangeDateButtonChecked ? "disabled" : ""}`}
            onClick={() => {
              props.onSubmit(
                date && isChangeDateButtonChecked ? date.toJSON() : undefined,
              );
            }}
          >
            {t("modals.download_postings.download")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DownloadPostingsModal;
