import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SvgIconS from "app/component/svg-icon/SvgIcon";
import "./InternalReport.css";
import { formatter } from "app/utils";
import MultiSelect from "./UI/Multiselect";
import {
  Adaptive,
  Calendar,
  CalendarContainer,
  CalendarInput,
  ContentReportTitle,
  Label,
  CalendarContainerWidth,
} from "./ReportFormStyled";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import { typeOptions } from "./utils";
import Tooltip from "../tooltip/Tooltip";
import { ExternalReportProps } from "./types";
import { getCommonCompaniesCatalog } from "store/selectors";
import TokenInput from "./UI/TokenInput";

const ExternalReport: React.FC<ExternalReportProps> = ({
  report,
  statusOptions,
  employeesOptions,
  companies,
  setCompanies,
  statuses,
  setStatuses,
  types,
  setTypes,
  selectedEmployees,
  setSelectedEmployees,
  creationPeriod,
  setCreationPeriod,
  approvalPeriod,
  setApprovalPeriod,
  buttonClicked,
  reset,
  isLoading,
  setReset,
}) => {
  const { t, i18n } = useTranslation();

  const companyDetails = useSelector(getCommonCompaniesCatalog);
  const [isOpenCreationPeriodCalendar, setIsOpenCreationPeriodCalendar] =
    useState(false);
  const [isOpenApprovalPeriodCalendar, setIsOpenApprovalPeriodCalendar] =
    useState(false);
  const [error, setError] = useState(false);
  const [removedPeriod, setRemovedPeriod] = useState(false);

  useEffect(() => {
    if (buttonClicked) {
      if (
        report.label === "PersonalIncomeTaxReport" &&
        !approvalPeriod.startDate
      ) {
        setError(true);
      } else if (creationPeriod.startDate || approvalPeriod.startDate) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [creationPeriod.startDate, approvalPeriod.startDate, buttonClicked]);

  const onSelectedToCreationPeriodDate = (value: any) => {
    setCreationPeriod(value);
  };

  const onSelectedToApprovalPeriodDate = (value: any) => {
    setApprovalPeriod(value);
  };

  const deleteCreationPeriodDate = () => {
    setRemovedPeriod(true);
    setCreationPeriod({
      startDate: "",
      endDate: "",
    });
  };

  const deleteApprovalPeriodDate = () => {
    setRemovedPeriod(true);
    setApprovalPeriod({
      startDate: "",
      endDate: "",
    });
  };

  const onClickToCreationPeriodCalendar = () => {
    setIsOpenCreationPeriodCalendar(!isOpenCreationPeriodCalendar);
  };

  const onClickToApprovalPeriodCalendar = () => {
    setIsOpenApprovalPeriodCalendar(!isOpenApprovalPeriodCalendar);
  };

  return (
    <>
      <ContentReportTitle>{t("report.report_content")}</ContentReportTitle>
      <Adaptive>
        <MultiSelect
          report={report}
          label={t("report.company")}
          options={companyDetails}
          selectedValues={companies}
          setSelectedValues={setCompanies}
          buttonClicked={buttonClicked}
          reset={reset}
        />
        <TokenInput
		  isLoading={isLoading}
          report={report}
          employees={employeesOptions}
          label={t("report.reporting_person")}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          reset={reset}
        ></TokenInput>
      </Adaptive>
      <Adaptive>
        {report.label !== "PersonalIncomeTaxReport" && (
          <MultiSelect
            report={report}
            label={t("report.status")}
            options={statusOptions}
            selectedValues={statuses}
            setSelectedValues={setStatuses}
            buttonClicked={buttonClicked}
            reset={reset}
          />
        )}
        <MultiSelect
          report={report}
          label={t("report.applications_type")}
          options={typeOptions}
          selectedValues={types}
          setSelectedValues={setTypes}
          buttonClicked={buttonClicked}
          reset={reset}
        />
        {report.label === "PersonalIncomeTaxReport" && (
          <CalendarContainerWidth>
            <Calendar>
              <Label error={error}>
                {t("report.approval_period")}
                {error ? "*" : ""}
              </Label>
              <CalendarContainer>
                {approvalPeriod.startDate && (
                  <SvgIconS
                    className="icon icon-close"
                    href="#svg_icon_close"
                    onClick={deleteApprovalPeriodDate}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "41px",
                      top: "11px",
                      zIndex: 2,
                      width: "9px",
                    }}
                  />
                )}
                <SvgIconS
                  className={"icon icon-calendar"}
                  style={{ position: "absolute", right: "2px", zIndex: 2 }}
                  href={"#svg_icon_calendar"}
                  onClick={onClickToApprovalPeriodCalendar}
                />

                <CalendarInput
                  error={error}
                  value={
                    approvalPeriod.startDate
                      ? `${formatter(
                          "DD.MM.YY",
                          i18n.language,
                          approvalPeriod.startDate
                        )} - ${formatter(
                          "DD.MM.YY",
                          i18n.language,
                          approvalPeriod.endDate
                        )}`
                      : t("report.select_dates").toString()
                  }
                  onClick={onClickToApprovalPeriodCalendar}
                  type="text"
                  readOnly={true}
                  tabIndex={-1}
                />

                <DatePickerCalendar
                  isShown={isOpenApprovalPeriodCalendar}
                  onSelected={onSelectedToApprovalPeriodDate}
                  isCleansed={false}
                  isMultiChoice={true}
                  onClose={onClickToApprovalPeriodCalendar}
                  reset={reset}
                  setReset={setReset}
                  removedPeriod={removedPeriod}
                  setRemovedPeriod={setRemovedPeriod}
                />
              </CalendarContainer>
            </Calendar>
          </CalendarContainerWidth>
        )}
      </Adaptive>
      {report.label !== "PersonalIncomeTaxReport" && (
        <Adaptive>
          <CalendarContainerWidth>
            <Calendar>
              <Label error={error}>
                {t("report.creation_period")}
                {error ? "*" : ""}
              </Label>
              <Tooltip
                textArray={[t("report.tooltip")]}
                width={350}
                notHovered={false}
                block={true}
              >
                <CalendarContainer>
                  {creationPeriod.startDate && (
                    <SvgIconS
                      className="icon icon-close"
                      href="#svg_icon_close"
                      onClick={deleteCreationPeriodDate}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "41px",
                        top: "11px",
                        zIndex: 2,
                        width: "9px",
                      }}
                    />
                  )}
                  <SvgIconS
                    className={"icon icon-calendar"}
                    style={{ position: "absolute", right: "2px", zIndex: 2 }}
                    href={"#svg_icon_calendar"}
                    onClick={onClickToCreationPeriodCalendar}
                  />

                  <CalendarInput
                    error={error}
                    value={
                      creationPeriod.startDate
                        ? `${formatter(
                            "DD.MM.YY",
                            i18n.language,
                            creationPeriod.startDate
                          )} - ${formatter(
                            "DD.MM.YY",
                            i18n.language,
                            creationPeriod.endDate
                          )}`
                        : t("report.select_dates").toString()
                    }
                    onClick={onClickToCreationPeriodCalendar}
                    type="text"
                    placeholder={t("ДД.ММ.ГГ")}
                    readOnly={true}
                    tabIndex={-1}
                    disabled={approvalPeriod.startDate ? true : false}
                  />

                  <DatePickerCalendar
                    isShown={isOpenCreationPeriodCalendar}
                    onSelected={onSelectedToCreationPeriodDate}
                    isCleansed={false}
                    isMultiChoice={true}
                    onClose={onClickToCreationPeriodCalendar}
                    reset={reset}
                    setReset={setReset}
                    removedPeriod={removedPeriod}
                    setRemovedPeriod={setRemovedPeriod}
                  />
                </CalendarContainer>
              </Tooltip>
            </Calendar>
          </CalendarContainerWidth>

          <CalendarContainerWidth>
            <Calendar>
              <Label error={error}>
                {t("report.approval_period")}
                {error ? "*" : ""}
              </Label>
              <Tooltip
                textArray={[t("report.tooltip")]}
                width={350}
                notHovered={false}
                block={true}
              >
                <CalendarContainer>
                  {approvalPeriod.startDate && (
                    <SvgIconS
                      className="icon icon-close"
                      href="#svg_icon_close"
                      onClick={deleteApprovalPeriodDate}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "41px",
                        top: "11px",
                        zIndex: 2,
                        width: "9px",
                      }}
                    />
                  )}
                  <SvgIconS
                    className={"icon icon-calendar"}
                    style={{ position: "absolute", right: "2px", zIndex: 2 }}
                    href={"#svg_icon_calendar"}
                    onClick={onClickToApprovalPeriodCalendar}
                  />

                  <CalendarInput
                    error={error}
                    value={
                      approvalPeriod.startDate
                        ? `${formatter(
                            "DD.MM.YY",
                            i18n.language,
                            approvalPeriod.startDate
                          )} - ${formatter(
                            "DD.MM.YY",
                            i18n.language,
                            approvalPeriod.endDate
                          )}`
                        : t("report.select_dates").toString()
                    }
                    onClick={onClickToApprovalPeriodCalendar}
                    type="text"
                    readOnly={true}
                    tabIndex={-1}
                    disabled={creationPeriod.startDate ? true : false}
                  />

                  <DatePickerCalendar
                    isShown={isOpenApprovalPeriodCalendar}
                    onSelected={onSelectedToApprovalPeriodDate}
                    isCleansed={false}
                    isMultiChoice={true}
                    onClose={onClickToApprovalPeriodCalendar}
                    reset={reset}
                    setReset={setReset}
                    removedPeriod={removedPeriod}
                    setRemovedPeriod={setRemovedPeriod}
                  />
                </CalendarContainer>
              </Tooltip>
            </Calendar>
          </CalendarContainerWidth>
        </Adaptive>
      )}
    </>
  );
};

export default ExternalReport;
