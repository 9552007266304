import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { ApplicationState } from "store/reducers";
import { showErrors } from "store/exception/actions";
import If from "app/component/util/If";
import { difference, get } from "lodash";
import EmployeeAutocomplete from "app/component/autocomplete/EmployeeAutocomplete";
import SubmitCustomModal from "app/component/modal/SubmitCustomModal";
import AssistanceWarningModal from "app/component/modal/AssistanceWarningModal";
import { PROFILE_FIELD_PERMISSIONS } from "../../../infrastructure/enum/field-permissions.enum";
import {
  deleteAssistancesById,
  deleteAssistancesByOccupationId,
  getAssistances,
  getAssistancesById,
  postAssistances,
} from "../../../services/ApiService";
import AssistanceRow from "./AssistanceRow";
import AssistanceModal from "./AssistanceModal";

const Assistance: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let profileFieldPermissions = useSelector(
    (state: ApplicationState) => state.common.profileFieldPermissions
  );

  let [assistantList, setAssistantList] = useState(
    [] as {
      assistantId: number;
      assistantName: {
        ru: string;
        en: string;
      };
      occupation: {
        id: number;
        name: {
          ru: string;
          en: string;
        };
      };
      assistantCompany: {
        id: number;
        ru: string;
        en: string;
      };
      numberOfAssistances: number;
      updateTime: number;
    }[]
  );
  let [isOpenEditModal, setOpenEditModal] = useState(false);
  let [editAssistant, setEditAssistant] = useState({
    assistantId: 0,
    assistantName: { ru: "", en: "" },
    assistantCompany: { id: 0, ru: "", en: "" },
  });
  let [inputValue, setInputValue] = useState(0);
  let [isOpenWarningAssistanceModal, setOpenWarningAssistanceModal] =
    useState(false);
  let [isOpenWarningDeleteModal, setOpenWarningDeleteModal] = useState(false);
  let [assistantIds, setAssistantIds] = useState([] as number[]);
  let [employeeIds, setEmployeeIds] = useState([] as number[]);
  let [deleteAssistantId, setDeleteAssistantId] = useState(0);

  useEffect(() => {
    getAssistant();
  }, []);

  const onChoose = (value: { value: number; text: string }) => {
    if (value.value) {
      setAssistantList([]);
      setInputValue(value.value);
      getAssistant(value.value);
    }

    if (!value.value && inputValue != 0) {
      setAssistantList([]);
      setInputValue(0);
      getAssistant();
    }
  };

  const openCreateModal = () => {
    initRowEdit({
      assistantId: 0,
      assistantName: { ru: "", en: "" },
      assistantCompany: { id: 0, ru: "", en: "" },
    });
  };

  const initRowEdit = (editUser: {
    assistantId: number;
    assistantName: { ru: string; en: string };
    assistantCompany: { id: number; ru: string; en: string };
  }) => {
    setEditAssistant(editUser);
    setOpenEditModal(true);
  };

  const onCloseHandler = () => {
    setOpenEditModal(false);
  };

  const toggleWarningAssistanceModal = () => {
    setOpenWarningAssistanceModal(!isOpenWarningAssistanceModal);
  };

  const toggleWarningDeleteModal = () => {
    setOpenWarningDeleteModal(!isOpenWarningDeleteModal);
  };

  const preSaveHandler = async (
    assistantIds: number[],
    employeeIds: number[]
  ) => {
    setAssistantIds(assistantIds);
    setEmployeeIds(employeeIds);
    if (editAssistant.assistantId == 0) {
      let response = await getAssistancesById(assistantIds[0]);
      if (response.headers.success) {
        let assistancesList = get(response, "data.value.assistances", []);
        if (assistancesList.length > 0) {
          toggleWarningAssistanceModal();
          return;
        } else {
          onSaveHandler(assistantIds, employeeIds);
          return;
        }
      }
    }
    onSaveHandler(assistantIds, employeeIds);
  };

  const save = () => {
    toggleWarningAssistanceModal();
    onSaveHandler(assistantIds, employeeIds);
  };

  const onSaveHandler = async (
    assistantIds: number[],
    employeeIds: number[]
  ) => {
    dispatch(loaderLock());

    let resultAction = [] as {
      assistantId: number;
      addIds: number[];
      removeIds: number[];
      removeAssistant: boolean;
    }[];

    if (assistantIds.length > 0) {
      for (let index = 0; index < assistantIds.length; index++) {
        const assistantId = assistantIds[index];
        let assistenceArray = await getAssistance(assistantId);
        let assistenceIds = assistenceArray.map((item: any) => item.employeeId);

        let addIds = [] as number[];
        let removeIds = [] as number[];
        let removeAssistant = false;

        if (index == 0) {
          addIds = difference(employeeIds, assistenceIds);
          let removeEmployeeIds = difference(assistenceIds, employeeIds);
          removeIds = assistenceArray
            .filter((item: any) => removeEmployeeIds.includes(item.employeeId))
            .map((item: any) => item.assistanceId);
          if (
            removeIds.length > 0 &&
            removeIds.length == assistenceIds.length
          ) {
            removeAssistant = true;
          }
        } else {
          addIds = difference(employeeIds, assistenceIds);
        }

        resultAction.push({
          assistantId: assistantId,
          addIds: addIds,
          removeIds: removeIds,
          removeAssistant: removeAssistant,
        });
      }
    }

    let allWait = [] as Promise<void>[];

    resultAction.forEach((item) => {
      if (!item.removeAssistant && item.removeIds.length > 0) {
        allWait.push(deleteAssistance(item.removeIds));
      }

      if (item.removeAssistant) {
        allWait.push(deleteAssistant(item.assistantId));
      }

      if (item.addIds.length > 0) {
        allWait.push(addAssistance(item.assistantId, item.addIds));
      }
    });

    await Promise.all(allWait);

    if (inputValue == 0) {
      await getAssistant();
    } else {
      await getAssistant(inputValue);
    }
    dispatch(loaderUnlock());
    setOpenEditModal(false);
  };

  const getAssistant = async (employee?: number) => {
    dispatch(loaderLock());
    let response = await getAssistances({
      params: { PageSize: 1000, Page: 1, AssistantEmployeeId: employee },
    });
    if (response.headers.success) {
      let newAssistantList = response.data.data.map((item: any) => {
        return {
          assistantId: get(item, "assistantId", 0),
          assistantName: {
            ru: get(item, "assistantName.ru", ""),
            en: get(item, "assistantName.en", ""),
          },
          occupation: {
            id: 0,
            name: {
              ru: get(item, "assistantOccupationName.ru", ""),
              en: get(item, "assistantOccupationName.en", ""),
            },
          },
          assistantCompany: {
            id: get(item, "company.id", ""),
            ru: `${get(item, "company.name.ru", "")} - ${get(item, "assistantOccupationName.ru", "")}`,
            en: `${get(item, "company.name.en", "")} - ${get(item, "assistantOccupationName.en", "")}`,
          },
          numberOfAssistances: get(item, "numberOfAssistances", 0),
          updateTime: +new Date(),
        };
      });
      setAssistantList(newAssistantList);
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось загрузить ассистентов",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const getAssistance = async (assistantId: number) => {
    dispatch(loaderLock());

    let response = await getAssistancesById(assistantId);
    if (response.headers.success) {
      let newAssistanceList = response.data.value.assistances.map(
        (item: any) => {
          return {
            assistanceId: get(item, "assistanceId", 0),
            employeeId: get(item, "employeeOccupationId", 0),
          };
        }
      );
      dispatch(loaderUnlock());
      return newAssistanceList;
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось загрузить сотрудников",
        })
      );
      dispatch(loaderUnlock());
      return [];
    }
  };

  const addAssistance = async (assistantId: number, employeeIds: number[]) => {
    dispatch(loaderLock());

    let allRequest = [] as Promise<void>[];

    employeeIds.forEach((item) =>
      allRequest.push(
        postAssistances({
          assistantOccupationId: assistantId,
          employeeOccupationId: item,
        })
      )
    );

    let responses = await Promise.all(allRequest);

    responses.forEach((response: any) => {
      if (response.headers.success) {
      } else {
        dispatch(
          showErrors({
            code: "del_substituation",
            message: "Не удалось добавить сотрудника к ассистенту",
          })
        );
      }
    });

    dispatch(loaderUnlock());
  };

  const deleteAssistance = async (assistanceId: number[]) => {
    dispatch(loaderLock());

    let allRequest = [] as Promise<void>[];

    assistanceId.forEach((item) =>
      allRequest.push(deleteAssistancesById(item))
    );

    let responses = await Promise.all(allRequest);

    responses.forEach((response: any) => {
      if (response.headers.success) {
      } else {
        dispatch(
          showErrors({
            code: "del_substituation",
            message: "Не удалось удалить сотрудника к ассистенту",
          })
        );
      }
    });

    dispatch(loaderUnlock());
  };

  const deleteAssistant = async (assistantId: number) => {
    dispatch(loaderLock());
    let response = await deleteAssistancesByOccupationId(assistantId);
    if (response.headers.success) {
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось удалить ассистента",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const deleteAssistantSubmit = async () => {
    dispatch(loaderLock());
    await deleteAssistant(deleteAssistantId);
    setDeleteAssistantId(0);
    setOpenWarningDeleteModal(false);
    if (inputValue == 0) {
      await getAssistant();
    } else {
      await getAssistant(inputValue);
    }
    dispatch(loaderUnlock());
  };

  const deleteHandler = async (assistantId: number) => {
    setDeleteAssistantId(assistantId);
    setOpenWarningDeleteModal(true);
  };

  const showAssistance: boolean = useMemo(() => {
    return (
      profileFieldPermissions &&
      profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.ASSISTANCE)
    );
  }, [profileFieldPermissions]);

  return (
    <Fragment>
      <div className="profile-calculation">
        <div className="profile-calculation-main">
          <div className="profile-calc-table-wrap">
            <div className={"input-item"} style={{ marginBottom: "30px" }}>
              <div className="input-search">
                <EmployeeAutocomplete
                  onChoose={onChoose}
                  placeholder={t("assistance_list.search_placeholder")}
                />
              </div>
            </div>
            <If condition={assistantList.length == 0}>
              <div className="request-list empty">
                <div className="request-list-empty">
                  <img src="/img/empty-img.png" />
                  <div style={{ width: "inherit" }}>
                    {t("assistance_list.empty_list")}
                  </div>
                </div>
              </div>
            </If>
            <If condition={assistantList.length > 0}>
              <div className="profile-calc-table table-sub">
                <div className="profile-calc-table-row">
                  <div
                    className="profile-calc-table-th"
                    style={{ maxWidth: "none", width: "calc(50% - 75px)" }}
                  >
                    {t("assistance_list.table.assistant")}
                  </div>
                  <div
                    className="profile-calc-table-th"
                    style={{ maxWidth: "none", width: "calc(50% + 75px)" }}
                  >
                    {t("assistance_list.table.employee")}
                  </div>
                </div>
                {assistantList.map((item) => {
                  return (
                    <AssistanceRow
                      key={item.assistantId}
                      assistantId={item.assistantId}
                      assistantName={{
                        ru: item.assistantName.ru,
                        en: item.assistantName.en,
                      }}
                      assistantCompany={{
                        id: item.assistantCompany.id,
                        ru: item.assistantCompany.ru,
                        en: item.assistantCompany.en,
                      }}
                      assistantOccupation={item.occupation}
                      assistantCompanyId={item.assistantCompany.id}
                      numberOfAssistances={item.numberOfAssistances}
                      updateTime={item.updateTime}
                      onEdit={initRowEdit}
                      onDelete={deleteHandler}
                    />
                  );
                })}
              </div>
            </If>
          </div>
        </div>
        <If condition={showAssistance}>
          <div className="profile-calculation-filter profile-calculation-filter-sub">
            <a className="btn-expense btn_green" onClick={openCreateModal}>
              {t("assistance_list.add")}
            </a>
          </div>
        </If>
      </div>
      <AssistanceModal
        isOpen={isOpenEditModal}
        assistant={editAssistant}
        onClose={onCloseHandler}
        onSave={preSaveHandler}
      />
      <SubmitCustomModal
        isOpen={isOpenWarningDeleteModal}
        title={t("assistance_list.delete_alert")}
        onClose={toggleWarningDeleteModal}
        onSubmit={deleteAssistantSubmit}
      />
      <AssistanceWarningModal
        isOpen={isOpenWarningAssistanceModal}
        title={t("assistance_list.rewrite_alert_title")}
        info={t("assistance_list.rewrite_alert_info")}
        sub_info={t("assistance_list.rewrite_alert_sub_info")}
        todo={t("assistance_list.rewrite_alert_todo")}
        onClose={toggleWarningAssistanceModal}
        onSubmit={save}
      />
    </Fragment>
  );
};

export default Assistance;