import styled from "styled-components";

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:has(input:disabled) {
	  cursor: not-allowed;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  &:hover::not(:disabled) {
    border: 1px solid #ff5248;
    border-radius: 4px;
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:focus ~ ${Checkmark} {
    outline: 3px solid rgba(11, 92, 215, 0.5);
  }
  &:disabled ~ ${Checkmark} {
    border: 1px solid #e0e0e0;
    background: #f5f5f5;
	cursor: not-allowed;
  }
  &:indeterminate ~ ${Checkmark} {
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      left: 4px;
      top: 4px;
      background-color: #ff5248;
    }
  }
  ${(props) =>
    props.checked &&
    ` 
  &:checked ~ ${Checkmark} {
    background-color: #FF5248;
    display: block;
    &::after {
      content: "";
      display: block;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      background-color: #FF5248;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(50deg);
    }
  }
&:disabled ~ ${Checkmark} {
    background: #C2C2C2;
    &::after {
      content: "";
      display: block;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 12px;
      background-color: #C2C2C2;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(50deg);
    }
}
&:indeterminate ~ ${Checkmark} {
  &::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 4px;
    background-color: #C2C2C2;
  }
}
`}
`;

const CheckboxLabelText = styled.div`
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
`;

export { StyledCheckbox, Checkmark, Container, CheckboxLabelText };
